<template>
    <div>
        <v-progress-linear indeterminate
                           v-if="loading"></v-progress-linear>
        <div class="headline">Prime Sponsor</div>
        <FilterableDataTable :headers="Billheaders"
                      :items="filterPrimeSponsor"
                      item-key="BillNumber"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['Bill.BillType', 'Bill.BillNumberOnly']"
                      :sort-desc="[false, false]"
                      loading-text="Loading... Please wait"
                      class="elevation-1">
            <template v-slot:item.BillURL="{ item }">
                <router-link :to="'/Session/Bill/' + item.Bill.BillId">{{item.Bill.BillType + '&nbsp;' + item.Bill.BillNumberOnly}}</router-link>
            </template>
        </FilterableDataTable>
        <div class="headline">Co Sponsor</div>
        <FilterableDataTable :headers="Billheaders2"
                      :items="filterCoSponsor"
                      item-key="BillNumber"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['Bill.BillType', 'Bill.BillNumberOnly']"
                      :sort-desc="[false, false]"
                      loading-text="Loading... Please wait"
                      class="elevation-1">
            <template v-slot:item.BillURL="{ item }">
                <router-link :to="'/Session/Bill/' + item.Bill.BillId">{{item.Bill.BillType + '&nbsp;' + item.Bill.BillNumberOnly}}</router-link>
            </template>
        </FilterableDataTable>
    </div>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Bills',
        props: ['SessionMemberId'],
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/SessionMembers/Bills/' + this.SessionMemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    
                    throw new Error(res.statusText);
                }).then(response => {

                    let r = EncodeDatetimes(response);

                    (r.Bills || []).forEach(x =>
                    {
                        x.BillURL = x.Bill.BillType + ' ' + x.Bill.BillNumberOnly;
                    });

                    this.Member = r;

                }).catch(error => {
                    throw new Error(error);
                }).finally(_ =>
                {
                    this.loading = false;
                });
            },

        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',
                tabs: ['Detail', 'Committees', 'Bills', 'Hearings'],
                Member: {
                    Session: {
                        Year: null
                    },
                },
                Billheaders: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillURL',
                        width: '10ch'
                    },
                    {
                        text: 'Bill Type',
                        value: 'Bill.BillType',
                        width: '20ch'
                    },
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'Bill.BillNumberOnly',
                        width: '15ch'
                    },
                    {
                        text: 'Title',
                        value: 'Bill.Title',
                        width: '*'
                    },
                ],
                Billheaders2: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillURL',
                        width: '10ch'
                    },
                    {
                        text: 'Bill Type',
                        value: 'Bill.BillType',
                        width: '20ch'
                    },
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'Bill.BillNumberOnly',
                        width: '15ch'
                    },
                    {
                        text: 'Title',
                        value: 'Bill.Title',
                        width: '*'
                    },
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionMemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            filterPrimeSponsor: function () {
                if (this.Member.Bills != null) {
                    return this.Member.Bills.filter(x => x.SponsorType == 'P');
                }
            },
            filterCoSponsor: function () {
                if (this.Member.Bills != null) {
                    return this.Member.Bills.filter(x => x.SponsorType == 'C');
                }
            },
        }
    };
</script>
<style></style>