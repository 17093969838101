<template>
    <div class="d-print-none">
        <v-card class="hidden-sm-and-down">
            <v-navigation-drawer floating
                                 permanent
                                 class="hidden-sm-and-down">

                <v-skeleton-loader class="mx-auto hidden-sm-and-down" v-if="loadingMenu"
                                   max-width="256"
                                   type="list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item,list-item"></v-skeleton-loader>

                <v-list dense
                        nav>

                    <v-list-item v-for="item in SubMenuItems"
                                 :key="item.DocumentHearingId"
                                 link
                                 :to="'/Budget/Documents/' + item.DocumentHearingId">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">{{ item.Description }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-card>
        <div class="hidden-md-and-up">
            <v-row>
                <v-col cols="12">
                    <v-spacer></v-spacer>
                    <div class="hidden-md-and-up justify-end">
                        <v-btn @click.stop="drawer1 = !drawer1">
                            <v-icon>{{dotsvertical}}</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-navigation-drawer v-model="drawer1"
                                 right
                                 fixed
                                 temporary>
                <v-list dense
                        nav>

                    <v-list-item v-for="item in SubMenuItems"
                                 :key="item.DocumentHearingId"
                                 link
                                 :to="'/Budget/Documents/' + item.DocumentHearingId">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">{{ item.Description }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </div>
    </div>
</template>
<script>
    import { mdiDotsVertical } from '@mdi/js';
    export default {
        name: 'BudgetAgencyList',
        props: ['SessionId'],
        components: {

        },
        methods: {
            LoadData() {
                this.loadingMenu = true;
                fetch('/api/DocumentHearings/HasDocuments?SessionIds=' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loadingMenu = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.SubMenuItems = EncodeDatetimes(response);
                    this.loadingMenu = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                dotsvertical: mdiDotsVertical,
                drawer1: false,
                loadingMenu: true,
                SubMenuItems: [

                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },

        },
        watch: {
            SessionId: function () {
                this.LoadData();
            }
        },
    };
</script>
<style></style>