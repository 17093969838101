<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <div class="headline text-center pb-4">Monthly Reports</div>
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">

            <MonthlyReportCard v-if="currentRoute.includes('GeneralFundReceipts')"></MonthlyReportCard>
            <MonthlyReportCard v-else-if="currentRoute.includes('Expenditures')"></MonthlyReportCard>
            <MonthlyReportCard v-else-if="currentRoute.includes('MedicalServices')"></MonthlyReportCard>
            <MonthlyReportCard v-else-if="currentRoute.includes('TrustFunds')"></MonthlyReportCard>
            <MonthlyReportCard v-else-if="currentRoute.includes('PrisonPopulations')"></MonthlyReportCard>
            <div v-else>
                <v-alert type="info">Please select a report in the menu</v-alert>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const MonthlyReportCard = () => import("@/Components/MonthlyReportCard.vue");
    export default {
        name: 'MonthlyReport',
        components: {
            MonthlyReportCard, Sidebar
        },
        methods: {
            LoadData() {
                fetch('/api/Sessions/Current', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },

        },
        data: function () {
            return {
                Session: {},
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let SubRouteName = '';
                if(this.currentRoute.includes('GeneralFundReceipts')){
                 SubRouteName = 'General Fund Receipts '
                }
                else if(this.currentRoute.includes('Expenditures')){
                 SubRouteName = 'Expenditures '
                }
                else if(this.currentRoute.includes('MedicalServices')){
                 SubRouteName = 'Medical Services '
                }
                else if(this.currentRoute.includes('TrustFunds')){
                 SubRouteName = 'Trust Funds '
                }
                else if(this.currentRoute.includes('PrisonPopulations')){
                 SubRouteName = 'Prison Populations '
                }
                if (this.Session && this.Session.Year) {
                    return this.Session.Year + SubRouteName + " Monthly Reports";
                }
                else {
                    return SubRouteName + "Monthly Reports";
                }
            },
            Description: function () {
                return this.Title;
            },
            currentRoute: function () {
                return this.$route.path;
            }
        }
    };
</script>
<style></style>