<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12" cols="12">
            <div class="headline">Historical Listing</div>
            <v-divider></v-divider>
            <p>
                The historical listing contains both territorial and statehood legislators. Legislators who served the Dakota Territory from 1861-1889, are listed as “territorial.” South Dakota became a state on November 2, 1889.  Legislators who served South Dakota from 1890-present, are listed as “statehood.”
            </p>
            <br />
            <p>
                Total Legislators <v-chip>{{filteredMembers.length}}</v-chip>
            </p>
            <br />
            <v-text-field label="Search"
                          clearable
                          v-model="search">
                <template v-slot:append>
                    <v-icon>{{searchIcon}}</v-icon>
                </template>
            </v-text-field>
            <FilterableDataTable :headers="headers"
                                 :items="filteredMembers"
                                 item-key="MemberServiceId"
                                 multi-sort
                                 :items-per-page="50"
                                 :sort-by="['Name']"
                                 :sort-desc="[false]"
                                 :editHeaders="true"
                                 :loading="loading"
                                 :search="search"
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.Name="{ item }">
                    <router-link :to="'/Historical/Legislator/Profile/' + item.MemberId">{{item.Name }}</router-link>
                </template>
                <template v-slot:item.Body="{item}">
                    <span class="text-xs-center" v-if="item.Body" v-html="item.Body.join(',&nbsp;')">
                    </span>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    import { mdiMagnify } from '@mdi/js';
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Historical',
        components: {
            FilterableDataTable, Sidebar
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/Historical/AllFlatMembers', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.filteredMembers = EncodeDatetimes(response);
                    this.Members = this.filteredMembers;//.filter(x => x.Body != null && x.Party != null && x.Office != null && x.City != null && x.StartYear != null && x.County != null && x.Gender != null && x.MemberType != null);
                    if (this.$vuetify.breakpoint.mobile) {
                        this.Members.forEach(x => {
                            if (x.Remarks != null) {
                                if (x.Remarks.length > 110) {
                                    x.Remarks = x.Remarks.substring(0, 110);
                                }
                            }
                            return x;
                        });
                    }

                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            Search() {
                this.loading = true;
                var res = this.Members;

                if (this.FirstName && !this.isEmpty(this.FirstName)) {
                    res = res.filter(x => x.FirstName && x.FirstName != null).filter(x => x.FirstName.toLowerCase().includes(this.FirstName.toLowerCase()));
                }

                if (this.LastName && !this.isEmpty(this.LastName)) {
                    res = res.filter(x => x.LastName && x.LastName != null).filter(x => x.LastName.toLowerCase().includes(this.LastName.toLowerCase()));
                }

                if (this.Party && !this.isEmpty(this.Party)) {
                    res = res.filter(x => x.Party && x.Party != null).filter(x => x.Party.toLowerCase().includes(this.Party.toLowerCase()));
                }
                if (this.Office && !this.isEmpty(this.Office)) {
                    res = res.filter(x => x.Office && x.Office != null).filter(x => x.Office.toLowerCase().includes(this.Office.toLowerCase()));
                }

                if (this.City && !this.isEmpty(this.City)) {
                    res = res.filter(x => x.City && x.City != null).filter(x => x.City.toLowerCase().includes(this.City.toLowerCase()));
                }

                if (this.Year && !this.isEmpty(this.Year)) {
                    res = res.filter(x => x.Year && x.Year != null).filter(x => x.Year.toLowerCase().includes(this.Year.toLowerCase()));
                }

                if (this.County && !this.isEmpty(this.County)) {
                    res = res.filter(x => x.County && x.County != null).filter(x => x.County.toLowerCase().includes(this.County.toLowerCase()));
                }

                if (this.StateOrTerritory && !this.isEmpty(this.StateOrTerritory)) {
                    res = res.filter(x => x.StateOrTerritory && x.StateOrTerritory != null).filter(x => x.StateOrTerritory.toLowerCase().includes(this.StateOrTerritory.toLowerCase()));
                }

                if (this.Gender && !this.isEmpty(this.Gender)) {
                    res = res.filter(x => x.Gender && x.Gender != null).filter(x => x.Gender.toLowerCase().includes(this.Gender.toLowerCase()));
                }

                if (this.Body && !this.isEmpty(this.Body)) {
                    res = res.filter(x => x.Body && x.Body != null).filter(x => x.Body.toLowerCase().includes(this.Body.toLowerCase()));
                }

                if (this.Remarks && !this.isEmpty(this.Remarks)) {
                    res = res.filter(x => x.Remarks && x.Remarks != null).filter(x => x.Remarks.toLowerCase().includes(this.Remarks.toLowerCase()));
                }

                this.loading = false;
                this.filteredMembers = res;
            },
            isEmpty(str) {
                return (!str || 0 === str.length);
            },
            unique(value, index, self) {
                return self.indexOf(value) === index
            },
        },
        data: function () {
            return {
                searchIcon: mdiMagnify,
                search: '',
                AdvancedSearch: false,
                loading: false,
                headers: [
                    {
                        text: 'Name',
                        value: 'Name',
                        align: 'left',
                        width: '20ch',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Gender',
                        value: 'Gender',
                        align: 'left',
                        width: '10ch',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'FirstName',
                        value: 'FirstName',
                        align: 'left',
                        width: '15ch',
                        sortable: true,
                        visible: false,
                        filterable: true,
                    },
                    {
                        text: 'LastName',
                        value: 'LastName',
                        align: 'left',
                        width: '15ch',
                        sortable: true,
                        visible: false,
                        filterable: true,
                    },
                    {
                        text: 'MiddleName',
                        value: 'MiddleName',
                        align: 'left',
                        width: '10ch',
                        sortable: true,
                        visible: false,
                        filterable: true,
                    },
                    {
                        text: 'Birth',
                        value: 'Birthdate',
                        align: 'left',
                        width: '10ch',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Death',
                        value: 'Deathdate',
                        align: 'left',
                        width: '12ch',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Type',
                        value: 'MemberType',
                        align: 'left',
                        width: '11ch',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Remarks',
                        value: 'Remarks',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Office',
                        value: 'Office',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Party',
                        value: 'Party',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Body',
                        value: 'Body',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'City',
                        value: 'City',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'County',
                        value: 'County',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Start Year',
                        value: 'StartYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'End Year',
                        value: 'EndYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                ],
                items: [],
                filteredMembers: [],
                Members: [],
                Party: null,
                Office: null,
                City: null,
                Year: null,
                County: null,
                StateOrTerritory: null,
                Gender: null,
                Body: null,
                Remarks: null,
                FirstName: null,
                LastName: null,
                Parties: [],
                Offices: [],
                Cities: [],
                Years: [],
                Counties: [],
                Genders: [],
                StateOrTerritories: [],
                Bodies: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            Members: function () {

            }
        },
        computed: {
            Title: function () {
                let title = 'Historical Legislators';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>