<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                ADA Request Form
            </div>
            <div>Individuals needing assistance, pursuant to the Americans with Disabilities Act should submit this form 48 hours before convening to allow time to make any necessary arrangements.</div>
            <v-form lazy-validation ref="form" v-model="valid">


                <v-text-field :counter="50" v-model="Request.Name" label="Name" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="150" v-model="Request.Email" label="Email" :rules="emailRules" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.Committee" label="Committee" :rules="[v => !!v || 'Required']" required></v-text-field>

                <v-menu ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :rules="[v => !!v || 'Required']" required>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormatted"
                                      label="Date of Hearing"
                                      persistent-hint
                                      v-bind="attrs"
                                      @blur="date  = parseDate(dateFormatted)"
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date " no-title @input="menu1 = false"></v-date-picker>
                </v-menu>

                <div>How will you be joining the meeting</div>
                <v-radio-group v-model="Request.MeetingInPerson" :rules="[v => !!v || 'Required']" required>
                    <v-radio label="Remotely" value="false"></v-radio>
                    <v-radio label="In Person" value="true"></v-radio>
                </v-radio-group>
                <div>(Note: We will seek approval from the Chair for any remote requests. Per JR7-28)</div>

                <v-text-field v-model="Request.BillOfInterest" label="Bill or Item of Interest" :rules="[v => !!v || 'Required']" required></v-text-field>


                <div>Do you wish to testify</div>
                <v-radio-group v-model="Request.Testify" :rules="[v => !!v || 'Required']" required>
                    <v-radio label="Yes" value="true"></v-radio>
                    <v-radio label="No" value="false"></v-radio>
                </v-radio-group>
                <v-textarea auto-grow rows="1" v-model="Request.AdditionalComments" :rules="[v => !!v || 'Required']" required label="Additional Comments or Special Needs"></v-textarea>
                <v-textarea auto-grow rows="1" v-model="Request.WillThereBeOthers" label="Will There be Others with you in need of assistance"></v-textarea>
                <v-textarea auto-grow rows="1" v-model="Request.OtherContact" label="Other contact information you choose to provide which may be helpful"></v-textarea>

                <br />
                <div>Thank you for your interest in the legislative process.</div>
                <br />
                <v-btn @click="submit()">Submit</v-btn>
                <v-snackbar :color="snackColor" v-model="snackVisible" :timeout="4000" top right>
                    {{ snackText }}
                </v-snackbar>
            </v-form>

        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'ADARequest',
        components: {
            Sidebar
        },
        methods: {
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            submit() {
                if (this.$refs.form.validate()) {
                    this.Request.DateOfHearing = new Date();
                    this.Request.DateRecieved = new Date();
                    myfetch('/api/ADARequest', {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(this.Request)
                    }).then(res => {
                        if (res.ok) {
                           // app.ShowMessage('Submitted Request', 'success');
                            this.snackColor = 'success';
                            this.snackText = 'Submitted Request';
                            this.snackVisible = true;

                            this.Request = this.blankRequest;
                            this.$refs.form.resetValidation()

                        }
                    });
                }
            }
        },
        data: function () {
            return {
                search: '',
                snackColor: '',
                snackText: '',
                snackVisible: false,
                valid: true,
                loading: false,
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
                emailRules: [
                    v => !!v || "E-mail is required",
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ],
                menu1: false,
                blankRequest: {
                    AdarequestId: 0,
                    Name: null,
                    Email: null,
                    Committee: null,
                    DateOfHearing: new Date().toISOString().substr(0, 10),
                    BillOfInterest: null,
                    Testify: false,
                    MeetingInPerson: false,
                    WillThereBeOthers: null,
                    AdditionalComments: null,
                    OtherContact: null,
                    DateRecieved: null,
                },
                Request: {
                    AdarequestId: 0,
                    Name: null,
                    Email: null,
                    Committee: null,
                    DateOfHearing: new Date().toISOString().substr(0, 10),
                    BillOfInterest: null,
                    Testify: false,
                    MeetingInPerson: false,
                    WillThereBeOthers: null,
                    AdditionalComments: null,
                    OtherContact: null,
                    DateRecieved: null,
                },
            }
        },
        mounted: function () {
        },
        watch: {
            date(val) {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        computed: {
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            Title: function () {
                let title = 'ADA Request';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
    </script>
<style></style>
