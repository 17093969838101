<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn v-if="!loading && ShowDownloadCSV && items && items.length > 0" text x-small right @click="ExportCSV">
                    <v-icon left>
                        {{download}}
                    </v-icon>
                    {{DownloadText}}
                </v-btn>
            </v-row>
        </v-col>
        <v-col>
            <v-data-table :dense="dense"
                          :headers="visibleHeaders"
                          :items="filteredItems"
                          :items-per-page="iPP"
                          :loading="loading"
                          :show-expand="showExpand"
                          :item-key="itemKey"
                          :multi-sort="multiSort"
                          :group-by="groupBy"
                          @click:row="rowClick"
                          :hide-default-footer="hideDefaultFooter"
                          :disable-pagination="disablePagination"
                          :sort-by="sortBy"
                          :disable-sort="disableSort"
                          :sort-desc="sortDesc">
                <template v-slot:body.prepend="prop">
                    <tr v-if="!prop.isMobile && prop.headers.some(y=>y.filterable !== false)">
                        <td v-for="(header, index) in prop.headers" :key="header.value" :width="header.width" style="border: none;">
                            <v-row v-if="index == 0" align="center" no-gutters class="flex-nowrap">
                                <v-col cols="auto">
                                    <v-menu v-if="editHeaders !== false && index === 0" right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon style="opacity:1">{{more_vert}}</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense @click.stop>
                                            <v-list-item v-for="(item, i) in headers" :key="i">
                                                <v-list-item-action @click.stop>
                                                    <v-checkbox @change="UpdateHeaderVisibility" v-model="item.visible"></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content @click.stop>{{ item.text }}</v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col cols="auto">
                                    <v-text-field dense v-model="header.Filter" class="caption" placeholder="Filter" v-if="header.filterable !== false"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-else no-gutters>
                                <v-col cols="12">
                                    <v-text-field dense v-model="header.Filter" class="caption" placeholder="Filter" v-if="header.filterable !== false"></v-text-field>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                    <v-row v-else-if="prop.headers.some(y=>y.filterable !== false)" no-gutters>
                        <v-col cols="12" v-for="(header, index) in prop.headers" v-if="header.filterable !== false" :key="header.value" :width="header.width" style="border: none;">
                            <v-text-field :label="header.text" v-model="header.Filter" class="caption" :placeholder="header.text + ' Filter'"></v-text-field>
                        </v-col>
                    </v-row>
                </template>

                <!-- Pass down slots that have a scope -->
                <template v-for="(_, name) in scopedSlots" v-slot:[name]="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>

                <!-- Pass down slots that DONT have a scope -->
                <template v-slot:no-data>
                    <slot name="no-data">No data available</slot>
                </template>

                <template v-slot:no-results>
                    <slot name="no-results">No records found</slot>
                </template>

                <template v-slot:loading>
                    <slot name="loading">Loading data...</slot>
                </template>

            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
    import { mdiDotsVertical } from '@mdi/js';
    import { mdiDownload } from '@mdi/js';
    export default {
        name: 'FilterableDataTable',
        props: {
            headers: Array,
            items: Array,
            editHeaders: { type: Boolean, default: false },
            loading: { type: Boolean, default: false },
            itemKey: { type: String },
            componentName: { type: String },
            search: { type: String },
            hideDefaultFooter: { type: Boolean, default: false },
            disablePagination: { type: Boolean, default: false },
            sortBy: { type: [String, Array], default: function () { return []; } },
            sortDesc: { type: [Boolean, Array], default: function () { return []; } },
            multiSort: { type: Boolean, default: false },
            itemsPerPage: { type: Number, default: 25 },
            groupBy: { type: String },
            ShowDownloadCSV: { type: Boolean, default: false },
            DownloadText: { type: String, default: "Download (CSV)" },
            dense: { type: Boolean, default: true },
            disableSort: { type: Boolean, default: false },
            showExpand: { type: Boolean, default: false },
        },
        methods: {
            SetHeaderVisibility()
            {

            },
            UpdateHeaderVisibility()
            {

            },
            rowClick(any, row)
            {
                this.$emit('click:row', any, row);
            },
            arrayToCSV(array)
            {
                if (array && array.length > 0)
                {
                    const header = Object.keys(array[0]).join(',');
                    const rows = array.map(row =>
                    {
                        return Object.values(row)
                            .map(value =>
                            {
                                if (typeof value === 'string')
                                {
                                    return `"${value.replace(/"/g, '""')}"`;
                                }
                                return value;
                            })
                            .join(',');
                    });
                    return [header, ...rows].join('\n');
                }
                return '';
            },
            ExportCSV()
            {
                if (this.items && this.items.length > 0)
                {
                    let csv = this.arrayToCSV(this.items);
                    let fileName = document.title.replace('|', '-') + '.csv';
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            },
            nestedValue(item, path)
            {
                if (!item)
                    return null;

                let current = item;
                if (path)
                {
                    let paths = path.split('.');

                    for (let i = 0; i < paths.length; i++)
                    {
                        if (current[paths[i]])
                            current = current[paths[i]];
                        else
                            return null;
                    }
                }
                return current;
            },
        },
        data: function ()
        {
            return {
                more_vert: mdiDotsVertical,
                download: mdiDownload,
            }
        },
        watch: {
        },
        mounted()
        {
        },
        computed: {
            visibleHeaders: function ()
            {
                if (this.headers && this.headers.length > 0)
                {
                    return this.headers.filter(x => x.visible != false);
                }
                return [];
            },
            filteredItems: function ()
            {
                if (!this.items)
                    return [];

                let filters = this.headers.filter(x => x.Filter);
                let resultRows = this.items.filter((x) =>
                {
                    for (let i in filters)
                    {
                        let val = this.nestedValue(x, filters[i].value);

                        if (val !== null && val !== undefined && val.toString().toLowerCase().indexOf(filters[i].Filter.toLowerCase()) === -1)
                        {
                            if (isValidDate(val))
                            {
                                if (val.toLocaleString().toLowerCase().indexOf(filters[i].Filter.toLowerCase()) == -1)
                                {
                                    return false;
                                }
                            }
                            else
                                return false;
                        }
                        else if (val == null)
                            return false;
                    }
                    return true;
                });

                if (this.search)
                {
                    let search = this.search.toLowerCase();

                    resultRows = resultRows.filter((x) =>
                    {
                        for (let prop in x)
                        {
                            if (x[prop] && x[prop].toString().toLowerCase().indexOf(search) >= 0)
                                return true;
                        }

                        return false;
                    });
                }

                return resultRows;
            },
            iPP: function ()
            {
                return this.itemsPerPage;
            },
            scopedSlots: function ()
            {
                let scope = {};

                for (var name in this.$scopedSlots)
                {
                    if (name !== 'no-data' && name != 'no-results' && name != 'loading')
                        scope[name] = this.$scopedSlots[name];
                }

                return scope;
            }
        }
    };
</script>
<style></style>