<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">Political Control</div>
            <FilterableDataTable :headers="headers"
                          :items="items"
                          item-key="Year"
                          :sort-by="['Year']"
                          :sort-desc="[true]"
                          :items-per-page="999"
                          :loading="loading"
                          disable-pagination
                          hide-default-footer
                          loading-text="Loading... Please wait"
                          class="elevation-1">

            </FilterableDataTable>
        </v-col>
    </v-row>
</template>

<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Political ',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Historical/Political', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    if(response){
                        this.items = EncodeDatetimes(response);
                    }
                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                search: '',
                loading: true,
                items: [],
                headers: [
                    { text: 'Year', value: 'Year' },
                    { text: 'Governor', value: 'Governor' },
                    { text: 'Senate Republicans', value: 'SRepublicans' },
                    { text: 'Senate Democrats', value: 'SDemocrats' },
                    { text: 'Senate Other', value: 'SOther' },
                    { text: 'House Republicans', value: 'HRepublicans' },
                    { text: 'House Democrats', value: 'HDemocrats' },
                    { text: 'House Other', value: 'HOther' },
                ],

            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {

        }
    };
</script>
<style></style>