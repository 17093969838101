<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="Session && Session.Year" :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline" v-if="Session && Session.Year">{{Session.YearString}} Fiscal Notes</div>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 :search="search"
                                 item-key="DocumentId"
                                 multi-sort
                                 disable-pagination
                                 hide-default-footer
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.BillNumber="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumber + '&nbsp;'}}</router-link>
                </template>
                <template v-slot:item.FiscalNote="{ item }">
                    <a rel="noopener" :href="$MyLRCUrl.value+'api/Documents/' + item.DocumentId + '.pdf'">{{item.BillType + '&nbsp;' + item.BillNumber + '&nbsp;'}}<span v-if="item.SixC">{{'(Per' + '&nbsp;' + 'JR' + '&nbsp;'+ '6C‑4)'}}</span><span v-else>{{'(' +item.Version + ')'}}</span></a>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'FiscalNotes',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.Session = EncodeDatetimes(response);
                }).catch(error =>
                {
                    throw new Error(error);
                });

                this.loading = true;

                fetch('/api/Documents/FiscalNotes/' + this.SessionId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        var temp = EncodeDatetimes(response);

                        temp.forEach(x =>
                        {
                            x.FiscalNote = x.BillType + ' ' + x.BillNumber + ' ';

                            if (x.SixC)
                                x.FiscalNote += '(Per JR 6C-4)';
                            else
                                x.FiscalNote += '(' + x.Version +')';
                        });
                        
                        this.items = temp.sort(
                            function (a, b)
                            {
                                if (a.BillType === b.BillType)
                                {
                                    return a.BillNumber - b.BillNumber;
                                }
                                return a.BillType > b.BillType ? 1 : -1;
                            });
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
            appendItemText(item)
            {
                var res = "";
                if (item.SixC)
                {
                    res += "(Per JR 6C‑4)";
                } else
                {
                    res += item.Version;
                }
                return res;
            },
        },
        data: function ()
        {
            return {
                search: '',
                Session: {},
                loading: false,
                headers: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillNumber',
                        width: '15ch'
                    },
                    {
                        text: 'Fiscal Note',
                        align: 'start',
                        value: 'FiscalNote',
                        width: '25ch'
                    },

                    {
                        text: 'Title',
                        value: 'Title',
                        width: '*'
                    },
                ],
                items: [

                ]
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Loading Fiscal Notes...';
                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + " Fiscal Notes";
                }
                else
                {
                    title = 'Fiscal Notes';
                }
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }

    };
</script>
<style></style>