<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12" :md="2" :sm="12">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col cols="12" :md="10" :sm="12">
            <div class="headline" v-if="Session && Session.YearString">{{Session.YearString}} Bill Text Search</div>
            <GoogleSearch :SessionId="SessionId" v-if="SessionId != 68"></GoogleSearch>
            <v-text-field v-else v-model="search"
                          label="Search"
                          single-line
                          clearable
                          outlined
                          hide-details>
                <template v-slot:append>
                    <v-icon>{{searchIcon}}</v-icon>
                </template>
            </v-text-field>
            <FilterableDataTable :headers="headers"
                                 :items="bills"
                                 edit-headers
                                 :loading="loading"
                                 item-key="BillId"
                                 class="elevation-1 mt-3"
                                 hide-default-footer
                                 disable-pagination
                                 :component-name="$options.name">
                <template v-slot:item.Bill="{ item }">
                    <a rel="noopener" :href="'/Session/Bill/' + item.BillId">{{item.BillType}} {{item.BillNumberOnly}}</a>
                </template>
                <template v-slot:item.BillText="{ item }">
                    <div v-html="item.BillText">
                    </div>
                </template>
                <template v-slot:item.Title="{ item }">
                    <div v-html="item.Title">
                    </div>
                </template>
                <template v-slot:no-data>
                    <v-alert v-if="search" :value="true" type="info">
                        No Bills Match the Search
                    </v-alert>
                    <v-alert v-else :value="true" type="info">
                        Please conduct a search with the above search box.
                    </v-alert>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    import { mdiMagnify } from '@mdi/js';
    export default {
        name: 'TextSearch',
        props: ['SessionId'],
        components: {
            GoogleSearch, Sidebar, FilterableDataTable
        },
        methods: {
            getDataFromApi() {
                if (this.search && this.search != "") {
                    this.loading = true;

                    fetch('/api/Bills/Search/' + this.search + '?SessionIds=' + this.SessionId, { method: 'GET' }).then(response => response.json()).then(data => {
                        EncodeDatetimes(data);
                        this.bills = data;
                        this.loading = false;
                    });
                }
                else {
                    this.bills = [];
                }

            },
            LoadData() {
                this.loading = true;
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
            },
        },
        data: function () {
            return {
                searchIcon: mdiMagnify,
                loading: false,
                bills: [],
                Session: {},
                headers: [
                    {
                        text: 'Bill',
                        align: 'left',
                        sortable: true,
                        value: 'Bill',
                        width: "1px",
                        visible: true
                    },

                    {
                        text: 'Session', value: 'Year', width: '1px',
                        visible: false
                    },


                    {
                        text: 'Title', value: 'Title', width: '400px',
                        visible: true
                    },
                    {
                        text: 'Bill Text', value: 'BillText', width: '1px',
                        visible: true
                    },
                ],
                subHeaders: [
                    {
                        text: 'Edit',
                        align: 'left',
                        sortable: false,
                        value: 'DocumentId',
                        width: '1%',
                        visible: true
                    },
                    {
                        text: 'Version',
                        align: 'left',
                        sortable: true,
                        value: 'BillIteration',
                        width: '1%',
                        visible: true
                    },
                    {
                        text: 'Document Date',
                        align: 'left',
                        sortable: true,
                        value: 'DocumentDate',
                        width: '1%',
                        visible: true
                    },

                    {
                        text: '',
                        align: 'left',
                        sortable: false,
                        value: '',
                        width: '50%',
                        visible: true
                    },
                ],
                search: null,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            search: debounce(function () { this.getDataFromApi(); }, 500),
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'Bill Text Search';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>