
<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col md="2" sm="12" cols="12">
                <RedistrictingMenu></RedistrictingMenu>
            </v-col>
            <v-col md="10" sm="12" cols="12">
                <v-card flat>
                    <v-card-title>
                        <h3>
                            Redistricting Court Cases
                        </h3>
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            <b>South Dakota Court Cases</b>
                        </h3>
                        <ul>
                            <li class="pb-3">
                                <b>
                                    Bone Shirt v. Hazeltine, 200 F. Supp. 2d 1150 (D.S.D. 2002)
                                </b><br />
                                Voting Rights Act<br />
                                <a href="https://law.justia.com/cases/federal/district-courts/FSupp2/200/1150/2422078/">District Court Decision</a>
                            </li>
                        </ul>
                        <h3>
                            <b>Selected Federal Redistricting Decisions</b>
                        </h3>
                        <ul>
                            <li class="pb-3">
                                <b>Baker v. Carr, 369 U.S. 186 (1962)</b><br />
                                Equal Protection<br />
                                <a href="http://www.oyez.org/cases/1960-1969/1960/1960_6">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep369/usrep369186/usrep369186.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Reynolds v. Sims, 377 U. S. 533 (1964)</b><br />
                                Equal Protection<br />
                                <a href="http://www.oyez.org/cases/1960-1969/1963/1963_23">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep377/usrep377533/usrep377533.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Evenwel v. Abbott, 578 U.S. __ (2016)</b><br />
                                Equal Protection<br />
                                <a href="https://www.oyez.org/cases/2015/14-940">Oyez Synopsis</a><br />
                                <a href="https://www.supremecourt.gov/opinions/15pdf/14-940_ed9g.PDF">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>White v. Regester, 412 U.S. 755 (1973)</b><br />
                                Voting Rights Act<br />
                                <a href="https://www.oyez.org/cases/1972/72-147">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep412/usrep412755/usrep412755.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Thornburg v. Gingles, 478 U.S. 30 (1986)</b><br />
                                Voting Rights Act<br />
                                <a href="http://www.oyez.org/cases/1980-1989/1985/1985_83_1968">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep478/usrep478030/usrep478030.pdf"> Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Shaw V. Reno, - 509 U.S. 630 (1993)</b><br />
                                Voting Rights Act<br />
                                <a href="http://www.oyez.org/cases/1990-1999/1992/1992_92_357"> Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep509/usrep509630/usrep509630.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Miller v. Johnson - 515 U.S. 900 (1995)</b><br />
                                Voting Rights Act<br />
                                <a href="http://www.oyez.org/cases/1990-1999/1994/1994_94_631">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep515/usrep515900/usrep515900.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Bush v. Vera, 517 U.S. 952 (1996)</b><br />
                                Voting Rights Act<br />
                                <a href="http://www.oyez.org/cases/1990-1999/1995/1995_94_805">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep517/usrep517952/usrep517952.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Hunt v. Cromartie 532 U.S. 234 (2001)</b><br />
                                Voting Rights Act<br />
                                <a href="http://www.oyez.org/cases/2000-2009/2000/2000_99_1864">Oyez Synopsis</a><br />
                                <a href="https://tile.loc.gov/storage-services/service/ll/usrep/usrep532/usrep532234/usrep532234.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Shelby County v. Holder, 570 U.S. 529 (2013)</b><br />
                                Voting Rights Act<br />
                                <a href="https://www.oyez.org/cases/2012/12-96">Oyez Synopsis</a><br />
                                <a href="https://www.justice.gov/sites/default/files/crt/legacy/2013/02/05/shelbymerits.pdf">Supreme Court Decision[External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Alabama Legislative Black Caucus v. Alabama, 575 U.S. __ (2015)</b><br />
                                Voting Rights Act<br />
                                <a href="https://www.oyez.org/cases/2014/13-895">Oyez Synopsis</a><br />
                                <a href="https://www.supremecourt.gov/opinions/14pdf/13-895_o7jq.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                            <li class="pb-3">
                                <b>Rucho v. Common Cause, 588 US __ (2019)</b><br />
                                Partisanship<br />
                                <a href="https://www.oyez.org/cases/2018/18-422">Oyez Synopsis</a><br />
                                <a href="https://www.supremecourt.gov/opinions/18pdf/18-422_9ol1.pdf">Supreme Court Decision [External PDF]</a>
                            </li>
                        </ul>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
    const RedistrictingMenu = () => import("@/Redistricting/RedistrictingMenu.vue");

    export default {
        name: 'CourtCases',
        components: {
            RedistrictingMenu
        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {

        },
        watch: {

        },
        computed: {
            Title: function () {
                let title = 'Redistricting Court Cases';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>