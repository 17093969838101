<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat>
                <v-card-text>
                    <v-progress-linear indeterminate
                                       v-if="loading"></v-progress-linear>
                    <v-tabs class="pb-2" show-arrows>
                        <v-tab v-for="i in tabs"
                               :key="i"
                               @click="filterListing(i)">
                            {{ i }}
                        </v-tab>
                    </v-tabs>
                    <div class="headline pb-2" v-if="filterValue=='All'">All Legislators</div>
                    <div class="headline pb-2" v-if="filterValue=='House'">House Legislators</div>
                    <div class="headline pb-2" v-if="filterValue=='Senate'">Senate Legislators</div>
                    <div class="headline pb-2" v-if="filterValue=='District'">Legislators by District</div>
                    <div class="pb-2">
                        Republicans <v-chip>{{RepublicanCount}}</v-chip>
                        Democrats <v-chip>{{DemocratCount}}</v-chip>
                        Independents <v-chip>{{IndependentCount}}</v-chip>
                        <v-spacer></v-spacer>
                        <div class="text-end">
                            <div v-if="filterValue!='District' && Session!=null">
                                <span v-if="filterValue == 'All'"><a rel="noopener" :href="'/api/SessionMembers/' + Session.SessionId + '.pdf'">Roster View</a></span>
                                <span v-if="filterValue=='House'"><a rel="noopener" :href="'/api/SessionMembers/' + Session.SessionId + '.pdf?Chamber=H'">Roster View</a></span>
                                <span v-if="filterValue=='Senate'"><a rel="noopener" :href="'/api/SessionMembers/' + Session.SessionId + '.pdf?Chamber=S'">Roster View</a></span>
                            </div>
                        </div>
                    </div>
                    <FilterableDataTable v-if="DistrictView ==false"
                                         DownloadCSV="false"
                                  :headers="headers"
                                  :items="filteredSessionMembers"
                                  item-key="SessionMemberId"
                                  disable-pagination
                                  hide-default-footer
                                  multi-sort
                                  :loading="loading"
                                  loading-text="Loading... Please wait"
                                  class="elevation-1">
                        <template v-slot:item.Name="{ item }">
                            <router-link :to="'/Legislators/Profile/' + item.SessionMemberId">{{item.Name }}</router-link>
                            <span v-if="item.InactiveDate!= null">*</span>
                        </template>
                        <template v-slot:item.MemberType="{ item }">
                            <span v-if="item.MemberType == 'S'">Senate</span>
                            <span v-else-if="item.MemberType == 'H'">House</span>
                        </template>
                    </FilterableDataTable>

                    <div v-if="DistrictView">
                        Legislators By District
                        <v-card flat v-for="i in Districts" :key="i.DistrictId">
                            <v-card-title class="text-center">District {{i}}</v-card-title>
                            <v-card-subtitle class="pb-0">Counties {{CountyList(i)}}</v-card-subtitle>
                            <v-card-text>
                                <FilterableDataTable :headers="DistrictHeaders"
                                              :items="ListOfLegislatorsByDistrict(i)"
                                              item-key="Name"
                                              hide-default-footer
                                              multi-sort
                                              :loading="loading"
                                              loading-text="Loading... Please wait"
                                              class="elevation-1">
                                    <template v-slot:item.Name="{ item }">
                                        <router-link :to="'/Legislators/Profile/' + item.SessionMemberId">{{item.Name }}</router-link>
                                        <span v-if="item.InactiveDate!= null">*</span>
                                    </template>
                                    <template v-slot:item.MemberType="{ item }">
                                        <span v-if="item.MemberType == 'S'">Senate</span>
                                        <span v-else-if="item.MemberType == 'H'">House</span>
                                    </template>
                                    <template v-slot:item.Term="{ item }">
                                        <span v-if="item.Term == 'I'">Incumbent</span>
                                        <span v-if="item.Term == 'G'">Govs. Appointment</span>
                                        <span v-if="item.Term == 'N'">New Member</span>
                                        <span v-if="item.Term == 'M'">Other House</span>
                                    </template>
                                </FilterableDataTable>
                            </v-card-text>
                        </v-card>
                    </div>
                    <small><b><i>*The Legislator did not fulfill their entire term.</i></b></small>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Listing',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            filterListing(item) {
                if (item == 'District') {
                    this.DistrictView = true;
                } else {
                    this.DistrictView = false;
                }
                this.filterValue = item;
            },
            ListOfLegislatorsByDistrict(i) {
                return this.SessionMembers.filter(x => x.District == i);
            },
            LoadData() {
                this.loading = true;
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
                fetch('/api/SessionMembers/Session/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    let res = EncodeDatetimes(response);
                    if (res) {
                        this.SessionMembers = res;
                    }
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                    throw new Error(error);
                });

                fetch('/api/DistrictCounties', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    let res = EncodeDatetimes(response);
                    if (res) {
                        this.DistrictCounties = res;
                    }
                }).catch(error => {
                    console.error(error);
                    throw new Error(error);
                });
            },
            CountyList(district) {
                var d = this.DistrictCounties.filter(x => x.District.District == district && x.SessionId == this.SessionId);
                var res = "";
                d.forEach(x => {
                    res += x.County.County + ", ";
                });
                return res.replace(/,\s*$/, "");
            }
        },
        data: function () {
            return {
                filterValue: 'All',
                loading: true,
                tabs: ["All", "House", "Senate", "District"],
                headers: [
                    { text: 'Name', value: 'Name' },
                    { text: 'Chamber', value: 'MemberType' },
                    { text: 'District', value: 'District' },
                    { text: 'Party', value: 'PartyName' },
                ],
                DistrictHeaders: [
                    { text: 'Name', value: 'Name' },
                    { text: 'Chamber', value: 'MemberType' },
                    //{ text: 'District', value: 'District' },
                    { text: 'Party', value: 'PartyName' },
                    { text: 'Status', value: 'MemberTermName' },
                    { text: 'Occupation', value: 'Occupation' },
                ],
                DistrictCounties: [],
                SessionMembers: [
                ],
                loading: false,
                DistrictView: false,
                Session: null,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            filteredSessionMembers: function () {
                if (this.filterValue == 'All') {
                    return this.SessionMembers;
                } else if (this.filterValue == 'House') {
                    return this.SessionMembers.filter(x => x.MemberType == 'H');
                }
                else if (this.filterValue == 'Senate') {
                    return this.SessionMembers.filter(x => x.MemberType == 'S');
                }
                else if (this.filterValue == 'District') {
                    return this.SessionMembers;
                }
            },
            RepublicanCount: function () {
                if (this.filteredSessionMembers) {
                    return this.filteredSessionMembers.filter(x => x.Politics == 'R').length;
                }
                return 0;
            },
            DemocratCount: function () {
                if (this.filteredSessionMembers) {
                    return this.filteredSessionMembers.filter(x => x.Politics == 'D').length;
                }
                return 0;
            },
            IndependentCount: function () {
                if (this.filteredSessionMembers) {
                    return this.filteredSessionMembers.filter(x => x.Politics == 'I').length;
                }
                return 0;
            },
            Districts: function () {
                if (this.filteredSessionMembers) {
                    return Array.from(new Set(this.filteredSessionMembers.map(x => x.District))).sort((a, b) => (a > b) ? 1 : -1);
                }
                return [];
            },
            Title: function () {
                let title = 'Legislator Listing';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + ' ' + title;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>