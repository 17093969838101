<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="SessionId" :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12" v-if="Keyword">
            <div class="headline" v-if="Session && Session.YearString && Keyword && Keyword.Keyword">{{Session.YearString}} Subject - {{Keyword.Keyword}}</div>
            <v-data-table :headers="headers"
                          :items="Keyword.Bills"
                          :search="search"
                          item-key="BillNumber"
                          group-by="BillType"
                          disable-pagination
                          hide-default-footer
                          multi-sort
                          :sort-by="['BillType', 'BillNumber']"
                          :sort-desc="[false, false]"
                          :loading="loading"
                          loading-text="Loading... Please wait"
                          class="elevation-1">
                <template v-slot:group.header="{items, isOpen, toggle, group, groupby}">
                    <td colspan="2">
                        Bill Type: {{LongBillName(group)}}
                        <v-icon v-if="isOpen" @click="toggle">{{minus}}</v-icon>
                        <v-icon v-else @click="toggle">{{plus}}</v-icon>
                    </td>
                </template>
                <template v-slot:item.BillURL="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumber}}</router-link>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    import { mdiPlus } from '@mdi/js';
    import { mdiMinus } from '@mdi/js';
    export default {
        name: 'Subject',
        props: ['SubjectId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            loadData() {
                this.loading = true;
                fetch('/api/Keywords/' + this.SubjectId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else if (res.status === 404) {
                        if (this.Session && this.Session.SessionId) {
                            this.$router.push('/Session/SubjectIndex/' + this.Session.SessionId);
                        }
                        else if (this.$Session && this.$Session.SessionId) {
                            this.$router.push('/Session/SubjectIndex/' + this.$Session.SessionId);
                        }
                        else {
                            this.$router.push('/Session/SubjectIndex/' + 44);
                        }
                        return null;
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Keyword = EncodeDatetimes(response);
                    if (this.Keyword) {
                        this.SessionId = this.Keyword.SessionId;
                        fetch('/api/Sessions/' + this.SessionId, {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        }).then(res => {
                            this.loading = false;
                            if (res.status === 200 || res.status === 201) {
                                return res.json();
                            }
                            else {
                                return null;
                            }
                            throw new Error(res.statusText);
                        }).then(response => {
                            this.Session = EncodeDatetimes(response);
                        }).catch(error => {
                            throw new Error(error);
                        });
                    }
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });

                fetch('/api/BillNumbers/BillTypes', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.BillTypes = EncodeDatetimes(response);
                    this.SenateBillTypes = this.BillTypes.filter(x => x.BillType.includes('S') == true);
                    this.HouseBillTypes = this.BillTypes.filter(x => x.BillType.includes('H') == true);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            LongBillName(short) {
                if (this.BillTypes && this.BillTypes.length > 0 && this.BillTypes.find(x => x.BillType == short)) {
                    return this.BillTypes.find(x => x.BillType == short).LongBillType || '';
                }
                else {
                    return short;
                }
            },
        },
        data: function () {
            return {
                plus: mdiPlus,
                minus: mdiMinus,
                search: '',
                loading: true,
                SessionId: null,
                Session: null,
                headers: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillURL',
                        width: '15ch'
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        width: '*'
                    },
                ],
                Keyword: {

                },
                BillTypes: [],
            }
        },
        mounted: function () {
            this.loadData();
        },
        watch: {
            SubjectId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'Loading Subject';
                if (this.Session && this.Session.Year && this.Keyword && this.Keyword.Keyword) {
                    title = this.Session.Year + " Subject Index - " + this.Keyword.Keyword;
                }
                else if (this.Keyword && this.Keyword.Keyword) {
                    title = "Subject Index - " + this.Keyword.Keyword;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>