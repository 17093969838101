<template>
    <v-card flat>
        <v-card-text v-if="Member">
            <v-layout row wrap>
                <v-flex xs4 v-if="Member && Member.Picture">
                    <div v-if="expand" style="width:90%">
                        <div :aria-label="'A professional photo of ' + Member.Name"
                             role="img"
                             class="v-image v-responsive"
                             style="max-height: 600px;">
                            <div class="v-responsive__sizer" style="padding-bottom: 166.667%;"></div>
                            <div class="v-image__image v-image__image--cover" :style="generateBackgroundImage(false)"></div>
                            <div class="v-responsive__content"></div>
                        </div>

                        <div style="font-size: smaller;" v-if="Member.PictureCopyright">{{Member.PictureCopyright}}</div>
                        <div style="font-size: smaller;">Use by Permission Only.</div>
                    </div>
                    <div class="text-center" v-if="!expand">
                        <div class="v-avatar rounded-pill grey">
                            <div :aria-label="'A professional photo of ' + Member.Name"
                                 role="img"
                                 class="v-image v-responsive"
                                 style="height: 130px; width: 100px;">
                                <div class="v-responsive__sizer" style="padding-bottom: 166.667%;"></div>
                                <div class="v-image__image v-image__image--cover" :style="generateBackgroundImage(true)"></div>
                                <div class="v-responsive__content"></div>
                            </div>
                        </div>
                    </div>
                </v-flex>
                <v-flex xs8>
                    <div class="headline" v-if="expand && Member.Position">
                        {{Member.Position}}
                    </div>
                    <div v-if="expand">
                        <b>Party: </b><span v-if="Member && Member.Politics == 'R'">Republican</span>
                        <span v-else-if="Member && Member.Politics == 'D'">Democrat</span>
                        <span v-else-if="Member && Member.Politics == 'I'">Independent</span>
                    </div>
                    <div v-if="expand">
                        <b>Term: </b>
                        <span v-if="Member && Member.Term == 'I'">Incumbent</span>
                        <span v-else-if="Member && Member.Term == 'G'">Govs. Appointment</span>
                        <span v-else-if="Member && Member.Term == 'N'">New Member</span>
                        <span v-else-if="Member && Member.Term == 'M'">Other House</span>
                    </div>

                    <div class="headline" v-if="!expand">{{Member.Name}}<span v-if="Member.InactiveDate!= null">*</span></div>
                    <div><b>District:</b> {{Member.District}}</div>
                    <div v-if="expand"><b>Counties: </b>{{Member.Counties}}</div>
                    <div v-if="expand"><b>Occupation: </b>{{Member.Occupation}}</div>
                    <div v-if="expand && Member.HomeAddress1 != null">
                        <br />
                        <div><b>Mailing Address:</b></div>
                        <div>{{Member.HomeAddress1}}</div>
                        <div v-if="Member.HomeAddress2">{{Member.HomeAddress2}}</div>
                        <div>{{Member.HomeCity + ', ' + Member.HomeState + ' ' + Member.HomeZip}}</div>
                    </div>
                    <div v-if="!expand">
                        <v-icon>{{person}}</v-icon> <router-link :to="'/Legislators/Profile/' + Member.SessionMemberId">View Profile</router-link>
                    </div>
                    <div v-if="expand">
                        <br />
                    </div>
                    <div v-if="Member && Member.HomePhone">
                        <v-icon>{{phone}}</v-icon> <span v-if="expand"><b>Home: </b> </span><a rel="noopener" :href="'tel:' +Member.HomePhone">{{Member.HomePhone}}</a>
                    </div>
                    <div v-if="expand && Member && Member.CapitolPhone">
                        <v-icon>{{officephone}}</v-icon> <b>Capitol: </b><a rel="noopener" :href="'tel:' +Member.CapitolPhone">{{Member.CapitolPhone}}</a>
                    </div>
                    <div v-if="expand && Member && Member.BusinessPhone">
                        <v-icon>{{phone}}</v-icon> <b>Business: </b><a rel="noopener" :href="'tel:' +Member.BusinessPhone">{{Member.BusinessPhone}}</a>
                    </div>
                    <div v-if="expand && Member && Member.CellPhone != null">
                        <v-icon>{{cellphone}}</v-icon> <b>Cell: </b><a rel="noopener" :href="'tel:' +Member.CellPhone">{{Member.CellPhone}}</a>
                    </div>

                    <div v-if="expand">
                        <br />
                    </div>
                    <div v-if="Member.EmailState"><v-icon>{{email}}</v-icon> <a rel="noopener" :href="'mailto:' +Member.EmailState">{{Member.EmailState}}</a></div>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mdiEmail } from '@mdi/js';
    import { mdiAccount } from '@mdi/js';
    import { mdiPhone } from '@mdi/js';
    import { mdiCellphone } from '@mdi/js';
    import { mdiPhoneClassic } from '@mdi/js';
    export default {
        name: 'MemberCard',
        props: ['expand', 'Member'],
        components: {
        },
        methods: {
            generatePictureUrl(supportWebP, isSmall)
            {
                let basePath = `/api/Persons/Picture/${this.Member.PersonId}/${this.Member.SessionId}/${isSmall}/${supportWebP}`;
                return basePath;
            },
            generateBackgroundImage(isSmall)
            {
                return "background-image: image-set(" +
                    "'" + this.generatePictureUrl(true, isSmall) + "' type('image/webp'), " +
                    "'" + this.generatePictureUrl(false, isSmall) + "' type('image/jpeg'))" +
                    "; background-position: center center;";
            },
        },
        data: function ()
        {
            return {
                email: mdiEmail,
                person: mdiAccount,
                phone: mdiPhone,
                cellphone: mdiCellphone,
                officephone: mdiPhoneClassic,
            }
        },
        mounted: function ()
        {

        },
        computed: {
        }
    };
</script>
<style>
    .custom-avatar picture,
    .custom-avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* This ensures the image scales correctly */
    }
</style>