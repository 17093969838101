<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                Required Reports
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-flex xs12>
                        <GoogleSearch></GoogleSearch>
                    </v-flex>
                </v-container>
                <v-divider></v-divider>
                <FilterableDataTable :headers="headers"
                                     :items="items"
                                     item-key="DocumentId"
                                     :loading="loading"
                                     loading-text="Loading... Please wait"
                                     hide-default-footer
                                     disable-pagination
                                     class="elevation-1"
                                     id="requiredTable">
                    <template v-slot:item.SortDate="{ item }">
                        <a rel="noopener" :href="$MyLRCUrl.value +'api/Documents/Required Report/' + item.DocumentId + '.pdf'">
                            {{ item.DocumentDate | FormatDates}}
                        </a>
                    </template>
                    <template v-slot:item.Subject="{ item }">
                        <div v-if="item.RequiredReportType && item.RequiredReportType.Subject" v-html="item.RequiredReportType.Subject"></div>
                        <div v-if="item.RequiredReportType && item.RequiredReportType.ReceivedBy">
                            <div class="hidden-sm-and-down">
                                <b>Delivered To: </b>{{item.RequiredReportType.ReceivedBy}}
                            </div>

                        </div>
                        <div v-if="item.RequiredReportType && item.RequiredReportType.Frequency">
                            <div class="hidden-sm-and-down" :style="StrikeThrough(item)">
                                <b>Frequency: </b>{{item.RequiredReportType.Frequency}}
                            </div>
                        </div>
                        <div v-if="item.Repealed">
                            <div class="hidden-sm-and-down">
                                <b>Repealed: </b>{{item.Repealed | FormatDates}}
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.SDCLRULES="{ item }">
                        <a v-if="item.RequiredReportType && item.RequiredReportType.Rules" @click="quickFindSearch(item.RequiredReportType.Rules, 'Administrative')">
                            {{item.RequiredReportType.Rules}}
                        </a>
                        <div v-if="item.RequiredReportType && item.RequiredReportType.Sdcl" v-html="splitStatutes(item.RequiredReportType.Sdcl)"></div>
                    </template>
                    <template v-slot:item.Repealed="{ item }">
                        {{item.Repealed | FormatDates }}
                    </template>
                </FilterableDataTable>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");

    export default {
        name: 'RequiredReports',
        components: {
            FilterableDataTable, GoogleSearch
        },
        methods: {
            LoadData()
            {
                fetch('/api/Documents/DocumentType?Type=57', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    let itms = EncodeDate(response);
                    itms.forEach(x =>
                    {
                        if (x.DocumentDate)
                        {
                            x.SortDate = x.DocumentDate.getTime();
                        }
                        else
                        {
                            x.SortDate = null;
                        }
                        if (x.RequiredReportType)
                        {
                            x.Subject = (x.RequiredReportType.Subject || "") + (x.RequiredReportType.Frequency || +"") + (x.RequiredReportType.ReceivedBy || +"");
                            x.RequiredBy = x.RequiredReportType.RequiredBy;
                            x.Frequency = x.RequiredReportType.Frequency;
                            x.ReceivedBy = x.RequiredReportType.ReceivedBy;
                            x.Repealed = x.RequiredReportType.Repealed;
                            x.SDCLRULES = (x.RequiredReportType.Sdcl || "") + (x.RequiredReportType.Rules || "");
                        }
                        else
                        {
                            x.Subject = null;
                            x.RequiredBy = null;
                            x.Frequency = null;
                            x.ReceivedBy = null;
                            x.SDCLRULES = null;
                            x.Repealed = null;
                        }
                    });
                    this.items = itms;
                }).catch(error =>
                {
                    throw new Error(error);
                });
            },
            StrikeThrough(item)
            {
                if (item.Repealed)
                    return 'text-decoration: line-through';

                return '';
            },
            splitStatutes(s0)
            {
                var s1 = s0.replace(', and', ',').replace('&', ',').replace('&', ',');
                var statutes = s1.split(',');
                var res = "";
                statutes.forEach(s =>
                {
                    res += "<a rel='noopener' href='https://sdlegislature.gov/Statutes/" + s.trim() + "')'>" + s.trim() + "</a>, ";
                });
                return res.replace(/,\s*$/, "");
            },

            quickFindSearch(quickFind, type)
            {
                if (type == 'Codified_Laws')
                {
                    this.$router.push('/Statutes/' + quickFind);
                    //fetch('/api/Statutes/StatuteToId/' + quickFind, {
                    //    method: "GET",
                    //    credentials: "include",
                    //    headers: {
                    //        "Content-Type": "application/json"
                    //    },
                    //}).then(res =>
                    //{
                    //    this.loading = false;
                    //    if (res.status === 200 || res.status === 201)
                    //    {
                    //        return res.json().then(r =>
                    //        {
                    //            this.$router.push('/Statutes/' + r);
                    //        });
                    //    }
                    //    else
                    //    {
                    //        return null;
                    //    }
                    //    throw new Error(res.statusText);
                    //}).catch(error =>
                    //{
                    //    throw new Error(error);
                    //});
                }
                else if (type == 'Constitution')
                {
                    fetch('/api/Statutes/ArticleToId/' + quickFind, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json().then(r =>
                            {
                                this.$router.push('/Statutes/Constitution/' + r);
                            });
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });

                }
                else if (type == 'Administrative')
                {
                    fetch('/api/Rules/RuleToId/' + quickFind, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response =>
                    {
                        let ruleId = EncodeDatetimes(response);
                        if (ruleId)
                        {
                            this.$router.push('/Rules/Administrative/' + ruleId);
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });
                }
                else if (type == 'Session_Laws')
                {
                    fetch('/api/SessionLaws/ChapterToId/' + quickFind + '?SessionId=' + this.Session.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response =>
                    {
                        let SessionLawId = EncodeDatetimes(response);
                        if (SessionLawId)
                        {
                            this.$router.push('/Statutes/Session_Laws/Chapter/' + SessionLawId);
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });
                    window.location = '/Statutes/Session_Laws/Chapter/' + quickFind;
                }
            },

        },
        data: function ()
        {
            return {
                loading: true,
                moreInfo: false,
                fullHeaders: [
                    { text: 'Date', value: 'SortDate', filterable: false, },
                    { text: 'Subject', value: 'Subject' },
                    { text: 'SDCL/Rule', value: 'SDCLRULES' },
                    { text: 'Required By', value: 'RequiredBy' },],
                mobileHeaders: [
                    { text: 'Date', value: 'SortDate', filterable: false, },
                    { text: 'Subject', value: 'Subject' },
                    { text: 'Delivered To', value: 'RequiredReportType.ReceivedBy' },
                    { text: 'Frequency', value: 'RequiredReportType.Frequency' },
                    { text: 'Repealed', value: 'Repealed' },
                    { text: 'SDCL/Rule', value: 'SDCLRULES' },
                    { text: 'Required By', value: 'RequiredBy' },],
                items: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            headers: function ()
            {
                if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
                {
                    return this.mobileHeaders;
                } else
                {
                    return this.fullHeaders;
                }
            },
            Title: function ()
            {
                let title = 'Required Reports';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>
