<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <PersonCard :expand="true" :UserId="UserId"></PersonCard>
        </v-col>
    </v-row>
</template>
<script>
    const PersonCard = () => import("@/Components/PersonCard.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Profile',
        props: ['UserId'],
        components: {
            PersonCard, Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                loading: true,

            }
        },
        mounted: function () {
        },
        computed: {
        }
    };
</script>
<style></style>