<template>
    <v-row class="ma-0 pa-0">
        <v-col md="3" sm="12" v-if="YearCommittee && YearCommittee.Session.SessionId">
            <CommitteeSidebar :SessionId="YearCommittee.Session.SessionId"></CommitteeSidebar>
        </v-col>
        <v-col md="9" sm="12" cols="12">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-card flat>
                <v-card-title v-if="YearCommittee && YearCommittee.Session && YearCommittee.Committee">
                    {{YearCommittee.Committee.FullName}} &mdash; {{YearCommittee.Session.YearString}}
                </v-card-title>
                <v-card-text v-if="YearCommittee && YearCommittee.Committee">
                    <div v-if="YearCommittee.Committee.EmailAddress">
                        <span v-if="YearCommittee.Committee.FullName.includes('Task Force')" style="color: red">A person may submit a request to appear from a remote site and give testimony before this committee. Submit requests, a digital handout, or written testimony at least 24 hours before the scheduled meeting to <a rel="noopener" :href="'mailto:' + YearCommittee.Committee.EmailAddress+'?body=Name:%0ACity%20of%20Residence:%0APhone%20Number:%0ARepresenting:%0ABill%20Number:%0AProponent%20or%20Opponent:%0AMeeting%20Date:%0A'">{{YearCommittee.Committee.EmailAddress}}</a>.</span>
                        <span v-else style="color: red">A person may submit a request to appear from a remote site and give testimony before this committee. Submit requests, a digital handout, or written testimony at least 24 hours before the scheduled meeting to <a rel="noopener" :href="'mailto:' + YearCommittee.Committee.EmailAddress+'?body=Name:%0ACity%20of%20Residence:%0APhone%20Number:%0ARepresenting:%0ABill%20Number:%0AProponent%20or%20Opponent:%0AMeeting%20Date:%0A'">{{YearCommittee.Committee.EmailAddress}}</a>.</span>
                    </div>

                    <span v-if="currentRoute.includes('/Interim')" class="d-print-none">
                        <v-btn depressed x-small color="orange" style="vertical-align:top;" rel="noopener" :href="'/api/InterimCommittees/RSS/' + CommitteeId">
                            <span class="white--text">RSS</span><v-icon color="white" right>{{rss}}</v-icon>
                        </v-btn>
                        <v-btn depressed x-small color="purple" style="vertical-align:top;" rel="noopener" :href="'/api/InterimCommittees/Podcast/' + CommitteeId">
                            <span class="white--text">Podcast</span><v-icon color="white" right>{{podcast}}</v-icon>
                        </v-btn>
                    </span>
                    <span v-if="currentRoute.includes('/Session')" class="d-print-none">
                        <v-btn depressed x-small color="orange" style="vertical-align:top;" rel="noopener" :href="'/api/SessionCommittees/RSS/' + CommitteeId">
                            <span class="white--text">RSS</span><v-icon color="white" right>{{rss}}</v-icon>
                        </v-btn>
                        <v-btn depressed x-small color="purple" style="vertical-align:top;" rel="noopener" :href="'/api/SessionCommittees/Podcast/' + CommitteeId">
                            <span class="white--text">Podcast</span><v-icon color="white" right>{{podcast}}</v-icon>
                        </v-btn>
                    </span>

                    <v-tabs class="pb-2"
                            v-model="activeTab"
                            show-arrows>
                        <v-tab v-for="i in filteredTabs"
                               :key="i.value"
                               @click="switchTab(i)"
                               :to="i.Path">
                            <div v-if="YearCommittee.Committee.FullBody">
                                <span v-if="i.value =='Agendas'">Calendars</span>
                                <span v-else-if="i.value =='Minutes & Audio'">Journals & Audio</span>
                                <span v-else>{{ i.value }}</span>
                            </div>
                            <div v-else>
                                {{ i.value }}
                            </div>
                        </v-tab>
                    </v-tabs>
                    <router-view :CommitteeId="CommitteeId"></router-view>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import moment from 'moment'
    import { mdiPodcast } from '@mdi/js';
    import { mdiRss } from '@mdi/js';
    const CommitteeSidebar = () => import("@/Menus/CommitteeSidebar.vue");

    export default {
        name: 'Committee',
        props: ['CommitteeId'],
        components: {
            CommitteeSidebar
        },
        methods: {
            switchTab(item) {
                this.filterValue = item.value;
            },
            LoadData() {
                this.loading = true;
                if (this.currentRoute.includes('/Interim')) {
                    fetch('/api/InterimCommittees/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.YearCommittee = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });

                    fetch('/api/InterimCommittees/HasVideos/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.HasVideo = EncodeDatetimes(response);


                    }).catch(error => {
                        throw new Error(error);
                    });
                } else {
                    fetch('/api/SessionCommittees/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.YearCommittee = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                    fetch('/api/SessionCommittees/HasVideos/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.HasVideo = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            openDocumentHTML(DocumentId) {
                //return Promise.resolve("<p>STring that you want me to write</p>");
                return fetch('/api/Documents/' + DocumentId + '.html', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.text();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            activeClass() {
                if (this.filterValue) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        data: function () {
            return {
                podcast: mdiPodcast,
                rss: mdiRss,
                loading: true,
                filterValue: 'Detail',
                YearCommittee: {
                    Session: {
                        Year: '',
                        SessionId: null,
                    },
                    Committee: {
                        MeetingTime: '',
                        Room: {
                            Room: '',
                        },
                        Days: '',
                    },
                },
                Documents: [],
                activeTab: 0,
                hasVideo: false,
            }
        },
        mounted: function () {
            if (this.$route.query.Tab != null) {
                this.activeTab = this.$route.query.Tab;
                this.filterValue = this.$route.query.Tab;
            }
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            }
        },
        computed: {
            CommitteeType: function () {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                } else {
                    return "Session";
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
            filteredTabs: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.tabs.filter(x => x.type != 'Session');
                } else {
                    if (this.YearCommittee.Committee.FullBody) {
                        return this.tabs.filter(x => x.value != 'E-Minutes');
                    } else {
                        if (this.HasVideo) {
                            return this.tabs.filter(x => x.value != 'Committee Agendas');
                        } else {
                            return this.tabs.filter(x => x.value != 'Committee Agendas' && x.value != 'E-Minutes');
                        }

                    }
                }
            },
            tabs: function () {
                return [
                    {
                        Id: 0, value: 'Detail', type: 'All', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/Detail"
                    },
                    {
                        Id: 1, value: 'Agendas', type: 'All', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/Agendas"
                    },
                    {
                        Id: 2, value: 'Minutes & Audio', type: 'All', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/Minutes"
                    },
                    {
                        Id: 3, value: 'Bills', type: 'Session', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/Bills"
                    },
                    {
                        Id: 4, value: 'Documents', type: 'All', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/HearingDocuments"
                    },
                    {
                        Id: 5, value: 'Committee Agendas', type: 'Session', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/CombinedAgendas"
                    },
                    {
                        Id: 6, value: 'E-Minutes', type: 'Session', Path: "/" + this.CommitteeType + "/Committee/" + this.CommitteeId + "/eMinutes"

                    },
                ];
            },
            Title: function () {
                let Year = '';
                if (this.YearCommittee && this.YearCommittee.Session && this.YearCommittee.Session.YearString) {
                    Year = this.YearCommittee.Session.YearString + ' ';
                }
                let Name = '';
                if (this.YearCommittee && this.YearCommittee.Committee && this.YearCommittee.Committee.FullName) {
                    Name = this.YearCommittee.Committee.FullName;
                }
                let title = Year + Name;
                return title;
            },
            Description: function () {
                return this.Title;
            },
            EmailAddress: function () {
                if (this.YearCommittee && this.YearCommittee.Committee && this.YearCommittee.Committee.EmailAddress) {
                    return this.YearCommittee.Committee.EmailAddress;
                }
                return null;
            },
        },
        metaInfo()
        {
            //https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
            let removeEmpty = function (obj)
            {
                const newObj = {};

                Object.entries(obj).forEach(([k, v]) =>
                {
                    if (Array.isArray(v))
                    {
                        newObj[k] = new Array();

                        v.forEach(x =>
                        {
                            if (x === Object(x))
                            {
                                newObj[k].push(removeEmpty(x));
                            }
                            else if (x != null)
                            {
                                newObj[k].push(x);
                            }
                        });
                    }
                    else if (v === Object(v))
                    {
                        newObj[k] = removeEmpty(v);
                    } else if (v != null)
                    {
                        newObj[k] = obj[k];
                    }
                });
                return newObj;
            }

            let committee = {
                title: this.Title,
                script: [{
                    type: 'application/ld+json',
                    json: {
                        '@context': 'http://schema.org',
                        '@type': 'GovernmentOrganization',
                        'name': this.Title,
                        'email': this.EmailAddress,
                        'department': 'South Dakota Legislature'
                    }
                }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] },
                    { vmid: 'alternate', rel: 'alternate', type: 'application/rss+xml', href: window.location.origin + '/api/' + this.CommitteeType + 'Committees/RSS/' + this.CommitteeId }
                ]
            };

            committee = removeEmpty(committee);

            return committee;
        }
    };
</script>
<style></style>