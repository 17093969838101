<template>
<v-card xs12 flat>
    <v-card-title><h2>General</h2></v-card-title>
    <v-card-text>
        <v-layout row wrap>
            <v-flex class="pa-3" v-for="item in Documents">
                <v-card>
                    <v-card-title style="text-align:center;display:inherit;" class="text-center">{{item.Title}}</v-card-title>
                    <v-card-text>
                        <v-list>
                            <template v-for="i in item.Docs">
                                <v-divider class="ma-0"></v-divider>
                                <v-list-item rel="noopener" :href="createURL(i)" :key="i.Name">
                                    <v-list-item-content :name="i.Name">
                                        <v-list-item-title>
                                            {{i.Name}} <span v-if="i.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="createURL(i) == '#'"> - Document Not Found</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-card-text>
</v-card>
</template>
<script>
    export default {
        name: 'General',
        props: ['SessionId'],
        components: {

        },
        methods: {
            LoadData() {
                fetch('/api/Sessions/' + this.SessionId, {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                            }).then(res => {
                                if (res.status === 200 || res.status === 201) {
                                    return res.json();
                                }
                                else {
                                    return null;
                                }
                                throw new Error(res.statusText);
                            }).then(response => {
                                this.Session = EncodeDatetimes(response);
                            }).catch(error => {
                                throw new Error(error);
                            });
                fetch('/api/Documents/DocumentType?Type=24&Type=68&Type=69&Type=71&Type=72&Type=73&Type=74&SessionIds=' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Committee Schedule & Staff Assignments')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'lrcstaff.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Legislative Guide')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'guide.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Legislative Index')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'legislativeindex.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Legislator Reference Book')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'legislatorreferencebook.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Redbook')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename.includes('redbook.pdf')));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Seating Arrangement - House')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'houseseatingchart.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Seating Arrangement - Senate')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'senateseatingchart.pdf'));
                    this.Documents.filter(x => x.Title == 'Session Resources')[0].Docs.filter(x => x.Name == 'Session Calendar')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'calendar.pdf'));


                    this.Documents.filter(x => x.Title == 'Leadership')[0].Docs.filter(x => x.Name == 'Legislative Leadership')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'leaders.pdf'));
                    this.Documents.filter(x => x.Title == 'Leadership')[0].Docs.filter(x => x.Name == 'House Chairs/Leadership Directory')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'housedirectory.pdf'));
                    this.Documents.filter(x => x.Title == 'Leadership')[0].Docs.filter(x => x.Name == 'Senate Chairs/Leadership Directory')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'senatedirectory.pdf'));



                    this.Documents.filter(x => x.Title == 'Rules')[0].Docs.filter(x => x.Name == 'Joint Rules')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'jointrules.pdf'));
                    this.Documents.filter(x => x.Title == 'Rules')[0].Docs.filter(x => x.Name == 'House Rules')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'houserules.pdf'));
                    this.Documents.filter(x => x.Title == 'Rules')[0].Docs.filter(x => x.Name == 'Senate Rules')[0].url = this.createMYLRCLink(this.items.find(x => x.Filename == 'senaterules.pdf'));



                }).catch(error => {
                    throw new Error(error);
                });
            },
            createMYLRCLink(obj) {
                if (obj != null) {
                    return this.$MyLRCUrl.value + 'api/Documents/' + obj.DocumentId + '.pdf';
                } else {
                    return "#";
                }

            },
            createURL(i) {
                if (i.includeSessionId == true) {
                    return i.url + this.SessionId;
                }
                else {
                    return i.url;
                }
            },
        },
        data: function () {
            return {
                Session: {},
                Documents: [
                    {
                        Title: 'Session Resources',
                        Docs: [
                            {
                                Name: 'Committee Schedule & Staff Assignments',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Legislative Guide',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Legislative Index',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Legislator Reference Book',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Lobbyist Listing',
                                url: 'https://sosenterprise.sd.gov/BusinessServices/Lobbyist/LobbyistSearch.aspx',
                            },
                            {
                                Name: 'Redbook',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Seating Arrangement - House',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Seating Arrangement - Senate',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Session Calendar',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Staff Subject Areas',
                                url: '/Staff/SubjectArea',
                            },
                            {
                                Name: 'Housing Questionnaire',
                                url: '/Session/Housing',
                            },
                            {
                                Name: 'Participate Remotely',
                                url: 'https://mylrc.sdlegislature.gov/api/documents/212840.pdf',
                            },
                        ],
                    },
                    {
                        Title: 'Leadership',
                        Docs: [
                            {
                                Name: 'Legislative Leadership',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'House Chairs/Leadership Directory',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Senate Chairs/Leadership Directory',
                                url: '#doc.pdf',
                            },
                        ],
                    },
                    {
                        Title: 'Rules',
                        Docs: [
                            {
                                Name: 'Joint Rules',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'House Rules',
                                url: '#doc.pdf',
                            },
                            {
                                Name: 'Senate Rules',
                                url: '#doc.pdf',
                            },
                        ],
                    },

                    {
                        Title: 'Media',
                        Docs: [
                            {
                                Name: 'Memorial Service',
                                url: '/Session/Memorial',
                            },
                            {
                                Name: 'Podcast Feeds',
                                url: '/Session/Committees/',
                                includeSessionId: true,

                            },
                            {
                                Name: 'RSS Feeds',
                                url: '/Session/Committees/',
                                includeSessionId: true,

                            },
                        ],
                    }
                ],
                items: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'General Information';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + " General Information";
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>