var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FilterableDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"hide-default-header":"","loading":_vm.loading,"hide-default-footer":"","single-expand":false,"disable-pagination":"","items":_vm.Minutes,"item-key":"DocumentId","show-expand":"","dense":false},on:{"item-expanded":_vm.expandItem,"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.DocumentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DatePretty(item.DocumentDate))+" "),(item.DocumentId == 237274)?_c('span',[_vm._v(" (Transcript Attached)")]):_vm._e()]}},{key:"item.DocumentId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[(item.eMinutes)?_c('v-btn',{attrs:{"outlined":"","tile":"","to":'/Session/Committee/' + _vm.CommitteeId + '/eMinutes/' + item.eMinutes.Avid},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("E-Minutes")]):_vm._e(),(item.DocumentId > 0)?_c('v-btn',{attrs:{"tile":"","outlined":"","rel":"noopener","href":_vm.$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.pdf'},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("View PDF")]):_vm._e(),(item.AudioLink)?_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","outlined":"","rel":"noopener","href":item.AudioLink.Url,"aria-label":"SDPB Audio"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.mdiVolumeHigh))]),_vm._v(" Audio ")],1):_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":""},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("No Audio")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(!item.HTML)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(item.HTML)?_c('div',{domProps:{"innerHTML":_vm._s(item.HTML)}}):_vm._e()],1)]}},{key:"footer",fn:function(ref){return [(_vm.Minutes && _vm.Minutes.length > 1 && _vm.combindURLHTML)?_c('v-btn',{attrs:{"tile":"","outlined":"","block":"","rel":"noopener","href":_vm.combindURLHTML}},[_vm._v("View Combined HTML")]):_vm._e(),(_vm.Minutes && _vm.Minutes.length > 1 && _vm.combindURL)?_c('v-btn',{attrs:{"tile":"","outlined":"","block":"","rel":"noopener","href":_vm.combindURL}},[_vm._v("View Combined PDF")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }