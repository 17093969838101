<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">Office Reports</div>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/Constitutional">Constitutional Officers</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/ExecutiveBoard">Executive Board</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/SupremeCourt">Supreme Court Justice</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/SpeakersPresidents">Speakers and Presidents</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/USCongress">United States Congress</v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>

</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'OfficeReports',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,

            }
        },
        mounted: function () {

        },
        computed: {

        }
    };
</script>
<style></style>