<template>
    <v-row>
        <v-col cols="12">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-alert v-else-if="!Documents || Documents.length < 1" dense
                     text
                     type="info">No Hearing Documents.</v-alert>
            <Documents :Documents="Documents"
                       title="Hearing Documents"
                       show-tree-view>
            </Documents>
        </v-col>
    </v-row>

</template>
<script>
    const Documents = () => import("@/Documents/Documents.vue");

    export default {
        name: 'Hearings',
        props: ['CommitteeId'],
        components: {
            Documents
        },
        methods: {
            switchTab(item) {
                this.filterValue = item.value;
            },
            LoadData() {
                this.loading = true;
                if (this.currentRoute.includes('/Interim')) {

                    fetch('/api/Documents/InterimCommittee/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Documents = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                } else {

                    fetch('/api/Documents/SessionCommittee/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Documents = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },

            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            openDocumentHTML(DocumentId) {
                //return Promise.resolve("<p>STring that you want me to write</p>");
                return fetch('/api/Documents/' + DocumentId + '.html', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.text();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            async expandItem(item) {
                if (item.value && item.item.HTML == null) {
                    item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                }
            },
            rowClick(item, item1) {
                var temp = {
                    item: item,
                    value: !item1.isExpanded
                };
                if (!item1.isExpanded) {
                    this.expandItem(temp);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {
                        this.expandedAgendas.push(item);
                    } else {
                        this.expandedMinutes.push(item);

                    }
                } else {
                    let testing = this.expandedAgendas.findIndex(x => x.DocumentId == item.DocumentId);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {

                        this.expandedAgendas.splice(testing, 1);
                    } else {
                        this.expandedMinutes.splice(testing, 1);
                    }
                }
            },
            isDateInArray(needle, haystack) {
                for (var i = 0; i < haystack.length; i++) {
                    if (needle.getTime() === haystack[i].getTime()) {
                        return true;
                    }
                }
                return false;
            }
        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',
                tabs: [
                    {
                        value: 'Detail', type: 'All'
                    },
                    {
                        value: 'Agendas', type: 'All'
                    },
                    {
                        value: 'Minutes & Audio', type: 'All'
                    },
                    {
                        value: 'Bills', type: 'Session'
                    },
                    {
                        value: 'Hearing Documents', type: 'All'
                    },
                    {
                        value: 'Committee Agendas', type: 'Session'
                    },
                ],
                headers: [{ text: 'Date', value: 'DocumentDate' }, { text: '', value: 'DocumentId' }, { text: '', value: 'data-table-expand' },],
                billHeaders: [{ text: 'Bill', value: 'BillId' }, { text: 'Title', value: 'Title' },],
                expandedAgendas: [],
                expandedMinutes: [],
                Documents: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            }
        },
        computed: {
            CommitteeType: function () {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                } else {
                    return "Session";
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
        }
    };
</script>
<style></style>
