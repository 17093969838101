<template>
    <div>
        <v-row>
            <v-col cols="12" md="2">
                <FilterableDataTable :headers="mheaders"
                              hide-default-header
                              hide-default-footer
                              :single-expand="false"
                              disable-pagination
                              :items="Minutes"
                              item-key="eMinutes.Avid"
                              class="elevation-1">
                    <template v-slot:item.DocumentDate="{ item }">
                        <div>
                            <v-btn v-if="item.eMinutes != null" @click.stop="" text :to="'/Session/Committee/' + CommitteeId + '/eMinutes/' + item.eMinutes.Avid">
                                {{DatePretty(item.DocumentDate)}}
                            </v-btn>
                        </div>
                    </template>
                </FilterableDataTable>
            </v-col>
            <v-col cols="10" md="10">
                <v-responsive :aspect-ratio="16/9" v-if="items.url !=null">
                    <div v-if="render">
                        <video :width="'85%'" :height="'85%'" id="myvideo" controls v-if="render">
                            <source :src="changeURL" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </v-responsive>
                <v-container>
                    <FilterableDataTable :headers="headers"
                                  :loading="loading"
                                  hide-default-footer
                                  disable-pagination
                                  :items="items.avMinutes"
                                  class="elevation-1">
                        <template v-slot:item.x.Time="{ item }">
                            <div>
                                <v-btn @click="jumpToTime(item.x.Time)">{{item.x.Time}}</v-btn>
                            </div>
                        </template>
                    </FilterableDataTable>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import moment from 'moment'
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    export default {
        name: 'eMinutes',
        props: ['CommitteeId', 'Avid'],
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loading = true;

                fetch('/api/SessionCommittees/Documents/' + this.CommitteeId + "?Type=2&Type=4", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Minutes = EncodeDatetimes(response);
                    this.Minutes = this.Minutes.filter(x => x.eMinutes != null);
                }).catch(error => {
                    throw new Error(error);
                });

                if (this.Avid != null) {
                    fetch('/api/SessionCommittees/Avid/' + this.Avid, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                        this.render = true;
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            jumpToTime(time) {

                var a = time.split(':'); // split it at the colons
                var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

                var myvideo = document.getElementById('myvideo');
                myvideo.play();
                myvideo.pause();
                myvideo.currentTime = seconds;
                myvideo.play();
            },
        },
        data: function () {
            return {
                loading: true,
                render: true,
                items: {},
                Minutes: [],
                mheaders: [{ text: 'Date', value: 'DocumentDate' }],
                headers: [
                    {
                        text: 'Time', value: 'x.Time', filterable: false
                    },
                    {
                        text: 'Description', value: 'x.Description', filterable: false
                    },
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            },
            Avid: function () {
                this.render = false;
                this.LoadData();
                this.$forceUpdate();
            }
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            changeURL: function () {
                return this.items.url;
            },
        }
    };
    </script>
<style></style>