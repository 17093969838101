
<template>
    <v-container>
    <v-card flat>
        <v-card-title>
            Program Evaluation Reports
        </v-card-title>
        <v-card-text>
            <v-container>
                <GoogleSearch></GoogleSearch>
            </v-container>
            <v-divider></v-divider>
            <div>
                Welcome to the Program Evaluation Reports web page. Here you will find reports on program evaluations performed by the South Dakota Legislative Research Council’s Division of Fiscal and Program Analysis at the direction of the Executive Board of the State Legislature. The program evaluation process began in 2018 with the first reports issued in November 2018.
            </div>
            <v-divider></v-divider>
            <FilterableDataTable :headers="headers"
                          :items="items"
                          item-key="DocumentId"
                          sort-by="DocumentId"
                          :sort-desc="[true]"
                          disable-pagination
                          hide-default-footer
                          :loading="loading"
                          loading-text="Loading... Please wait"
                          class="elevation-1">
                <template v-slot:item.DocumentDate="{ item }">
                    {{GetDateYear(item.DocumentDate)}}
                </template>
                <template v-slot:item.Title="{ item }">
                    <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Program Evaluations/' + item.DocumentId + '.pdf'">
                        {{item.Title}}
                    </a>
                </template>
            </FilterableDataTable>
        </v-card-text>
    </v-card>
</v-container>

</template>
<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'ProgramEvaluation',
        components: {
            GoogleSearch, FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=43', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            GetDateYear(item) {
                return item.getFullYear();
            },

        },
        data: function () {
            return {
                loading: true,
                headers: [
                    { text: 'Report', value: 'Title' },
                    { text: 'Year', value: 'DocumentDate' },
                ],
                items: [

                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Program Evaluations';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>