export function DirectorHead(items)
{
    if (items == null || !items.length)
        return null;
        
    return items
        .find(x =>
            x.Title == 'Director' ||
            x.Title == 'Interim Director' ||
            (x.Title || '').indexOf('Director') >= 0 && (x.Title || '').indexOf('Deputy Director') == -1);
}

export function CodeCounselHead(items)
{
    if (items == null || !items.length)
        return null;

    return items
        .find(x => (x.Title || '').indexOf('Code Counsel') >= 0);
}

export function FiscalHead(items)
{
    if (items == null || !items.length)
        return null;

    return items.find(x =>
        (x.Title || '').indexOf('Chief Fiscal Analyst') >= 0);
}

export function Fiscal(items, includeHead)
{
    if (items == null || !items.length)
        return null;

    let head = null;

    if (includeHead)
        head = FiscalHead(items);

    return items.filter(x =>
        x.StaffGroupId == 2 ||
        x.UserId == (head || {}).UserId && x.UserId != null);
}

export function ResearchHead(items)
{
    if (items == null || !items.length)
        return null;

    return items.find(x =>
        (x.Title || '').indexOf('Chief Research & Legal Analyst') >= 0);
}

export function Research(items, includeHead)
{
    if (items == null || !items.length)
        return null;

    let head = null;

    if (includeHead)
        head = ResearchHead(items);

    return items.filter(x =>
        x.StaffGroupId == 3 ||
        x.UserId == (head || {}).UserId && x.UserId != null);
}

export function OperationsHead(items)
{
    if (items == null || !items.length)
        return null;

    return items.find(x =>
        (x.Title || '').indexOf('Chief of Operations') >= 0 ||
        (x.Title || '').indexOf('Chief of Legislative Operations') >= 0);
}

export function Operations(items, includeHead)
{
    if (items == null || !items.length)
        return null;

    let head = null;

    if (includeHead)
        head = OperationsHead(items);

    return items.filter(x =>
        x.StaffGroupId == 5 ||
        x.UserId == (head || {}).UserId && x.UserId != null);
}

export function ItHead(items)
{
    if (items == null || !items.length)
        return null;

    return items.find(x =>
        (x.Title || '').indexOf('Chief IT Officer') >= 0);
}

export function It(items, includeHead)
{
    if (items == null || !items.length)
        return null;

    let head = null;

    if (includeHead)
        head = ItHead(items);

    return items.filter(x =>
        x.StaffGroupId == 4 ||
        x.UserId == (head || {}).UserId && x.UserId != null);
}