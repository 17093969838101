<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat>
                <v-card-title>{{getYear()}} Intern Program</v-card-title>
                <v-card-text>
                    <div class="headline" style="text-align:center">{{getYear()}} Intern Information and Application are now available.</div>
                    Each session, the legislature employs 22 college students to serve as interns. Interns provide the legislators with the assistance they need to survive the rigors of a hectic legislative session. While in Pierre, interns can observe the lawmaking process in action, learn about the many issues confronting the state, and may earn college credit all at the same time.
                    <br /><br />
                    Applications may be submitted from late August to early October. You may contact the Legislative Research Council or the University Legislative Intern Coordinator with your questions. The majority and minority leaders in the Senate and the House of Representative select the interns. In November, you will be notified of the disposition of your application. Please contact us at 605-773-3251 at any time we may be of assistance.                    <br /><br />
                    <br /><br />
                    <div style="text-align:center"><a rel="noopener" href="mailto:InternProgram@sdlegislature.gov;?subject=Intern Program">Contact LRC Intern Coordinator</a></div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Intern',
        components: {
            Sidebar
        },
        methods: {
            getYear() {
                const now = new Date();
                //js getmonth zero based
                if (now.getMonth() + 1 >= 7) {
                    return now.getFullYear() + 1;
                }
                else {
                    return now.getFullYear();
                }
            },
        },
        data: function () {
            return {

            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'Intern Program';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>