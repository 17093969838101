<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <div class="headline text-center">
                Budget Resources
            </div>
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <BudgetReportCard v-if="currentRoute.includes('GOACDocuments')"></BudgetReportCard>
            <BudgetReportCard v-else-if="currentRoute.includes('GeneralFundReversions')"></BudgetReportCard>
            <BudgetReportCard v-else-if="currentRoute.includes('PersonalServiceDocuments')"></BudgetReportCard>
            <LettersOfIntent v-else-if="currentRoute.includes('LettersOfIntent')"></LettersOfIntent>
            <BudgetReportCard v-else-if="currentRoute.includes('BFMReports')"></BudgetReportCard>
            <div v-else>
                <v-alert type="info">Please select a report in the menu</v-alert>
            </div>
        </v-col>
    </v-row>
</template>
<script>

    const BudgetReportCard = () => import("@/Components/BudgetReportCard.vue");
    const LettersOfIntent = () => import("@/Budget/LettersOfIntent.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'BudgetResources',
        components: {
            BudgetReportCard, Sidebar, LettersOfIntent
        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let SubRouteName = 'Budget Resources';
                if (this.currentRoute.includes('GOACDocuments')) {
                    SubRouteName = 'Government Operations and Audit Documents '
                }
                else if (this.currentRoute.includes('GeneralFundReversions')) {
                    SubRouteName = 'General Fund Reversions '
                }
                else if (this.currentRoute.includes('PersonalServiceDocuments')) {
                    SubRouteName = 'Personal Services Documents '
                }
                else if (this.currentRoute.includes('LettersOfIntent')) {
                    SubRouteName = 'Letters of Intent & Associated Documents'
                }
                else if (this.currentRoute.includes('BFMReports')) {
                    SubRouteName = 'Bureau of Finance & Management (BFM) Reports'
                }
                return SubRouteName;
            },
            Description: function () {
                return this.Title;
            },
            currentRoute: function () {
                return this.$route.path;
            },
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>