<template>
    <v-card class="pr-4">
        <v-card-title v-if="currentRoute.includes('/Interim')">
            Archived Interims
        </v-card-title>
        <v-card-title v-else>
            Archived Sessions
        </v-card-title>
        <v-card-text>
            <v-card flat>
                <v-card-text>
                    <div v-if="currentRoute.includes('/Interim')"><GoogleSearch></GoogleSearch></div>
                    <div v-else>
                        <GoogleSearch></GoogleSearch>
                    </div>
                </v-card-text>
            </v-card>

            <FilterableDataTable :headers="filteredHeaders"
                          :items="filteredSessions"
                          item-key="Year"
                          :sort-by="['YearString']"
                          :sort-desc="true"
                          disable-pagination
                          hide-default-footer
                          loading-text="Loading... Please wait"
                          class="elevation-1">
                <template v-slot:item.Bills="{ item }">
                    <router-link :to="'/Session/Bills/' + item.SessionId">Bills</router-link>
                </template>
                <template v-slot:item.Committees="{ item }">
                    <router-link v-if="IsSession" :to="'/Session/Committees/' + item.SessionId">Committees</router-link>
                    <router-link v-else-if="!IsSession" :to="'/Interim/Committees/' + item.SessionId">Committees</router-link>
                </template>
                <template v-slot:item.ConferenceCommittees="{ item }">
                    <router-link :to="'/Session/ConferenceCommittees/' +item.SessionId">Conference Committees</router-link>
                </template>
                <template v-slot:item.General="{ item }">
                    <router-link :to="'/Session/General/' +item.SessionId">General</router-link>
                </template>
                <template v-slot:item.FinalReport="{ item }">
                    <div v-if="getReportID(item.SessionId) != null">
                        <a rel="noopener" :href="$MyLRCUrl.value +'api/Documents/AnnualReport/' + getReportID(item.SessionId) + '.pdf'">Final Report</a>
                    </div>
                </template>
                <template v-slot:item.Legislators="{ item }">
                    <router-link :to="'/Legislators/Listing/' +item.SessionId">Legislators</router-link>
                </template>
                <template v-slot:item.Session_Laws="{ item }">
                    <router-link :to="'/Statutes/Session_Laws/' +item.SessionId">Session Laws</router-link>
                </template>
            </FilterableDataTable>
            <div v-if="currentRoute.includes('/Session') || currentRoute.includes('/Statute') ">
                <a rel="noopener" href="http://sdsdl-montage.auto-graphics.com/#/customizeListView?listId=CustomizeEntityLists_97">State Archives: Session Laws of South Dakota, beginning with 1890 to the present.</a>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Archived',
        components: {
            GoogleSearch, FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Sessions', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Sessions = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
                if (this.currentRoute.includes('/Interim')) {

                    fetch('/api/Documents/DocumentType?Type=88', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.FinalReports = EncodeDatetimes(response);

                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            getReportID(id) {
                if (this.FinalReports.length > 0) {
                    var single = this.FinalReports.filter(x => x.SessionId == id);
                    if (single.length > 0) {
                        return single[0].DocumentId;
                    }

                }
                return null;
            },
        },
        data: function () {
            return {
                headers: [
                    { text: 'Year', value: 'YearString', Type: 'B' },
                    { text: 'Bills', value: 'Bills', Type: 'S', filterable: false },
                    { text: 'Committees', value: 'Committees', Type: 'B', filterable: false },
                    { text: 'Conference Committees', value: 'ConferenceCommittees', Type: 'S', filterable: false },
                    { text: 'General', value: 'General', Type: 'S', filterable: false },
                    { text: 'Final Report', value: 'FinalReport', Type: 'I', filterable: false },
                    { text: 'Legislators', value: 'Legislators', Type: 'B', filterable: false },
                    { text: 'Session Laws', value: 'Session_Laws', Type: 'S', filterable: false },
                ],
                FinalReports: [],
                Sessions: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            currentRoute() {
                this.LoadData();
            },
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            filteredHeaders: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.headers.filter(x => x.Type != 'S');
                } else {
                    return this.headers.filter(x => x.Type != 'I');
                }
            },
            filteredSessions: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.Sessions.filter(x => x.Type != 'Session' && !x.SpecialSession);
                } else {
                    return this.Sessions.filter(x => x.Type != 'Interim');
                }
            },
            IsSession: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return false;
                } else {
                    return true;
                }
            },
            Title: function () {
                return 'Archive';
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
    </script>
<style></style>