<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat>
                <v-card-title>Page Program</v-card-title>
                <v-card-text>
                    <div class="headline" style="text-align:center">2025 Page Program Application and Information</div>
                    Each legislative session, high school students from across South Dakota have an opportunity for a firsthand look at the legislative process by serving as pages for the South Dakota Legislature. Pages are appointed to serve in the House of Representatives or the Senate for four to nine days during one of the five term periods.
                    <br /><br />
                    The page program is a competitive program, so early application is encouraged. The preferred deadline is October 4, 2024 and the final deadline is October 18, 2024.
                    <br /><br />
                    Application is made through a sponsoring legislator or legislator-elect. Talk to a legislator or legislator-elect and ask for the privilege of them sponsoring you. Not sure who to approach as a sponsor? Any current legislator or legislator-elect can sponsor a page. Click on Find My Legislators to find the legislators in your district; however, a sponsoring legislator may be from any South Dakota district.
                    <span style="font-weight: bold;"><router-link to="/Legislators/Find">Find My Legislators</router-link></span>
                    <br />
                    <br />
                    The President Pro Tempore of the Senate selects the pages who will serve in the Senate. The Speaker of the House of Representatives selects the pages who will serve in the House. In early December, you will be notified by the Page Advisor regarding the disposition of your application.
                    <br /><br />
                    <b>If you need assistance or have any questions, please contact the Legislative Research Council by <a href="mailto:PageProgram@sdlegislature.gov">email</a> or at <a rel="noopener" href="tel:605-773-3251">605-773-3251</a>, or the Legislative Page Advisor at <a rel="noopener" href="tel:605-773-5257">605-773-5257</a>.</b>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Page',
        components: {
            Sidebar
        },
        methods: {
            getYear() {
                const now = new Date();
                //js getmonth zero based
                if (now.getMonth() + 1 > 7) {
                    return now.getFullYear() + 1;
                }
                else {
                    return now.getFullYear();
                }
            },
        },
        data: function () {
            return {

            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'Page Program';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>