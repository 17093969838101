<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12" v-if="Document && Document.SessionId">
            <Sidebar :SessionId="Document.SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <script type="application/ld+json" v-if="Document && Document.BillId">
            {
            "@context": "http://schema.org/",
            "@type": "Legislation",
            "name": "{{ Document.Year }} South Dakota {{Document.BillTypeFull + ' ' + Document.BillNumber}}",
            "description": "{{Document.FullTitle.replace(/["]/g,'')}}",
            "legislationType": "{{Document.BillTypeFull}}",
            "legislationIdentifier": "https://sdlegislature.gov/Session/Bill/{{Document.BillId}}/{{Document.DocumentId}}",
            "inLanguage": "English"
            }
            </script>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <div v-if="Document && Document.DocumentHtml" v-html="Document.DocumentHtml"></div>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'BillHTML',
        props: ['BillId', 'DocumentId'],
        components: {
            Sidebar
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/Bills/HTML/' + this.DocumentId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Document = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                search: '',
                loading: false,
                Document: {},
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            DocumentId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'Loading Bill...';
                if (this.Document && this.Document.BillTypeFull && this.Document.BillNumber) {
                    title = this.Document.Year + " " + this.Document.BillTypeFull + " " + this.Document.BillNumber;
                }
                else if (this.Document && this.Document.BillTypeFull && this.Document.BillNumber) {
                    title = this.Document.BillTypeFull + " " + this.Document.BillNumber;
                }
                return title;
            },
            Description: function () {
                if(this.Document && this.Document.FullTitle)
                {
                    return this.Document.FullTitle.replace(/["]/g,'');
                }
                return this.Title;
            },
            Year: function () {
                if(this.Document && this.Document.Year)
                {
                    return this.Document.Year;
                }
                return null;
            },
        },
        metaInfo() {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            'name': this.Title,
                            'description': this.Description,
                            'legislationType': (this.Document || {}).BillTypeFull,
                            'legislationIdentifier': window.location.href,
                            'inLanguage': 'English'
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] },
                    { vmid: 'alternate', rel: 'alternate', type:'application/pdf', href: 'https://mylrc.sdlegislature.gov/api/Documents/Bill/'+this.DocumentId+'.pdf?Year='+this.Year }
                ]
            }
        }
    };
</script>
<style></style>