<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                U.S. Congressional Delegation
            </div>
            <br />
            <div><b>Senate</b></div>
            <div>
                <div>
                    <a rel="noopener" href="https://www.thune.senate.gov/public/index.cfm/contact">Senator John Thune</a>
                </div>
                <div>
                    <a rel="noopener" href="https://www.rounds.senate.gov/contact">Senator Mike Rounds</a>
                </div>
            </div>
            <br />
            <div><b>House</b></div>
            <div>
                <a rel="noopener" href="https://dustyjohnson.house.gov/contact">Representative Dusty Johnson</a>
            </div>
        </v-col>
    </v-row>

</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Congress',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,
            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'U.S. Congressional Delegation';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>