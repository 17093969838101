<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col md="2" cols="12">
                <RedistrictingMenu></RedistrictingMenu>
            </v-col>
            <v-col md="10" cols="12">
                <v-card flat>
                    <v-card-title>
                        <h3>FAQs</h3>
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            What is reapportionment?
                        </h3>
                        <p>
                            Reapportionment refers to the process of assigning seats in the United States House of Representatives among the fifty states. Every ten years, the U.S. Census Bureau uses a formula based on population to calculate the apportionment of these seats. This method has been used in every census since 1940. After apportionment, it is up to the individual states to redraw their Congressional districts to account for any changes. Since South Dakota only has one congressional seat currently, reapportionment did not affect the state.
                        </p>

                        <h3>
                            What is redistricting?
                        </h3>
                        <p>
                            Redistricting is the process of redrawing legislative district boundaries from which public officials are elected. Redistricting in South Dakota takes place after the release of the decennial census data. Each district must have reasonably equal populations.
                        </p>

                        <h3>What is the decennial census?</h3>
                        <p>
                            The decennial census counts every resident of the United States. It has been conducted in years ending in "0" since 1790, as required by Article I, Section 2 of the U.S. Constitution.
                        </p>

                        <h3>Who has primary responsibility for redistricting?</h3>
                        <p>
                            In South Dakota, the state legislature is responsible for redistricting according to the South Dakota Constitution. All new districts must be voted on and then approved by the House and Senate, and then signed by the Governor.
                        </p>

                        <h3>
                            When do the new districts take effect?
                        </h3>
                        <p>
                            Once approved, the new Senate and House districts will become effective for the 2022 election cycle.
                        </p>

                        <h3>How are local districts drawn?</h3>
                        <p>
                            Local redistricting also follows the once-a-decade census. Local jurisdictions include county boards of supervisors, city councils, school boards, and some special district boards. The timeline for local redistricting varies, with some local redistricting not being finished for a year or more still.
                        </p>
                        <h3>
                            What are communities of interest?
                        </h3>
                        <p>
                            A community of interest is defined as a group of people in a geographical area, which share similar interests and priorities: social, cultural, ethnic, economic, religious, or political, such as:
                            <ul>
                                <li>Shared interests</li>
                                <li>Housing patterns</li>
                                <li>Living conditions</li>
                                <li>Neighborhoods</li>
                                <li>Economic status</li>
                                <li>Education</li>
                            </ul>
                        </p>
                        <h3>
                            How many people will be in each district?
                        </h3>
                        <p>
                            The number of people allocated to each district is based on the 2020 Census, which determined that the population of South Dakota was 886,667 as of April 1, 2020. The redistricting committees have determined that there will be 35 legislative districts. As a result, the target number for each Senate district in South Dakota should be approximately 25,333 people.
                        </p>
                        <h3>
                            When will we get the data?
                        </h3>
                        <p>
                            While the state has received the total population counts from the Census Bureau, it is still waiting for the redistricting data, known as the P.L. 94-171 data. That data will provide more granular details of how the population in South Dakota communities has changed. All that information will be delivered to the states by August 12, 2021, and be available to the public one week to 10 days later. On September 30, 2021, the Census Bureau will make available simple, easy-to-navigate tables.
                        </p>
                        <h3>
                            Where and when does the data needed for redistricting come from?
                        </h3>
                        <p>
                            The redistricting process usually begins after the census apportionment and redistricting data is released to the states. By law, the Bureau must deliver apportionment data to the President by December 31 of the year ending in zero, which is then transmitted to Congress and the states. Redistricting data, known as P.L. 94-171 data, should be delivered to the states by March 31 of the year ending in one. Due to the COVID-19 pandemic, Census data collection was delayed, leading to delays in the processing of the required deliverables. Therefore, South Dakota does not expect to have the necessary redistricting data until August 12, 2021, at which time it can begin the process of drafting maps.
                        </p>
                        <h3>
                            What criteria must be considered in redrawing district boundaries?
                        </h3>
                        <p>
                            State and federal law tells us how the redrawing of district boundaries must happen. First, South Dakota must:
                            <ul>
                                <li><i>Ensure equal population</i>. We must ensure that each legislative district roughly has the target number of people in it, which is 25,333 people.</li>
                                <li><i>Comply with the Federal Voting Rights Act</i>. This law was passed by Congress to ensure that minorities have an equal opportunity to elect representatives of their choice.</li>
                            </ul>
                        </p>
                        <p>
                            Additionally, South Dakota has additional criteria it uses in drawing the districts, which include:
                            <ul>
                                <li><i>Adherence to standards of population deviance</i>. That means states have some flexibility to draw districts with more or less people than the target number, if the reason the legislature did so is somewhere on this list.</li>
                                <li><i>Protection of minority voting rights</i>. South Dakota has its own requirements, outside of the Federal Voting Rights Act, to ensure minorities have equitable representation.</li>
                                <li><i>Be contiguous</i>. This means you can travel from one end of the district to the other without ever leaving the district.</li>
                                <li><i>Be compact</i>. There are many definitions of compactness. Generally, it refers to the shape and size of a district, still accounting for equal population, that tends to be more geometric and has a clear center point.</li>
                                <li><i>Respect geographical and political boundaries</i>. That means trying to keep counties, cities, reservations, etc. together within a district.</li>
                            </ul>
                        </p>
                        <h3>
                            How can the public get involved?
                        </h3>
                        <p>
                            Public participation plays an integral role in the redistricting process. There are many ways to get involved and have your voice be heard. You can contact the committee at <a href="mailto:sdredistricting@sdlegislature.gov">sdredistricting@sdlegislature.gov</a>. You can also attend a public hearing or contact your local representatives. See our resources and links pages for additional ways you can get involved.
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    const RedistrictingMenu = () => import("@/Redistricting/RedistrictingMenu.vue");

    export default {
        name: 'Faq',

        components: {
            RedistrictingMenu
        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {

        },
        watch: {

        },
        computed: {
            Title: function () {
                let title = 'Redistricting FAQ';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>