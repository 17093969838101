<template>
    <v-row>
        <v-col cols="12">
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='H' && Object.keys(Speaker).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Speaker: </b></v-col>
                <v-col xs="6" sm="8" md="9"><router-link :to="'/Legislators/Profile/' + Speaker.SessionMemberId">{{Speaker.Member.LastName}}, {{Speaker.Member.FirstName}}</router-link></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='H' && Object.keys(SpeakerProTempore).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Speaker Pro Tempore: </b></v-col>
                <v-col xs="6" sm="8" md="9"><router-link :to="'/Legislators/Profile/' + SpeakerProTempore.SessionMemberId">{{SpeakerProTempore.Member.LastName}}, {{SpeakerProTempore.Member.FirstName}}</router-link></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='H' && Object.keys(ChiefClerk).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Chief Clerk: </b></v-col>
                <v-col xs="6" sm="8" md="9"> {{ChiefClerk}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='S' && Object.keys(President).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>President: </b></v-col>
                <v-col xs="6" sm="8" md="9"><a rel="noopener" :href="'https://governor.sd.gov/governor/lt-governor.aspx'">{{President}}</a></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='S' && Object.keys(PresidentProTempore).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>President Pro Tempore: </b></v-col>
                <v-col xs="6" sm="8" md="9"><router-link :to="'/Legislators/Profile/' + PresidentProTempore.SessionMemberId">{{PresidentProTempore.Member.LastName}}, {{PresidentProTempore.Member.FirstName}}</router-link></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='S' && Object.keys(Secretary).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Secretary: </b></v-col>
                <v-col xs="6" sm="8" md="9">{{Secretary}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false && Object.keys(Chair).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Chair: </b></v-col>
                <v-col xs="6" sm="8" md="9" v-if="Chair.SessionMemberId"><router-link :to="'/Legislators/Profile/' + Chair.SessionMemberId">{{Chair.Member.LastName}}, {{Chair.Member.FirstName}}</router-link></v-col>
                <v-col xs="6" sm="8" md="9" v-else-if="Chair.PrettyName">{{Chair.PrettyName}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&Object.keys(LeadCoChair).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Lead Co-Chair: </b></v-col>
                <v-col xs="6" sm="8" md="9"><router-link :to="'/Legislators/Profile/' + LeadCoChair.SessionMemberId">{{LeadCoChair.Member.LastName}}, {{LeadCoChair.Member.FirstName}}</router-link></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false && CoChair.length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Co-Chair: </b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <span v-for="(i,index) in CoChair" :key="i.SessionMemberId"><router-link :to="'/Legislators/Profile/' + i.SessionMemberId">{{i.Member.LastName}}, {{i.Member.FirstName}}</router-link><span v-if="index < CoChair.length - 1">; </span></span>
                </v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&Object.keys(ViceChair).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Vice-Chair: </b></v-col>
                <v-col xs="6" sm="8" md="9" v-if="ViceChair.SessionMemberId"><router-link :to="'/Legislators/Profile/' + ViceChair.SessionMemberId">{{ViceChair.Member.LastName}}, {{ViceChair.Member.FirstName}}</router-link></v-col>
                <v-col xs="6" sm="8" md="9" v-else-if="ViceChair.PrettyName">{{ViceChair.PrettyName}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&Object.keys(ExOfficio).length !== 0">
                <v-col xs="6" sm="4" md="3"><b>Ex Officio: </b></v-col>
                <v-col xs="6" sm="8" md="9"><router-link :to="'/Legislators/Profile/' + ExOfficio.SessionMemberId">{{ExOfficio.Member.LastName}}, {{ExOfficio.Member.FirstName}}</router-link></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&CommitteeType == 'Session' &&  YearCommittee.Committee.Room != null && YearCommittee.Committee.Room.Room != '' ">
                <v-col xs="6" sm="4" md="3"><b>Room: </b></v-col>
                <v-col xs="6" sm="8" md="9">{{YearCommittee.Committee.Room}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&CommitteeType == 'Session' && YearCommittee.Committee.Days != null">
                <v-col xs="6" sm="4" md="3"><b>Days: </b></v-col>
                <v-col xs="6" sm="8" md="9">{{YearCommittee.Committee.Days}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false &&CommitteeType == 'Session' &&  YearCommittee.Committee.MeetingTime != null">
                <v-col xs="6" sm="4" md="3"><b>Time: </b></v-col>
                <v-col xs="6" sm="8" md="9">{{MeetingTimePretty(YearCommittee.Committee.MeetingTime)}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true">
                <v-col xs="6" sm="4" md="3"><b>Chairs/Leadership: </b></v-col>
                <v-col xs="6" sm="8" md="9" v-if="YearCommittee.Body=='H'"><a rel="noopener" :href="HouseDirectoryURL">View</a></v-col>
                <v-col xs="6" sm="8" md="9" v-if="YearCommittee.Body=='S'"><a rel="noopener" :href="SenateDirectoryURL">View</a></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true">
                <v-col xs="6" sm="4" md="3"><b>Seating Chart: </b></v-col>
                <v-col xs="6" sm="8" md="9" v-if="YearCommittee.Body=='H'"><a rel="noopener" :href="HouseSeatingChartURL">View</a></v-col>
                <v-col xs="6" sm="8" md="9" v-if="YearCommittee.Body=='S'"><a rel="noopener" :href="SenateSeatingChartURL">View</a></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='H'">
                <v-col xs="6" sm="4" md="3"><b>House Rules: </b></v-col>
                <v-col xs="6" sm="8" md="9"><a rel="noopener" :href="HouseRulesURL">View</a></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && YearCommittee.Body=='S'">
                <v-col xs="6" sm="4" md="3"><b>Senate Rules: </b></v-col>
                <v-col xs="6" sm="8" md="9"><a rel="noopener" :href="SenateRulesURL">View</a></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && JointRulesURL">
                <v-col xs="6" sm="4" md="3"><b>Joint Rules:</b></v-col>
                <v-col xs="6" sm="8" md="9"><a rel="noopener" :href="JointRulesURL">View</a></v-col>
            </v-row>
            <v-row xs12 v-if="Representatives.length >0">
                <v-col xs="6" sm="4" md="3"><b>Representatives: </b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <div v-for="(r,i) in Representatives" style="display:inline">
                        <router-link :to="'/Legislators/Profile/' +r.SessionMemberId">{{r.Member.LastName}},&nbsp;{{r.Member.FirstName}}&nbsp;({{r.Politics}})</router-link><span v-if="i<Representatives.length-1">; </span>
                    </div>
                </v-col>
            </v-row>
            <v-row xs12 v-if="Senators.length >0">
                <v-col xs="6" sm="4" md="3"><b>Senators: </b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <div v-for="(s,i) in Senators" style="display:inline">
                        <router-link :to="'/Legislators/Profile/' +s.SessionMemberId">{{s.Member.LastName}},&nbsp;{{s.Member.FirstName}}&nbsp;({{s.Politics}})</router-link><span v-if="i<Senators.length-1">; </span>
                    </div>
                </v-col>
            </v-row>
            <v-row xs12 v-if="CommitteeId == 504 || CommitteeId == 503">
                <v-col xs="6" sm="4" md="3"><b> </b></v-col>
                <v-col xs="6" sm="8" md="9"><b>Pending Senate Rule S4-1 Change</b></v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false && YearCommittee.NonCommitteeMembers != null && YearCommittee.NonCommitteeMembers.length >0">
                <v-col xs="6" sm="4" md="3"><b>Non-Legislators:</b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <div v-for="(s,i) in YearCommittee.NonCommitteeMembers" style="display:inline">
                        {{s.PrettyName}}<span v-if="i<YearCommittee.NonCommitteeMembers.length-1">; </span>
                    </div>
                </v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==false && YearCommittee.Staff.length >0">
                <v-col xs="6" sm="4" md="3"><b>Staff:</b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <div v-for="(s,i) in YearCommittee.Staff" style="display:inline">
                        <router-link v-if="s.UserId" :to="'/Staff/Profile/' +s.UserId">{{s.LastName}}, {{s.FirstName}}</router-link><span v-else>{{s.LastName}}</span><span v-if="i<YearCommittee.Staff.length-1">; </span>
                    </div>
                </v-col>
            </v-row>

            <v-row xs12 v-if="YearCommittee && YearCommittee.Committee.FullBody==true && Object.keys(Secretary).length !== 0">
                <v-col xs="6" sm="4" md="3" v-if="YearCommittee.Committee.FullBody==false"><b>Secretary:</b></v-col>
                <v-col xs="6" sm="8" md="9">{{YearCommittee.Secretary}}</v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && CommitteeType != 'Session' && (YearCommittee.Committee.FullBody==false && YearCommittee.Authority != null && YearCommittee.Authority != '') && YearCommittee.Authority.length >0">
                <v-col xs="6" sm="4" md="3"><b>Scope/Authority:</b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <span v-for="(auth,i) in YearCommittee.Authority">
                        <span v-if="auth.Sdcl != null && i ==0">SDCL </span>
                        <span v-if="auth.Sdcl !=null"><a rel="noopener" :href="'https://sdlegislature.gov/Statutes/' + auth.Sdcl">{{auth.Sdcl }}</a><span v-if="(YearCommittee.Authority.length -1) > i">, </span></span>
                        <span v-else-if="auth.BillNumber !=null"><router-link :to="'/Session/Bill/' + auth.BillId">{{auth.BillNumber}}</router-link><span v-if="(YearCommittee.Authority.length -1) > i">, </span></span>
                        <span v-else><a rel="noopener" :href="'https://mylrc.sdlegislature.gov/api/Documents/StudyScope/' + auth.DocumentId + '.pdf'">Study Scope</a><span v-if="(YearCommittee.Authority.length -1) > i">, </span></span>
                    </span>
                </v-col>
            </v-row>
            <v-row xs12 v-if="YearCommittee && currentRoute.includes('/Interim') && YearCommittee.MiscInfo">
                <v-col xs="6" sm="4" md="3"><b>Other Information:</b></v-col>
                <v-col xs="6" sm="8" md="9">
                    <div v-html="YearCommittee.MiscInfo"></div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

</template>
<script>
    import moment from 'moment'

    export default {
        name: 'Detail',
        props: ['CommitteeId'],
        components: {

        },
        methods: {
            LoadData() {
                this.loading = true;
                if (this.currentRoute.includes('/Interim')) {
                    fetch('/api/InterimCommittees/Detail/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok)
                        {
                            return res.json();
                        }
                        else if (res.status === 404) {
                            this.$router.push('/Interim/Committees/' + this.Session.SessionId);
                            return res.text().then(r => Promise.reject(r));
                        }
                        else {
                            return res.text().then(r => Promise.reject(r));
                        }
                        
                    }).then(response => {
                        this.YearCommittee = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });

                } else {
                    fetch('/api/SessionCommittees/Detail/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => 
                    {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else if (res.status === 404) {
                            this.$router.push('/Session/Committees/' + this.Session.SessionId);
                            return res.text().then(r => Promise.reject(r));
                        }
                        else {
                            return res.text().then(r => Promise.reject(r));
                        }
                    }).then(response => {
                        this.YearCommittee = EncodeDatetimes(response);
                        if (this.YearCommittee.CommitteeAgendas != null) {
                            var dates = this.YearCommittee.CommitteeAgendas.map(z => moment(z.DocumentDate).format('MM/DD/YYYY'));
                            this.UniqueDates = [new Set(dates)][0];
                        }
                        if (this.YearCommittee.FullBody) {
                            this.LoadRuleDocs();
                        }

                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            LoadRuleDocs() {
                fetch('/api/Documents/DocumentType?Type=72&Type=73&Type=74&SessionIds=' + this.Session.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    let jr = this.items.find(x => x.Filename == 'jointrules.pdf');
                    let hr = this.items.find(x => x.Filename == 'houserules.pdf');
                    let sr = this.items.find(x => x.Filename == 'senaterules.pdf');
                    let hsc = this.items.find(x => x.Filename == 'houseseatingchart.pdf');
                    let ssc = this.items.find(x => x.Filename == 'senateseatingchart.pdf');
                    let hd = this.items.find(x => x.Filename == 'housedirectory.pdf');
                    let sd = this.items.find(x => x.Filename == 'senatedirectory.pdf');


                    if (jr) { this.JointRulesURL = this.$MyLRCUrl.value + 'api/Documents/SessionRules/' + jr.DocumentId + '.pdf'; }
                    if (hr) { this.HouseRulesURL = this.$MyLRCUrl.value + 'api/Documents/SessionRules/' + hr.DocumentId + '.pdf'; }
                    if (sr) { this.SenateRulesURL = this.$MyLRCUrl.value + 'api/Documents/SessionRules/' + sr.DocumentId + '.pdf'; }
                    if (hsc) { this.HouseSeatingChartURL = this.$MyLRCUrl.value + 'api/Documents/SeatingChart/' + hsc.DocumentId + '.pdf'; }
                    if (ssc) { this.SenateSeatingChartURL = this.$MyLRCUrl.value + 'api/Documents/SeatingChart/' + ssc.DocumentId + '.pdf'; }
                    if (hd) { this.HouseDirectoryURL = this.$MyLRCUrl.value + 'api/Documents/Directory/' + hd.DocumentId + '.pdf'; }
                    if (sd) { this.SenateDirectoryURL = this.$MyLRCUrl.value + 'api/Documents/Directory/' + sd.DocumentId + '.pdf'; }

                }).catch(error => {
                    throw new Error(error);
                });

            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            openDocumentHTML(DocumentId) {
                //return Promise.resolve("<p>STring that you want me to write</p>");
                return fetch('/api/Documents/' + DocumentId + '.html', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.text();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            async expandItem(item) {
                if (item.value && item.item.HTML == null) {
                    item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                }
            },
            rowClick(item, item1) {
                var temp = {
                    item: item,
                    value: !item1.isExpanded
                };
                if (!item1.isExpanded) {
                    this.expandItem(temp);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {
                        this.expandedAgendas.push(item);
                    } else {
                        this.expandedMinutes.push(item);

                    }
                } else {
                    let testing = this.expandedAgendas.findIndex(x => x.DocumentId == item.DocumentId);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {

                        this.expandedAgendas.splice(testing, 1);
                    } else {
                        this.expandedMinutes.splice(testing, 1);
                    }
                }
            },
            combindAgendaURL(item) {
                var docs = this.YearCommittee.CommitteeAgendas.filter(x => moment(x.DocumentDate).format('MM/DD/YYYY') == moment(item).format('MM/DD/YYYY'));
                var str = "";
                docs.forEach(i => {
                    str += "&Ids=" + i.DocumentId;
                });
                str = str.substring(1, str.length);
                return this.$MyLRCUrl.value + 'api/Documents/Combined.pdf?' + str;
            },
            isDateInArray(needle, haystack) {
                for (var i = 0; i < haystack.length; i++) {
                    if (needle.getTime() === haystack[i].getTime()) {
                        return true;
                    }
                }
                return false;
            }
        },
        data: function () {
            return {
                loading: true,
                loadingHTML: true,
                filterValue: 'Detail',
                UniqueDates: [],
                HouseRulesURL: null,
                SenateRulesURL: null,
                JointRulesURL: null,
                SenateSeatingChartURL: null,
                HouseSeatingChartURL: null,
                HouseDirectoryURL: null,
                SenateDirectoryURL: null,
                tabs: [
                    {
                        value: 'Detail', type: 'All'
                    },
                    {
                        value: 'Agendas', type: 'All'
                    },
                    {
                        value: 'Minutes & Audio', type: 'All'
                    },
                    {
                        value: 'Bills', type: 'Session'
                    },
                    {
                        value: 'Hearing Documents', type: 'All'
                    },
                    {
                        value: 'Committee Agendas', type: 'Session'
                    },
                ],
                headers: [{ text: 'Date', value: 'DocumentDate' }, { text: '', value: 'DocumentId' }, { text: '', value: 'data-table-expand' },],
                billHeaders: [{ text: 'Bill', value: 'BillId' }, { text: 'Title', value: 'Title' },],
                expandedAgendas: [],
                expandedMinutes: [],
                CommitteeMemberTypes: [],
                YearCommittee: {
                    Session: {
                        Year: '',
                        SessionId: null,
                    },
                    Committee: {
                        MeetingTime: '',
                        Room: {
                            Room: '',
                        },
                        Days: '',
                    },
                },
                Documents: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            }
        },
        computed: {
            Session: function () {
                if (this.YearCommittee.Session && this.YearCommittee.Session.SessionId) {
                    return this.YearCommittee.Session;
                }
                else if (this.YearCommittee.SessionId) {
                    return this.$Sessions.value.find(x => x.SessionId == this.YearCommittee.SessionId);
                }
                else if (this.YearCommittee.Session) {
                    return this.$Sessions.value.find(x => x.SessionId == this.YearCommittee.Session.SessionId);
                }
                else {
                    return this.$Session;
                }
            },
            CommitteeType: function () {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                } else {
                    return "Session";
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
            filteredTabs: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.tabs.filter(x => x.type != 'Session');
                } else {
                    if (this.YearCommittee.Committee.FullBody) {
                        return this.tabs;
                    } else {
                        return this.tabs.filter(x => x.value != 'Committee Agendas');

                    }
                }
            },
            Senators: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    return this.YearCommittee.CommitteeMembers.filter(x => x.MemberType == 'S').sort((a, b) => (a.Member.LastName > b.Member.LastName) ? 1 : -1);
                } else {
                    return [];
                }
            },
            Representatives: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    return this.YearCommittee.CommitteeMembers.filter(x => x.MemberType == 'H').sort((a, b) => (a.Member.LastName > b.Member.LastName) ? 1 : -1);
                } else {
                    return [];
                }
            },
            Chair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Chair');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else if (this.YearCommittee && this.YearCommittee.NonCommitteeMembers && this.YearCommittee.NonCommitteeMembers.some(x => x.Chair == true)) {
                        return this.YearCommittee.NonCommitteeMembers.find(x => x.Chair == true);
                    }
                    return {};
                }
                else if (this.YearCommittee && this.YearCommittee.NonCommitteeMembers && this.YearCommittee.NonCommitteeMembers.some(x => x.Chair == true)) {
                    return this.YearCommittee.NonCommitteeMembers.find(x => x.Chair == true);
                }
                return {};
            },
            ViceChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Vice-Chair');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else if (this.YearCommittee && this.YearCommittee.NonCommitteeMembers && this.YearCommittee.NonCommitteeMembers.some(x => x.ViceChair == true)) {
                        return this.YearCommittee.NonCommitteeMembers.find(x => x.ViceChair == true);
                    }
                    return {};
                }
                else if (this.YearCommittee && this.YearCommittee.NonCommitteeMembers && this.YearCommittee.NonCommitteeMembers.some(x => x.ViceChair == true)) {
                    return this.YearCommittee.NonCommitteeMembers.find(x => x.ViceChair == true);
                }
                return {};
            },
            CoChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Co-Chair');
                    if (res.length > 0) {
                        return res;
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }

            },
            LeadCoChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Lead Co-Chair');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            ExOfficio: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'ex officio');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            Speaker: function () {
                if (this.YearCommittee.Committee != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.Position == 'Speaker');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            ChiefClerk: function () {
                if (this.YearCommittee.SessionStaff != undefined) {
                    return this.YearCommittee.SessionStaff.ChiefClerk;

                } else {
                    return {};
                }
            },
            President: function () {
                if (this.YearCommittee.President != undefined) {

                    return this.YearCommittee.President;
                }
                else {
                    return "";
                }

            },
            PresidentProTempore: function () {
                if (this.YearCommittee.Committee != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.Position == 'President Pro Tempore');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            SpeakerProTempore: function () {
                if (this.YearCommittee.Committee != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.Position == 'Speaker Pro Tempore');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            Secretary: function () {
                if (this.YearCommittee.SessionStaff != undefined) {
                    return this.YearCommittee.SessionStaff.Secretary;

                } else {
                    return {};
                }
            },
        }
    };</script>
<style></style>