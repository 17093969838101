<template>
    <v-card style="float:left;" class="d-print-none">
        <v-navigation-drawer floating
                             permanent
                             class="hidden-sm-and-down d-print-none">
            <v-list dense nav>
                <template v-for="item in SubMenuItems">
                    <v-list-item v-if="(item.url.toLowerCase().startsWith('https:') || item.url.toLowerCase().includes('/api/'))"
                                 :key="item.Id"
                                 rel="noopener" :href="formatURL(item)"
                                 link
                                 style="text-decoration:none !important">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                <span v-if="Session && Session.Year && item.prependYear">{{Session.YearString}}</span> {{ item.Title }} <span v-if="item.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="formatURL(item).includes('.csv')"><sup>(CSV)</sup></span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else
                                 :key="item.Id"
                                 :to="formatURL(item)"
                                 link
                                 style="text-decoration:none !important">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                <span v-if="Session && Session.Year && item.prependYear">{{Session.YearString}}</span> {{ item.Title }} <span v-if="item.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="formatURL(item).includes('.csv')"><sup>(CSV)</sup></span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
        <div class="hidden-md-and-up d-print-none">
            <div class="hidden-md-and-up justify-end" style="top: 5px">
                <v-btn @click.stop="drawer1 = !drawer1">
                    <v-icon>{{dotsvertical}}</v-icon>
                </v-btn>
            </div>
        </div>
        <v-navigation-drawer v-model="drawer1"
                             right
                             fixed
                             clase="d-print-none"
                             temporary>
            <v-list dense nav>
                <template v-for="item in SubMenuItems">
                    <v-list-item v-if="(item.url.toLowerCase().startsWith('https:') || item.url.toLowerCase().includes('/api/'))"
                                 :key="item.Id"
                                 rel="noopener" :href="formatURL(item)"
                                 link
                                 style="text-decoration:none !important">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                <span v-if="Session && Session.Year && item.prependYear">{{Session.YearString}}</span> {{ item.Title }} <span v-if="item.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="formatURL(item).includes('.csv')"><sup>(CSV)</sup></span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else
                                 :key="item.Id"
                                 :to="formatURL(item)"
                                 link
                                 style="text-decoration:none !important">
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                <span v-if="Session && Session.Year && item.prependYear">{{Session.YearString}}</span> {{ item.Title }} <span v-if="item.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="formatURL(item).includes('.csv')"><sup>(CSV)</sup></span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
    </v-card>

</template>
<script>
    import { mdiDotsVertical } from '@mdi/js';
    export default {
        name: 'Sidebar',
        props: {
            SessionId: { type: String | Number, default: null },
        },
        data: function ()
        {
            return {
                dotsvertical: mdiDotsVertical,
                allSubMenuItems: [
                    { Id: 1, Title: 'Bills', url: '/Session/Bills/', Route: '/Session/Bills', prependYear: true, includeSession: true },
                    { Id: 2, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Session/Bills', includeSession: true },
                    { Id: 3, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/Bills', includeSession: true },
                    { Id: 4, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/Bills', includeSession: true },

                    { Id: 5, Title: 'Bills', url: '/Session/Bills/', Route: '/Session/Vote', prependYear: true, includeSession: true },
                    { Id: 6, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Session/Vote', includeSession: true },
                    { Id: 7, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/Vote', includeSession: true },
                    { Id: 8, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/Vote', includeSession: true },

                    { Id: 9, Title: 'Bills', url: '/Session/Bills/', Route: '/Bill/', prependYear: true, includeSession: true },
                    { Id: 10, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Bill/', includeSession: true },
                    { Id: 11, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Bill/', includeSession: true },
                    { Id: 12, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Bill/', includeSession: true },

                    { Id: 13, Title: 'Bills', url: '/Session/Bills/', Route: '/Session/TextSearch', prependYear: true, includeSession: true },
                    { Id: 14, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Session/TextSearch', includeSession: true },
                    { Id: 15, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/TextSearch', includeSession: true },
                    { Id: 16, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/TextSearch', includeSession: true },

                    { Id: 17, Title: 'Bills', url: '/Session/Bills/', Route: '/Session/SubjectIndex', prependYear: true, includeSession: true },
                    { Id: 18, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Session/SubjectIndex', includeSession: true },
                    { Id: 19, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/SubjectIndex', includeSession: true },
                    { Id: 20, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/SubjectIndex', includeSession: true },

                    { Id: 21, Title: 'Bills', url: '/Session/Bills/', Route: '/Session/Subject/', prependYear: true, includeSession: true },
                    { Id: 22, Title: 'Text Search', url: '/Session/TextSearch/', Route: '/Session/Subject/', includeSession: true },
                    { Id: 23, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/Subject/', includeSession: true },
                    { Id: 24, Title: 'Bill Reports', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/Subject/', includeSession: true },

                    { Id: 25, Title: 'Bill Status Report', url: '/Session/BillStatusReport/', Route: '/Session/BillReports' },
                    { Id: 26, Title: 'Bills Signed By Governor', url: '/Session/BillReports/SignedByGovernor/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 27, Title: 'Bills Vetoed By Governor', url: '/Session/BillReports/VetoedByGovernor/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 28, Title: 'Bills On Governors Desk', url: '/Session/BillReports/GovernorsDesk/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 29, Title: 'Subject Index', url: '/Session/SubjectIndex/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 30, Title: 'Custom Bill Report', url: '/Session/BillReports/Custom/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 31, Title: 'Fiscal Note Report', url: '/Session/BillReports/FiscalNotes/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 32, Title: 'Hoghouse Report', url: '/Session/BillReports/Hoghouse/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 33, Title: 'Prison/Jail Population Cost Estimates', url: '/Session/BillReports/PrisonJail/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 34, Title: 'Withdrawn Report', url: '/Session/BillReports/Withdrawn/', Route: '/Session/BillReports', includeSession: true },
                    { Id: 35, Title: 'Emergency Clause Report', url: '/Session/BillReports/Emergency/', Route: '/Session/BillReports', includeSession: true },

                    { Id: 36, Title: 'Legislator Contacts', Route: '/Legislators', url: '/Legislators/Contacts/', includeSession: true, },
                    { Id: 37, Title: 'Find My Legislators', Route: '/Legislators', url: '/Legislators/Find', },
                    { Id: 38, Title: 'Legislator Listing', Route: '/Legislators', url: '/Legislators/Listing/', includeSession: true, prependYear: true, },
                    { Id: 39, Title: 'Historical Listing', Route: '/Legislators', url: '/Historical', },
                    { Id: 40, Title: 'Legislator Term Limits', Route: '/Legislators', url: 'https://mylrc.sdlegislature.gov/api/Documents/72128.pdf', },
                    { Id: 41, Title: 'Address List', Route: '/Legislators', url: '/api/SessionMembers/', includeSession: true, includeFileType: '.csv', },
                    { Id: 42, Title: 'Archived', Route: '/Legislators', url: '/Session/Archived', },

                    { Id: 43, Title: 'Historical Listing', url: '/Historical', Route: '/Historical', not: '/Legislators' },
                    { Id: 44, Title: 'General Reports', url: '/Historical/Reports/GeneralReports', Route: '/Historical', not: '/Legislators' },
                    { Id: 45, Title: 'Office Reports', url: '/Historical/Reports/OfficeReports', Route: '/Historical', not: '/Legislators' },
                    { Id: 46, Title: 'Tenure Reports', url: '/Historical/Reports/TenureReports', Route: '/Historical', not: '/Legislators' },
                    { Id: 47, Title: 'Submit New Information', url: '/ContactUs', Route: '/Historical', not: '/Legislators' },

                    { Id: 48, Title: 'Foreword', url: '/Statutes/Session_Laws/Foreword/', Route: '/Statutes/Session_Laws', includeSession: true },
                    { Id: 49, Title: 'Cross Reference', url: '/Statutes/Session_Laws/CrossReference/', Route: '/Statutes/Session_Laws', includeSession: true },
                    { Id: 50, Title: 'Topical Index', url: '/Statutes/Session_Laws/TopicalIndex/', Route: '/Statutes/Session_Laws', includeSession: true },
                    { Id: 51, Title: 'Table of Contents', url: '/Statutes/Session_Laws/', Route: '/Statutes/Session_Laws', includeSession: true },
                    { Id: 52, Title: 'SDCL Index', url: '/Statutes/Session_Laws/SDCLIndex/', Route: '/Statutes/Session_Laws', includeSession: true },
                    { Id: 200, Title: 'Session Law Book', url: '', Route: '/Statutes/Session_Laws', Hidden: true, LoadUrl: '/api/SessionLaws/Book' },

                    { Id: 53, Title: 'Constitution', url: '/Constitution', Route: '/Constitution' },
                    { Id: 54, Title: 'Codified Laws', url: '/Statutes', Route: '/Statutes' },

                    { Id: 55, Title: 'Administrative Rules List', url: '/Rules/Administrative', Route: '/Rules/Administrative' },
                    { Id: 56, Title: 'Current Register', url: 'https://mylrc.sdlegislature.gov/api/Documents/210727.pdf', Route: '/Rules/Administrative' },
                    { Id: 57, Title: 'Archived Registers', url: '/Rules/Registers/', Route: '/Rules/Administrative', includeSession: true },
                    { Id: 58, Title: 'Administrative Rules Manual', url: '/Rules/RulesManual', Route: '/Rules/Administrative' },
                    { Id: 59, Title: 'Rules Review Committee', url: '/Interim/Committee/461', Route: '/Rules/Administrative' },
                    { Id: 60, Title: 'Rules.sd.gov', url: 'https://rules.sd.gov', Route: '/Rules/Administrative' },
                    { Id: 61, Title: 'Administrative Rules Process', url: 'https://mylrc.sdlegislature.gov/api/Documents/IssueMemo/124666.pdf?Year=2017', Route: '/Rules/Administrative' },

                    { Id: 62, Title: 'Administrative Rules List', url: '/Rules/Administrative', Route: '/Rules/Registers' },
                    { Id: 63, Title: 'Current Register', url: 'https://mylrc.sdlegislature.gov/api/Documents/210727.pdf', Route: '/Rules/Registers' },
                    { Id: 64, Title: 'Archived Registers', url: '/Rules/Registers/', Route: '/Rules/Registers', includeSession: true },
                    { Id: 65, Title: 'Administrative Rules Manual', url: '/Rules/RulesManual', Route: '/Rules/Registers' },
                    { Id: 66, Title: 'Rules Review Committee', url: '/Interim/Committee/461', Route: '/Rules/Registers' },
                    { Id: 67, Title: 'Rules.sd.gov', url: 'https://rules.sd.gov', Route: '/Rules/Registers' },
                    { Id: 68, Title: 'Administrative Rules Process', url: 'https://mylrc.sdlegislature.gov/api/Documents/IssueMemo/124666.pdf?Year=2017', Route: '/Rules/Registers' },

                    { Id: 69, Title: 'Administrative Rules List', url: '/Rules/Administrative', Route: '/Rules/RulesManual' },
                    { Id: 70, Title: 'Current Register', url: 'https://mylrc.sdlegislature.gov/api/Documents/210727.pdf', Route: '/Rules/RulesManual' },
                    { Id: 71, Title: 'Archived Registers', url: '/Rules/Registers/', Route: '/Rules/RulesManual', includeSession: true },
                    { Id: 72, Title: 'Administrative Rules Manual', url: '/Rules/RulesManual', Route: '/Rules/RulesManual' },
                    { Id: 73, Title: 'Rules Review Committee', url: '/Interim/Committee/461', Route: '/Rules/RulesManual' },
                    { Id: 74, Title: 'Rules.sd.gov', url: 'https://rules.sd.gov', Route: '/Rules/RulesManual' },
                    { Id: 75, Title: 'Administrative Rules Process', url: 'https://mylrc.sdlegislature.gov/api/Documents/IssueMemo/124666.pdf?Year=2017', Route: '/Rules/RulesManual' },

                    { Id: 76, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/MonthlyReport' },
                    { Id: 77, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/MonthlyReport' },
                    { Id: 78, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/MonthlyReport' },
                    { Id: 79, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/MonthlyReport' },
                    { Id: 80, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/MonthlyReport' },

                    { Id: 81, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/GeneralFundReceipts' },
                    { Id: 82, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/GeneralFundReceipts' },
                    { Id: 83, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/GeneralFundReceipts' },
                    { Id: 84, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/GeneralFundReceipts' },
                    { Id: 85, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/GeneralFundReceipts' },

                    { Id: 86, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/Expenditures' },
                    { Id: 87, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/Expenditures' },
                    { Id: 88, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/Expenditures' },
                    { Id: 89, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/Expenditures' },
                    { Id: 90, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/Expenditures' },

                    { Id: 91, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/MedicalServices' },
                    { Id: 92, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/MedicalServices' },
                    { Id: 93, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/MedicalServices' },
                    { Id: 94, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/MedicalServices' },
                    { Id: 95, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/MedicalServices' },

                    { Id: 96, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/TrustFunds' },
                    { Id: 97, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/TrustFunds' },
                    { Id: 98, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/TrustFunds' },
                    { Id: 99, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/TrustFunds' },
                    { Id: 100, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/TrustFunds' },

                    { Id: 101, Title: 'General Fund Receipts', url: '/Budget/GeneralFundReceipts', Route: '/Budget/PrisonPopulations' },
                    { Id: 102, Title: 'Expenditures & Encumbrances', url: '/Budget/Expenditures', Route: '/Budget/PrisonPopulations' },
                    { Id: 103, Title: 'Medical Services', url: '/Budget/MedicalServices', Route: '/Budget/PrisonPopulations' },
                    { Id: 104, Title: 'Trust Funds', url: '/Budget/TrustFunds', Route: '/Budget/PrisonPopulations' },
                    { Id: 105, Title: 'Prison Populations', url: '/Budget/PrisonPopulations', Route: '/Budget/PrisonPopulations' },

                    { Id: 106, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/Resources' },
                    { Id: 107, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/Resources' },
                    { Id: 107, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/Resources' },
                    { Id: 108, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/Resources' },
                    { Id: 109, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/Resources' },
                    { Id: 110, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/Resources' },
                    { Id: 111, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/Resources' },

                    { Id: 113, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/GOACDocuments' },
                    { Id: 114, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/GOACDocuments' },
                    { Id: 115, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/GOACDocuments' },
                    { Id: 116, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/GOACDocuments' },
                    { Id: 117, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/GOACDocuments' },
                    { Id: 118, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/GOACDocuments' },
                    { Id: 112, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/GOACDocuments' },

                    { Id: 119, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/GeneralFundReversions' },
                    { Id: 120, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/GeneralFundReversions' },
                    { Id: 121, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/GeneralFundReversions' },
                    { Id: 122, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/GeneralFundReversions' },
                    { Id: 123, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/GeneralFundReversions' },
                    { Id: 124, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/GeneralFundReversions' },
                    { Id: 125, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/GeneralFundReversions' },

                    { Id: 126, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 127, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 128, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 129, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 130, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 131, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/PersonalServiceDocuments' },
                    { Id: 132, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/PersonalServiceDocuments' },

                    { Id: 133, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/LettersOfIntent' },
                    { Id: 134, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/LettersOfIntent' },
                    { Id: 135, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/LettersOfIntent' },
                    { Id: 136, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/LettersOfIntent' },
                    { Id: 137, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/LettersOfIntent' },
                    { Id: 138, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/LettersOfIntent' },
                    { Id: 139, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/LettersOfIntent' },

                    { Id: 140, Title: 'Government Operations and Audit Documents', url: '/Budget/GOACDocuments', Route: '/Budget/BFMReports' },
                    { Id: 141, Title: 'General Fund Reversions', url: '/Budget/GeneralFundReversions', Route: '/Budget/BFMReports' },
                    { Id: 142, Title: 'Personal Services Docs', url: '/Budget/PersonalServiceDocuments', Route: '/Budget/BFMReports' },
                    { Id: 143, Title: 'Letters of Intent & Associated Documents', url: '/Budget/LettersOfIntent', Route: '/Budget/BFMReports' },
                    { Id: 144, Title: 'BFM Reports', url: '/Budget/BFMReports', Route: '/Budget/BFMReports' },
                    { Id: 145, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Budget/BFMReports' },
                    { Id: 146, Title: 'Legislative Budget Handbook', url: 'https://mylrc.sdlegislature.gov/api/Documents/72020.pdf', Route: '/Budget/BFMReports' },

                    { Id: 147, Title: 'Page Program', url: '/Students/Page', Route: '/Students/Page' },
                    { Id: 148, Title: 'General Information', url: 'https://mylrc.sdlegislature.gov/api/Documents/126823.pdf', Route: '/Students/Page' },
                    { Id: 149, Title: 'Frequently Asked Questions', url: 'https://mylrc.sdlegislature.gov/api/Documents/126821.pdf', Route: '/Students/Page' },
                    { Id: 150, Title: 'Application', url: 'https://mylrc.sdlegislature.gov/api/Documents/220727.pdf', Route: '/Students/Page' },
                    { Id: 151, Title: 'Page Term Calendar', url: 'https://mylrc.sdlegislature.gov/api/Documents/126825.pdf', Route: '/Students/Page', },

                    { Id: 152, Title: 'Intern Program', url: '/Students/Intern', Route: '/Students/Intern' },
                    { Id: 153, Title: 'General Information', url: 'https://mylrc.sdlegislature.gov/api/Documents/126820.pdf', Route: '/Students/Intern' },
                    { Id: 154, Title: 'University Coordinators', url: 'https://mylrc.sdlegislature.gov/api/Documents/126819.pdf', Route: '/Students/Intern' },
                    { Id: 155, Title: 'Application', url: 'https://mylrc.sdlegislature.gov/Main#/Students/Intern/Application', Route: '/Students/Intern' },

                    { Id: 156, Title: 'Staff Listing', url: '/Staff/Listing', Route: '/Staff' },
                    { Id: 157, Title: 'Staff Listing By Division', url: '/Staff/Division', Route: '/Staff' },
                    { Id: 158, Title: 'Staff By Subject Area', url: '/Staff/SubjectArea', Route: '/Staff' },
                    { Id: 159, Title: 'Staff By Session Committee', url: '/Staff/Session/Committee', Route: '/Staff' },
                    { Id: 160, Title: 'Staff By Interim Committee', url: '/Staff/Interim/Committee', Route: '/Staff' },
                    { Id: 161, Title: 'Staff By Budget Agency Assignment', url: '/Staff/BudgetAgency', Route: '/Staff' },
                    { Id: 162, Title: 'Staff By Rules Agency Assignment', url: '/Staff/RuleAgency', Route: '/Staff' },
                    { Id: 163, Title: 'Org Chart', url: 'https://mylrc.sdlegislature.gov/api/Documents/72137.pdf', Route: '/Staff' },
                    { Id: 165, Title: 'Internship Program', url: '/Students/Intern', Route: '/Staff' },
                    { Id: 166, Title: 'Careers', url: '/Staff/Careers', Route: '/Staff' },

                    { Id: 167, Title: 'Contact Information', url: '/ContactUs', Route: '/ContactUs' },
                    { Id: 168, Title: 'Email the LRC Staff', url: '/Staff/Listing', Route: '/ContactUs' },
                    { Id: 169, Title: 'Email a Legislator', url: '/Legislators/Contacts/', Route: '/ContactUs', includeSession: true },
                    { Id: 170, Title: 'Contact the Governor', url: 'https://governor.sd.gov/office/contact.aspx', Route: '/ContactUs' },
                    { Id: 171, Title: 'U.S. Congressional Delegation', url: '/ContactUs/Congress', Route: '/ContactUs' },
                    { Id: 172, Title: 'ADA Request', url: '/ContactUs/ADARequest', Route: '/ContactUs' },
                ],
                drawer1: false,
                Session: {},
                loading: false,
            }
        },
        components: {

        },
        methods: {
            formatURL(item)
            {
                if (item.includeSession)
                {
                    if (this.Session)
                    {
                        if (this.SessionId)
                        {
                            if (item.includeFileType)
                            {

                                return item.url + this.SessionId + item.includeFileType;
                            } else
                            {
                                return item.url + this.SessionId;

                            }
                        } else
                        {
                            if (item.includeFileType)
                            {
                                return item.url + this.Session.SessionId + item.includeFileType;
                            } else
                            {
                                return item.url + this.Session.SessionId;
                            }
                        }
                    } else
                    {
                        if (item.includeFileType)
                        {
                            return item.url + item.includeFileType;
                        } else
                        {
                            return item.url;
                        }
                    }
                } else
                {
                    if (this.menuDocuments && this.menuDocuments.TermLimits && item.Title == "Legislator Term Limits") { return this.$MyLRCUrl.value + 'api/Documents/' + this.menuDocuments.TermLimits.DocumentId + '.pdf' }
                    if (this.sideMenuDocuments)
                    {
                        if (item.Title == "Bill Status Report") { return '/Session/BillStatus/' + this.SessionId }
                        if (this.sideMenuDocuments.Register && item.Title == "Current Register") { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.Register.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.RulePromulgation && item.Title == "Administrative Rules Process") { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.RulePromulgation.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.PageGeneral && item.Title == "General Information" & item.Route.includes('Page')) { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.PageGeneral.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.PageFAQ && item.Title == "Frequently Asked Questions" & item.Route.includes('Page')) { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.PageFAQ.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.PageTermCalendar && item.Title == "Page Term Calendar" & item.Route.includes('Page')) { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.PageTermCalendar.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.InternGeneral && item.Title == "General Information" & item.Route.includes('Intern')) { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.InternGeneral.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.InternCoordinator && item.Title == "University Coordinators" & item.Route.includes('Intern')) { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.InternCoordinator.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.OrgChart && item.Title == "Org Chart") { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.OrgChart.DocumentId + '.pdf' }
                        if (this.sideMenuDocuments.BudgetHandbook && item.Title == "Legislative Budget Handbook") { return this.$MyLRCUrl.value + 'api/Documents/' + this.sideMenuDocuments.BudgetHandbook.DocumentId + '.pdf' }

                    }
                    return item.url;

                }
            },
            LoadData: function ()
            {
                this.loading = true;

                if (this.SessionId)
                {
                    fetch('/api/Sessions/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.ok)
                        {
                            return res.json();
                        }
                        throw new Error(res.statusText);
                    }).then(response =>
                    {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });
                }
                else
                {
                    fetch('/api/Sessions/Current', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response =>
                    {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });
                }

                this.allSubMenuItems.filter(x => x.LoadUrl).forEach(x =>
                {
                    fetch(x.LoadUrl + '/' + (this.SessionId || ''), { method: 'GET' })
                        .then(r =>
                        {
                            if (r.ok)
                                return r.json();
                            else
                                return r.text().then(r => Promise.reject(r));
                        })
                        .then(r =>
                        {
                            x.url = r.url;
                            x.Hidden = !x.url;
                        });
                });

            },
        },
        watch: {
            SessionId: {
                handler()
                {
                    this.LoadData();
                },
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            SubMenuItems: function ()
            {
                var Route = this.currentRoute.replace('/' + this.$route.params.SessionId, '');
                var res = this.allSubMenuItems.filter(x => Route.includes(x.Route) && !Route.includes(x.not) && !x.Hidden);
                return res;
            },
            menuDocuments: function ()
            {
                return this.$menuDocuments;
            },
            sideMenuDocuments: function ()
            {
                return (this.$sideMenuDocuments || {}).value;
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
    };
</script>
<style></style>