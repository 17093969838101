var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"md":"2","sm":"12","cols":"12"}},[(_vm.SessionId)?_c('Sidebar',{attrs:{"SessionId":_vm.SessionId}}):_vm._e()],1),(_vm.Keyword)?_c('v-col',{attrs:{"md":"10","sm":"12"}},[(_vm.Session && _vm.Session.YearString && _vm.Keyword && _vm.Keyword.Keyword)?_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.Session.YearString)+" Subject - "+_vm._s(_vm.Keyword.Keyword))]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Keyword.Bills,"search":_vm.search,"item-key":"BillNumber","group-by":"BillType","disable-pagination":"","hide-default-footer":"","multi-sort":"","sort-by":['BillType', 'BillNumber'],"sort-desc":[false, false],"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var group = ref.group;
var groupby = ref.groupby;
return [_c('td',{attrs:{"colspan":"2"}},[_vm._v(" Bill Type: "+_vm._s(_vm.LongBillName(group))+" "),(isOpen)?_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(_vm.minus))]):_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(_vm.plus))])],1)]}},{key:"item.BillURL",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Session/Bill/' + item.BillId}},[_vm._v(_vm._s(item.BillType + ' ' + item.BillNumber))])]}}],null,false,3173025296)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }