<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col md="2" cols="12">
                <RedistrictingMenu></RedistrictingMenu>
            </v-col>
            <v-col md="10" cols="12">
                <v-card flat>
                    <v-card-title>
                        <h3>
                            Resources and Links
                        </h3>
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            Resources
                        </h3>
                        <ul>
                            <li><a href="https://mylrc.sdlegislature.gov/api/Documents/IssueMemo/207379.pdf">Redistricting 2020, A Primer - LRC Issue Memo</a></li>
                            <li><a href="https://mylrc.sdlegislature.gov/api/Documents/221276.pdf">Brainstorming Maps</a> - LRC Presentation on September 9, 2021</li>
                            <li><a href="https://mylrc.sdlegislature.gov/api/Documents/221178.pdf">Redistricting Data</a> - LRC Presentation on August 30, 2021</li>
                            <li><a href="https://mylrc.sdlegislature.gov/api/Documents/220640.pdf">Redistricting Legal Review in South Dakota</a> - LRC Presentation on June 1, 2021</li>
                            <li><a href="https://www.ncsl.org/research/redistricting/redistrictinglaw2020.aspx">NCSL's Redistricting Law 2020 Book</a> (available at a cost)</li>
                        </ul>
                        <h3 class="mt-3">
                            Links
                        </h3>
                        <ul>
                            <li><a href="https://sdlegislature.gov/Interim/Committee/434/Detail">Senate Committee on Legislative Redistricting</a></li>
                            <li><a href="https://sdlegislature.gov/Interim/Committee/433/Detail">House Committee on Legislative Redistricting</a></li>
                            <li><a href="https://sdstate.maps.arcgis.com/apps/MapSeries/index.html?appid=45180bb1f6e14bcd84af59108fe73991">GIS Map of Counties (Courtesy of SDSU)</a></li>
                            <li><a href="https://www.census.gov/">U.S. Census Bureau</a></li>
                            <li><a href="https://sdlegislature.gov/Statutes/Constitution">NCSL Redistricting</a> (National Conference of State Legislatures)</li>
                            <li><a href="https://constitution.congress.gov/">U.S Constitution</a></li>
                            <li><a href="https://sdlegislature.gov/Statutes/Constitution">South Dakota Constitution</a></li>
                            <li><a href="https://sdlegislature.gov/Interim/Committee/92/Detail">2011 Redistricting Committee</a></li>
                            <li><a href="https://sdlegislature.gov/Interim/Committee/349/Detail">2001 Redistricting Committee</a></li>
                        </ul>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    const RedistrictingMenu = () => import("@/Redistricting/RedistrictingMenu.vue");

    export default {
        name: 'Resources',

        components: {
            RedistrictingMenu
        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {

        },
        watch: {

        },
        computed: {
            Title: function () {
                let title = 'Redistricting Resources';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>