<template>
<v-container>
    <div class="headline">
        Resources
    </div>
    <v-container>
        <v-layout row wrap>
            <v-flex md6 xs12>
                <div>Reference Series</div>
                <v-list>
                    <v-list-item v-for="i in References">
                        <v-list-item-content>
                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Student Reference Series/' +i.DocumentId + '.pdf'">
                                {{i.Title}}<sup>(PDF)</sup>
                            </a>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-flex>
            <v-flex md6 xs12>
                <div>Other Materials</div>
                <v-list>
                    <v-list-item v-for="i in Other">
                        <v-list-item-content>
                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Student Other Materials/' +i.DocumentId + '.pdf'">
                                {{i.Title}}<sup>(PDF)</sup>
                            </a>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-flex>
        </v-layout>
    </v-container>
</v-container>
</template>
<script>
    export default {
        name: 'PublicResources',
        components: {

        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=81&Type=82', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.References = this.items.filter(x => x.DocumentTypeId == 81).sort((a, b) => (a.Title < b.Title ? -1 : 1));
                    this.Other = this.items.filter(x => x.DocumentTypeId == 82).sort((a, b) => (a.Title < b.Title ? -1 : 1));
                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                References: [

                ],
                Other: [

                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Public Resources';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>