<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Staff By Rules Agency Assignment
            </div>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 item-key="AgencyDivisionId"
                                 :items-per-page="999"
                                 :sort-by="['AgencyDivision']"
                                 :loading="loading"
                                 disable-pagination
                                 hide-default-footer
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.Title="{ item }">
                    <span v-if="item.Title != null">{{item.Title}}</span>
                    <span v-else>{{item.AgencyDivision}}</span>
                </template>
                <template v-slot:item.ResearchStaff="{ item }">
                    <template v-for="(u,index) in Users(item, 'ResearchUser')">
                        <span v-if="index > 0">, </span>
                        <router-link :to="'/Staff/Profile/' + u.ResearchUser.UserId" :key="item.AgencyDivisionId + 'Research' + u.ResearchUser.UserId">{{u.ResearchUser.FirstName + '&nbsp;' + u.ResearchUser.LastName}}</router-link>
                    </template>
                </template>
                <template v-slot:item.FiscalStaff="{ item }">
                    <template v-for="(u,index) in Users(item, 'FiscalUser')">
                        <span v-if="index > 0">, </span>
                        <router-link :to="'/Staff/Profile/' + u.FiscalUser.UserId" :key="item.AgencyDivisionId + 'Fiscal' + u.FiscalUser.UserId">{{u.FiscalUser.FirstName + '&nbsp;' + u.FiscalUser.LastName}}</router-link>
                    </template>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'RuleAgency',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;

                fetch('/api/Users/RuleAgencies',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        let r = EncodeDatetimes(response);

                        r.forEach(x =>
                        {
                            x.ResearchStaff = this.Users(x, 'ResearchUser').map(y => y.ResearchUser.FirstName + ' ' + y.ResearchUser.LastName).toString();
                            x.FiscalStaff = this.Users(x, 'FiscalUser').map(y => y.FiscalUser.FirstName + ' ' + y.FiscalUser.LastName).toString();
                        });

                        this.items = r;
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
            Users(item, prop)
            {
                return item.Users.filter(x => x[prop]);
            },
        },
        data: function ()
        {
            return {
                loading: true,
                headers: [
                    { text: 'Agency', value: 'AgencyDivision', width: '25%' },
                    { text: 'Title', value: 'Title', width: '25%' },
                    { text: 'Research Staff', value: 'ResearchStaff', width: '25%' },
                    { text: 'Fiscal Staff', value: 'FiscalStaff', width: '25%' },
                ],
                items: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Staff by Rule Agency';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>