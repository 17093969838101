<template>
    <v-row class="ma-0 pa-0 billStatus">
        <v-col>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <div class="d-print-none">
                <v-text-field v-model="filterTitle" label="Filter by Title" outlined></v-text-field>
            </div>
            <v-row v-for="Bill in BillsFiltered" :key="Bill.BillId">
                <v-col>
                    <BillStat :Bill="Bill" :ActionLogs="Bill.ActionLogs" :session-id="Bill.SessionId"></BillStat>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    const BillStat = () => import("@/Session/BillReports/BillStat.vue");
    export default {
        name: 'BillStatus',
        props: {
            SessionId: { type: String | Number, default: 44 },
        },
        components: {
            BillStat
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });

                fetch('/api/Bills/BillStatus/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Bills = EncodeDate(response);
                }).catch(error => {
                    throw new Error(error);
                });
            }
        },
        data: function () {
            return {
                loading: false,
                Session: {},
                Bills: [],
                filterTitle: null,
                filterTitleDebouce: null,
            }
        },
        watch: {
            SessionId: function () {
                this.LoadData();
            },
            filterTitle: debounce(function () {
                this.filterTitleDebouce = this.filterTitle;
            }, 500)
        },
        mounted() {
            this.LoadData();
        },
        computed: {
            BillsFiltered: function () {
                if (this.filterTitleDebouce && this.Bills && this.Bills.length > 0)
                    return this.Bills.filter(x => (x.Title || '').toLowerCase().indexOf(this.filterTitleDebouce) > -1);
                else
                    return this.Bills;
            },
            Title: function () {
                let title = 'Loading Bill...';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + " Bill Status Report";
                }
                else {
                    title = "Bill Status Report";
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>