
<template>
    <FilterableDataTable :headers="headers"
                         hide-default-header
                         :loading="loading"
                         hide-default-footer
                         :single-expand="false"
                         disable-pagination
                         :items="Minutes"
                         item-key="DocumentId"
                         show-expand
                         :dense="false"
                         @item-expanded="expandItem"
                         @click:row="rowClick"
                         class="elevation-1">
        <template v-slot:item.DocumentDate="{ item }">
            {{DatePretty(item.DocumentDate)}}
            <span v-if="item.DocumentId == 237274">&nbsp;(Transcript Attached)</span>
        </template>
        <template v-slot:item.DocumentId="{ item }">
            <div class="text-end">
                <v-btn v-if="item.eMinutes" @click.stop="" outlined tile :to="'/Session/Committee/' + CommitteeId + '/eMinutes/' + item.eMinutes.Avid">E-Minutes</v-btn>
                <v-btn @click.stop="" v-if="item.DocumentId > 0" tile outlined rel="noopener" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.pdf'">View PDF</v-btn>
                <v-btn @click.stop="" v-if="item.AudioLink" class="ma-2" tile outlined rel="noopener" :href="item.AudioLink.Url" aria-label="SDPB Audio">
                    <v-icon color="primary">{{mdiVolumeHigh}}</v-icon>
                    Audio
                </v-btn>
                <v-btn v-else @click.stop="" disabled class="ma-2">No Audio</v-btn>
            </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <v-progress-linear indeterminate v-if="!item.HTML"></v-progress-linear>
                <div v-if="item.HTML" v-html="item.HTML"></div>
            </td>
        </template>
        <template v-slot:footer="{}">
            <v-btn v-if="Minutes && Minutes.length > 1 && combindURLHTML" tile outlined block rel="noopener" :href="combindURLHTML">View Combined HTML</v-btn>
            <v-btn v-if="Minutes && Minutes.length > 1 && combindURL" tile outlined block rel="noopener" :href="combindURL">View Combined PDF</v-btn>
        </template>
    </FilterableDataTable>
</template>
<script>
    import moment from 'moment'
    import { mdiVolumeHigh } from '@mdi/js';

    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Minutes',
        props: ['CommitteeId'],
        components: {
            FilterableDataTable
        },
        methods: {
            switchTab(item)
            {
                this.filterValue = item.value;
            },
            LoadData()
            {
                this.loading = true;
                let f;

                if (this.currentRoute.toLowerCase().includes('/interim'))
                {
                    f = fetch('/api/InterimCommittees/Documents/' + this.CommitteeId + "?Type=2&Type=4&Type=3&Type=5",
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        });
                }
                else
                {
                    f = fetch('/api/SessionCommittees/Documents/' + this.CommitteeId + "?Type=2&Type=4&Type=3&Type=5",
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                }

                f.then(
                    res =>
                    {
                        this.loading = false;

                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        let data = EncodeDatetimes(response);
                        let tempMinutes = data.filter(x => x.DocumentTypeId == 2 || x.DocumentTypeId == 4);
                        let documentId = -1;

                        let agendas = data.filter(x => x.DocumentTypeId == 3 || x.DocumentTypeId == 5);

                        agendas.forEach(x =>
                        {
                            if (x.NoMeeting == true)
                            {
                                tempMinutes = tempMinutes.filter(y => y.DocumentDate.getTime() !== x.DocumentDate.getTime());
                            }
                            else if (!tempMinutes.some(y => y.DocumentDate.getTime() == x.DocumentDate.getTime()))
                            {
                                var nObj = {
                                    DocumentDate: x.DocumentDate,
                                    InterimYearCommitteeId: x.InterimYearCommitteeId,
                                    AudioLink: x.AudioLink,
                                    DocumentId: documentId,
                                    HTML: null,
                                    DocumentTypeId: 2,
                                };

                                documentId -= 1;

                                tempMinutes.push(nObj);
                            }
                        });

                        tempMinutes.sort((x, y) =>
                        {
                            if (x.DocumentDate && y.DocumentDate)
                                return y.DocumentDate - x.DocumentDate;
                            else if (x.DocumentDate)
                                return -1;
                            else
                                return 1;
                        });

                        this.Minutes = tempMinutes;

                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });
            },

            MeetingTimePretty(Time)
            {
                if (Time != undefined)
                {
                    return moment(Time).format('h:mm A');
                } else
                {
                    return null;
                }
            },
            DatePretty(Date)
            {
                if (Date != undefined)
                {
                    return moment(Date).format('M/DD h:mm A');
                } else
                {
                    return null;
                }
            },
            MinDatePretty(Date)
            {
                if (Date != undefined)
                {
                    return moment(Date).format('M/DD');
                } else
                {
                    return null;
                }
            },
            openDocumentHTML(DocumentId)
            {
                return fetch('/api/Documents/' + DocumentId + '.html',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        this.loading = false;

                        if (res.ok)
                            return res.text();
                        else
                            return "<p>No HTML Available<p>";
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });
            },
            async expandItem(item)
            {
                if (item.item.HTML == null)
                {
                    if (item.item.DocumentId > 0)
                    {
                        item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                    } else
                    {
                        item.item.HTML = "No HTML Available";
                    }
                }
            },
            rowClick(any, item)
            {
                if (item.item.DocumentId < 0)
                    return;

                //Load Html
                if (!item.isExpanded)
                    this.expandItem(item);

                item.expand(!item.isExpanded);
            },

        },
        data: function ()
        {
            return {
                loading: true,
                loadingHTML: true,
                mdiVolumeHigh,
                headers: [
                    { text: 'Date', value: 'DocumentDate' },
                    { text: '', value: 'DocumentId', filterable: false },
                    { text: '', value: 'data-table-expand', filterable: false, width: '5ch' }],
                CommitteeMemberTypes: [],
                YearCommittee: {
                    Session: {
                        Year: '',
                        SessionId: null,
                    },
                    Committee: {
                        MeetingTime: '',
                        Room: {
                            Room: '',
                        },
                        Days: '',
                    },
                },
                Minutes: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            CommitteeId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            CommitteeType: function ()
            {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim'))
                {
                    return "Interim";
                } else
                {
                    return "Session";
                }
            },
            currentRoute: function ()
            {
                return this.$route.path;
            },
            combindURL()
            {
                if (this.Minutes)
                {
                    let PublicMinDocs = this.Minutes.filter(x => x.DocumentId && x.DocumentId > 0).sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                    if (PublicMinDocs.length < 2)
                    {
                        return null;
                    }
                    var str = "";
                    PublicMinDocs.forEach(i =>
                    {
                        if (i.DocumentId && Number.isInteger(i.DocumentId))
                        {
                            str += "&Ids=" + i.DocumentId;
                        }
                    });
                    str = str.substring(1, str.length);
                    return this.$MyLRCUrl.value + 'api/Documents/Combined.pdf?' + str;
                }
                return null;
            },
            combindURLHTML()
            {
                if (this.Minutes)
                {
                    let PublicMinDocs = this.Minutes.filter(x => x.DocumentId && x.DocumentId > 0).sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                    if (PublicMinDocs.length < 2)
                    {
                        return null;
                    }
                    var str = "";
                    PublicMinDocs.forEach(i =>
                    {
                        if (i.DocumentId && Number.isInteger(i.DocumentId))
                        {
                            str += "&Ids=" + i.DocumentId;
                        }
                    });
                    str = str.substring(1, str.length);
                    return this.$MyLRCUrl.value + 'api/Documents/Combined.html?' + str;
                }
                return null;
            },
        }
    };</script>
<style></style>