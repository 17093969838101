<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat v-if="ServiceMember">
                <v-card-title v-if="ServiceMember.LastName">
                    <span v-if="ServiceMember.FirstName">{{ServiceMember.FirstName}}&nbsp;</span><span v-if="ServiceMember.MiddleName">{{ServiceMember.MiddleName}}&nbsp;</span><span v-if="ServiceMember.LastName">{{ServiceMember.LastName}}</span>
                </v-card-title>
                <v-card-text>
                    <v-flex md3 v-if="ServiceMember.Sex">
                        <b>Gender: </b>{{ServiceMember.Sex}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Birthdate">
                        <b>Birthday: </b>{{ServiceMember.Birthdate}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Deathdate">
                        <b>Date of Death: </b>{{ServiceMember.Deathdate}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Remarks">
                        <b>Remarks: </b>{{ServiceMember.Remarks}}
                    </v-flex>
                    <br />
                    <div class="headline">Years of Service</div>
                    <div v-if="ServiceMember.TotalYears">Total Years of Service: <v-chip>{{ServiceMember.TotalYears}}</v-chip></div>
                    <FilterableDataTable v-if="ServiceMembers" :headers="YearsHeaders"
                                         :items="ServiceMembers"
                                         :search="search"
                                         :loading="loading"
                                         hide-default-footer
                                         disable-pagination
                                         componentName="ServiceMembersDatagrid"
                                         :editHeaders="false"
                                         item-key="MemberServiceID"
                                         class="elevation-1">

                        <v-alert slot="no-results" :value="true" type="info">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </FilterableDataTable>
                    <br />
                    <div class="headline">Offices</div>
                    <FilterableDataTable v-if="MemberOffices" :headers="OfficeHeaders"
                                         :items="MemberOffices"
                                         :search="search"
                                         :loading="loading"
                                         hide-default-footer
                                         disable-pagination
                                         componentName="MemberOffices"
                                         :editHeaders="false"
                                         item-key="MemberOfficeID"
                                         class="elevation-1">
                        <v-alert slot="no-results" :value="true" type="info">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </FilterableDataTable>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'HistoricalProfile',
        props: ['MemberId'],
        components: {
            FilterableDataTable,Sidebar
        },
        methods: {
            switchTab(item) {
                this.filterValue = item;
            },
            LoadData() {
                fetch('/api/Historical/Member/' + this.MemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else if (res.status === 404) {
                        this.$router.push('/Legislators/Historical');///Legislators/Profile/1695/Detail
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.loading = false;
                    if (this.ServiceMembers === undefined) {
                        this.ServiceMembers = [];
                    }
                    if (response) {
                        let Encoded = EncodeDatetimes(response);
                        if (Encoded) {
                            this.ServiceMember = Encoded;
                            if (this.ServiceMember && this.ServiceMember.MemberServices) {
                                this.ServiceMembers = this.ServiceMember.MemberServices;
                            }
                            if (this.ServiceMember && this.ServiceMember.MemberOffices) {
                                this.MemberOffices = this.ServiceMember.MemberOffices;
                            }
                        }
                    }
                    if (this.ServiceMember === undefined) {
                        this.ServiceMember = {};
                    }
                }).catch(error => {
                    this.loading = false;
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                search: null,
                loading: true,
                filterValue: 'Detail',
                tabs: ['Detail', 'Committees', 'Bills', 'Hearings'],
                ServiceMember: {},
                ServiceMembers: [],
                MemberOffices: [],
                YearsHeaders: [
                    {
                        text: 'Party',
                        value: 'Party',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Address',
                        value: 'Address',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'County',
                        value: 'County',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Body',
                        value: 'BodyLong',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Start Year',
                        value: 'StartYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'End Year',
                        value: 'EndYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                ],
                OfficeHeaders: [
                    {
                        text: 'Party',
                        value: 'Party',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Position',
                        value: 'Position',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Address',
                        value: 'Address',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Year',
                        value: 'Year',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Comment',
                        value: 'Comment',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            MemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            Session: function () {
                return this.$Session;
            },
        }
    };
</script>
<style></style>