<template>
    <v-container>
        <v-card flat xs12>
            <v-card-title>
                <h2>
                    Committee Live
                    <span v-if="doc && doc.RoomAudio">
                        <v-btn icon color="primary" rel="noopener" :href="doc.RoomAudio" aria-label="SDPB Audio">
                            <v-icon>{{mdiVolumeHigh}}</v-icon>
                        </v-btn>
                    </span>
                </h2>
            </v-card-title>
            <v-card-text>
                <v-layout row wrap>
                    <v-card flat>
                        <span v-if="doc && doc.FullName">
                            <v-card-title style="text-align:center;display:inherit;" class="text-center">{{doc.FullName}}</v-card-title>
                        </span>
                        <v-card-text v-if="items">
                            <v-progress-linear indeterminate v-if="Loading"></v-progress-linear>
                            <v-list>
                                <template v-for="i in items">
                                    <v-divider class="ma-0"></v-divider>
                                    <v-list-item rel="noopener" :href="i.url">
                                        <v-list-item-content>
                                            <v-list-item-title v-if="i.Status" class="text-wrap">
                                                <b>Status:</b> {{i.Status}}
                                            </v-list-item-title>
                                            <div v-if="i.BillNumber && i.Bill">
                                                <br />
                                                <router-link :to="'/Session/Bill/' + i.Bill.BillId">{{i.Bill.BillType}} {{i.Bill.BillNumberOnly}}</router-link> <b>{{i.Bill.Title}}</b>
                                            </div>
                                            <v-list-item-subtitle>
                                                {{i.LastUpdated | FormatDateTimes}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                            <v-alert v-if="!Loading && items && items.length == 0 && doc && (new Date(doc.DocumentDate) > new Date())" dense
                                     text
                                     type="info">Meeting has not started</v-alert>
                            <v-alert v-else-if="!Loading && items && items.length == 0" dense
                                     text
                                     type="info">No Live Events</v-alert>
                        </v-card-text>
                    </v-card>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                Disclaimer: The South Dakota Legislative Research Council takes reasonable precautions to assure the accuracy and reliability of the data contained on this web site. No warranty, guarantee, or promise, express or implied, is made.
                The live events of a committee is made available as a public service. Online resources may be vulnerable to unauthorized changes or redirection. Any person who relies on any information obtained from this web site does so at that person’s own risk. In the case of inconsistencies resulting from omissions or other errors, the current printed version of the Minutes/Journal will prevail.
                For matters affecting legal or other rights, or to confirm content, please refer to the Minutes/Journal or consult an attorney. The staff of the Legislative Research Council may not provide legal advice.
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
    import { mdiVolumeHigh } from '@mdi/js';

    export default {
        name: 'LiveBoard',
        props: ['DocumentId'],
        components: {

        },
        methods: {
            LoadData() {
                this.Loading = true;
                fetch('/api/Documents/LiveMeeting/' + this.DocumentId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    var res = EncodeDatetimes(response);
                    this.items = res.liveMeeting;
                    this.doc = res.document;
                    this.Loading = false;
                }).catch(error => {
                    this.Loading = false;
                    throw new Error(error);
                });

                setTimeout(() => {
                    this.LoadData();
                }, 30000);
            },
        },
        data: function () {
            return {
                mdiVolumeHigh,
                items: [],
                doc: {},
                Loading: true,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            DocumentId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            Title: function () {
                let title = 'Live';
                if (this.doc && this.doc.FullName) {
                    title = 'Live ' + this.doc.FullName;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };</script>
<style></style>