<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">General Reports</div>
            <v-list>
                <v-list-item v-for="i in items" :key="i.name">
                    <v-btn :to="i.url">{{i.name}}</v-btn>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'GeneralReports',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,
                items: [
                    { name: 'Political Control', url: '/Historical/Reports/Political', },
                    { name: 'Statehood Legislators', url: '/Historical/Reports/Statehood', },
                    { name: 'Territorial Legislators', url: '/Historical/Reports/Territorial', }
                ],

            }
        },
        mounted: function () {

        },
        computed: {

        }
    };
</script>
<style></style>