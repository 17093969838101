<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                Press Releases
                <v-btn depressed x-small color="orange" style="vertical-align:top;" rel="noopener" :href="'/api/Documents/DocumentTypesRSS?Type=48'">
                    <span class="white--text">RSS</span><v-icon color="white" right>{{rss}}</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div>
                    The Legislative Research Council will periodically update this page with the ten latest press releases available.
                </div>
                <v-divider></v-divider>
                <FilterableDataTable :headers="headers"
                              :items="items"
                              item-key="Name"
                              sort-by="Name"
                              :sort-desc="[false]"
                              :loading="loadingLatest"
                              loading-text="Loading... Please wait"
                              hide-default-footer
                              class="elevation-1">
                    <template v-slot:item.Description="{ item }">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/PressRelease/' + item.DocumentId + '.pdf'" v-if="item.Title">{{item.Title}}</a>
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/PressRelease/' + item.DocumentId + '.pdf'" v-else-if="item.Description">{{item.Description}}</a>
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/PressRelease/' + item.DocumentId + '.pdf'" v-else>{{item.Filename}}</a>
                    </template>
                    <template v-slot:item.DocumentDate="{ item }">
                        {{formatDate(item.DocumentDate)}}
                    </template>
                    <template v-slot:no-data>
                        No recent news.
                    </template>
                </FilterableDataTable>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import { mdiRss } from '@mdi/js';
    import moment from 'moment'
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'PressRelease',
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loadingLatest = true;

                fetch('/api/Documents/DocumentType?Type=48&Top=10&Past=true', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    if (res.ok)
                        return res.json();
                    else
                        return Promise.reject(res.status);
                }).then(response =>
                {
                    var res = EncodeDatetimes(response);

                    this.items = res;

                }).catch(error =>
                {
                    throw new Error(error);
                }).finally(_ =>
                {
                    this.loadingLatest = false;
                });
            },
            formatDate(Date) {
                return moment(Date).format('M/DD/YYYY');
            },
        },
        data: function () {
            return {
                rss: mdiRss,
                headers: [
                    { text: 'Press Release', value: 'Description' },
                    { text: 'Date Posted', value: 'DocumentDate' },
                ],
                loadingLatest: true,
                items: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Press Releases';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>