<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12" v-if="SessionId">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12" cols="12">
            <v-row>
                <v-col>
                    <h3 v-if="Session != null">{{Session.YearString}} Legislators</h3>
                    <v-progress-linear indeterminate
                                       v-if="loading"></v-progress-linear>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-0 ma-0">
                    <v-card class="pa-0 ma-0">
                        <v-card-title>
                            House Members
                        </v-card-title>
                        <v-card-text class="pa-xs-0 ma-xs-0">
                            <v-list>
                                <template v-for="m in HouseMembers">
                                    <v-divider class="ma-0"></v-divider>
                                    <v-list-item class="pa-0 ma-0">
                                        <v-list-item-content>
                                            <MemberCard :expand="false" :Member="m"></MemberCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col class="pa-0 ma-0">
                    <v-card>
                        <v-card-title>
                            Senate Members
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="m in SenateMembers">
                                    <v-divider class="ma-0"></v-divider>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <MemberCard :expand="false" :Member="m"></MemberCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row><small><b><i>*The Legislator did not fulfill their entire term.</i></b></small></v-row>
        </v-col>
    </v-row>
</template>

<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const MemberCard = () => import("@/Components/MemberCard.vue");
    export default {
        name: 'Contacts',
        props: ['SessionId'],
        components: {
            MemberCard, Sidebar
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/SessionMembers/Session/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.loading = false;
                    this.Members = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
                this.loading = true;
                if (this.SessionId) {
                    fetch('/api/Sessions/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
        },
        data: function () {
            return {
                Members: [],
                Session: {},
                loading: true,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            SenateMembers: function () {
                if (this.Members && this.Members.length > 0) {
                    //Dont remember if its MemberType or chamber
                    return this.Members.filter(x => x.MemberType == 'S');
                }
                else {
                    return [];
                }
            },
            HouseMembers: function () {
                //Dont remember if its MemberType or chamber
                if (this.Members && this.Members.length > 0) {
                    return this.Members.filter(x => x.MemberType == 'H');
                }
                else {
                    return [];
                }
            },
            Title: function () {
                let title = 'Legislators';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + ' ' + title;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>