<template>
    <v-card flat>
        <div class="headline">
            {{filteredReportTypes.name}}
        </div>
        <v-card-text>
            <v-layout row wrap>
                <v-flex md2 xs12 v-for="y in Years" style="display:inline">
                    <div>Fiscal Year {{y}}</div>
                    <v-list>
                        <v-list-item v-for="d in Documents(y)">
                            <v-list-item-content>
                                <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + d.DocumentType + '/' + d.DocumentId + '.pdf'">{{Month(d.DocumentDate)}}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
    import moment from 'moment'

    export default {
        name: 'MonthlyReportCard',
        components: {

        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=32&Type=35&Type=31&Type=30&Type=42', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            Documents(year) {
                return this.items.filter(x => x.FiscalYear == year && x.DocumentTypeId == this.filteredReportTypes.DocTypeId);
            },
            Month(date) {
                return moment(date, "MM/DD/YYYY").format("MMMM");
            },
        },
        data: function () {
            return {
                ReportTypes: [
                    { name: 'General Fund Receipts', route: '/Budget/GeneralFundReceipts', DocTypeId: 32 },
                    { name: 'Expenditures & Encumbrances', route: '/Budget/Expenditures', DocTypeId: 35 },
                    { name: 'Medical Services', route: '/Budget/MedicalServices', DocTypeId: 31 },
                    { name: 'Trust Funds', route: '/Budget/TrustFunds', DocTypeId: 30 },
                    { name: 'Prison Populations', route: '/Budget/PrisonPopulations', DocTypeId: 42 }
                ],
                items: [],
                loading: true,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            filteredReportTypes: function () {
                return this.ReportTypes.find(x => x.route == this.currentRoute);
            },
            Years: function () {
                if (this.items != undefined) {
                    var docDates = this.items.filter(x => x.DocumentTypeId == this.filteredReportTypes.DocTypeId).map(a => a.FiscalYear);
                    if (docDates.length > 0) {
                        var unique = [...new Set(docDates)].sort().reverse();
                        //this.Year = unique[0];
                        return unique;
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            },
            Title: function () {
                let title = 'Monthly Report';
                if(this.filteredReportTypes){
                    title = this.filteredReportTypes.name;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>