<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="SessionId" :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline" v-if="Session && Session.Year">{{Session.YearString}} Bills Signed By The Governor</div>
            <v-container class="width:80%;">
                <v-layout row wrap style="text-align:center">
                    <v-row no-gutters>
                        <v-col>
                            <v-card class="py-2"
                                    outlined
                                    tile
                                    xs12>
                                <div style="text-align:center">
                                    <v-btn @click="filter('All')" text>All</v-btn>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
                <v-layout row wrap style="text-align:center">
                    <v-row no-gutters>
                        <v-col>
                            <v-card class="pa-2"
                                    outlined
                                    v-for="i in HouseBillTypes" :key="i.BillType"
                                    tile
                                    xs12
                                    @click="filter(i.BillType)">
                                <v-flex>
                                    <v-btn @click="filter(i.BillType)" text>{{i.LongBillType}}</v-btn>
                                </v-flex>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="pa-2"
                                    outlined
                                    v-for="i in SenateBillTypes" :key="i.BillType"
                                    tile
                                    xs12
                                    @click="filter(i.BillType)">
                                <v-flex>
                                    <v-btn @click="filter(i.BillType)" text>{{i.LongBillType}}</v-btn>
                                </v-flex>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>

            <FilterableDataTable :headers="headers"
                                 :items="filterBills"
                                 :search="search"
                                 item-key="BillNumber"
                                 multi-sort
                                 disable-pagination
                                 hide-default-footer
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.BillNumber="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumber}}</router-link>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'SignedByGovernor',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/Sessions/' + this.SessionId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.Session = EncodeDatetimes(response);
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });

                fetch('/api/BillLogs/SignedByGovernor/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.loading = false;
                        var temp = EncodeDatetimes(response);

                        this.items = temp.sort(
                            function (a, b)
                            {
                                if (a.BillType === b.BillType)
                                {
                                    return a.BillNumber - b.BillNumber;
                                }
                                return a.BillType > b.BillType ? 1 : -1;
                            });

                        this.filterBills = this.items;
                        var uniqueBillTypes = [...new Set(temp.map(item => item.BillType))];
                        this.SenateBillTypes = this.SenateBillTypes.filter(x => uniqueBillTypes.includes(x.BillType) == true);
                        this.HouseBillTypes = this.HouseBillTypes.filter(x => uniqueBillTypes.includes(x.BillType) == true);
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });
                fetch('/api/BillNumbers/BillTypes', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.BillTypes = EncodeDatetimes(response);
                    this.SenateBillTypes = this.BillTypes.filter(x => x.BillType.includes('S') == true);
                    this.HouseBillTypes = this.BillTypes.filter(x => x.BillType.includes('H') == true);
                }).catch(error =>
                {
                    throw new Error(error);
                });
            },
            filter(billType)
            {

                if (billType == 'All')
                {
                    this.filterBills = this.items;
                } else
                {
                    this.filterBills = this.items.filter(x => x.BillType == billType);
                }
            },
        },
        data: function ()
        {
            return {
                search: '',
                loading: false,
                Session: {},
                headers: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillNumber',
                        width: '15ch'
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        width: '*'
                    },
                ],
                filterBills: [],
                items: [],
                BillTypes: [],
                SenateBillTypes: [],
                HouseBillTypes: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Loading Bills Signed By the Governor...';
                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + " Bills Signed By the Governor";
                }
                else
                {
                    title = 'Bills Signed By the Governor';
                }
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>