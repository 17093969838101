<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                <span v-if="currentRoute.includes('/Staff/Session/Committee')">Staff By Session Committee</span>
                <span v-else>Staff By Interim Committee</span>
            </div>
            <FilterableDataTable :headers="headers"
                                 :items="filteredItems"
                                 :item-key="key"
                                 :items-per-page="999"
                                 :loading="loading"
                                 disable-pagination
                                 hide-default-footer
                                 loading-text="Loading... Please wait"
                                 class="elevation-1"
                                 :key="key">
                <template v-slot:item.Name="{ item }">
                    <span v-if="currentRoute.includes('/Staff/Session/Committee')">
                        <router-link :to="'/Session/Committee/' + item.SessionCommitteeId">{{item.Committee.FullName }}</router-link>
                    </span>
                    <span v-else>
                        <router-link :to="'/Interim/Committee/' + item.InterimYearCommitteeId">{{item.Committee.Name}}</router-link>
                    </span>
                </template>
                <template v-slot:item.Staff="{ item }">
                    <span v-for="(i,il) in item.Users">
                        <router-link :to="'/Staff/Profile/' + i.UserId">{{i.Name}}<span v-if="il< item.Users.length-1">, </span></router-link>
                    </span>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Committee',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/UserCommittees',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        let r = EncodeDatetimes(response);

                        r.SessionCommittees.forEach(x =>
                        {
                            x.Name = x.Committee.FullName;
                            x.Staff = x.Users.map(y => y.Name).toString();
                        });

                        r.InterimCommittees.forEach(x =>
                        {
                            x.Name = x.Committee.Name;
                            x.Staff = x.Users.map(y => y.Name).toString();
                        });

                        this.items = r;
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
        },
        data: function ()
        {
            return {
                headers: [
                    { text: 'Committee', value: 'Name', width: '70ch' },
                    { text: 'Staff', value: 'Staff', width: '*' },
                ],
                items: [],
                loading: true,
                key: 'SessionCommitteeId',
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            currentRoute: function ()
            {
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            filteredItems: function ()
            {
                if (this.currentRoute.includes('/Staff/Session/Committee'))
                {
                    this.key = 'SessionCommitteeId';
                    return this.items.SessionCommittees;
                } else
                {
                    this.key = 'InterimYearCommitteeId';
                    return this.items.InterimCommittees;
                }
            },
            Title: function ()
            {
                let title = 'Staff by Committees';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>

