<template>
    <div>
        <v-progress-linear indeterminate
                           v-if="loading"></v-progress-linear>
        <div class="headline" v-if="filteredSessionCommittees.length >0">Session Committees</div>
        <FilterableDataTable :headers="SessionCommitteeHeaders"
                      :items="filteredSessionCommittees"
                      item-key="Name"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['SessionCommittees.Name']"
                      :sort-desc="[false]"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      class="elevation-1"
                      v-if="filteredSessionCommittees.length >0">
            <template v-slot:item.SessionCommittees.Name="{ item }">
                <router-link :to="'/Session/Committee/' + item.SessionCommitteeId">
                    <span v-if="item.SessionCommittees.Body == 'H'">House</span>
                    <span v-if="item.SessionCommittees.Body == 'S'">Senate</span>
                    <span v-if="item.SessionCommittees.Body == 'J'">Joint</span>
                    {{item.SessionCommittees.Name }}
                </router-link>
            </template>
            <template v-slot:item.SessionCommittees.MeetingTime="{ item }">
                {{MeetingTimePretty(item.SessionCommittees.MeetingTime)}}
            </template>
        </FilterableDataTable>
        <div class="headline" v-if="filteredConferenceCommittees.length >0">Conference Committees</div>
        <FilterableDataTable :headers="ConferenceCommitteeHeaders"
                      :items="filteredConferenceCommittees"
                      item-key="Name"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['ConferenceCommittee.Name']"
                      :sort-desc="[false]"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      class="elevation-1"
                      v-if="filteredConferenceCommittees.length >0">
            <template v-slot:item.ConferenceCommittee.Name="{ item }">
                {{item.ConferenceCommittee.Name }}
            </template>

        </FilterableDataTable>
        <div class="headline" v-if="filteredInterimCommittees.length >0">Interim Committees</div>
        <FilterableDataTable :headers="InterimCommitteeHeaders"
                      :items="filteredInterimCommittees"
                      item-key="Name"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['InterimYearCommittee.Name']"
                      :sort-desc="[false]"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                      class="elevation-1"
                      v-if="filteredInterimCommittees.length >0">
            <template v-slot:item.InterimYearCommittee.Name="{ item }">
                <router-link :to="'/Interim/Committee/' + item.InterimYearCommitteeId">{{item.InterimYearCommittee.Name }}</router-link>
            </template>
        </FilterableDataTable>
    </div>
</template>
<script>
    import moment from 'moment'
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Committees',
        props: ['SessionMemberId'],
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/SessionMembers/Committees/' + this.SessionMemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.loading = false;
                    this.Member = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DateTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('M/DD/YYYY h:mm A');
                } else {
                    return null;
                }
            }
        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',
                Member: {
                    Session: {
                        Year: null
                    },
                },
                headers: [
                    {
                        text: 'Name',
                        value: 'SessionCommittees.Name',
                        type: 'Session',
                    },
                    {
                        text: 'Name',
                        value: 'InterimYearCommittee.Name',
                        type: 'Interim',
                    },
                    {
                        text: 'Name',
                        value: 'ConferenceCommittee.Name',
                        type: 'Conference',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'SessionCommittees.Description',
                        type: 'Session',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'InterimYearCommittee.Description',
                        type: 'Interim',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'ConferenceCommittee.Description',
                        type: 'Conference',
                    },
                    {
                        text: 'Days',
                        value: 'SessionCommittees.Days',
                        type: 'Session',
                    },
                    {
                        text: 'Time',
                        value: 'SessionCommittees.MeetingTime',
                        type: 'Session',
                    },
                    {
                        text: 'Room',
                        value: 'SessionCommittees.Room',
                        type: 'Session',
                    },
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionMemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            SessionCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Session');
            },
            InterimCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Interim');
            },
            ConferenceCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Conference');
            },
            filteredSessionCommittees: function () {
                if (this.Member && this.Member.Committees) {
                    return this.Member.Committees.filter(x => x.SessionCommitteeId != null && x.SessionCommittees && x.SessionCommittees.FullBody != true);
                }
                else {
                    return [];
                }
            },
            filteredInterimCommittees: function () {
                if (this.Member && this.Member.Committees) {
                    return this.Member.Committees.filter(x => x.InterimYearCommitteeId != null);
                }
                else {
                    return [];
                }
            },
            filteredConferenceCommittees: function () {
                if (this.Member && this.Member.Committees) {
                    return this.Member.Committees.filter(x => x.ConferenceCommitteeId != null);
                }
                else {
                    return [];
                }
            },
        }
    };
</script>
<style></style>