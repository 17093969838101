<template>
    <v-row>
        <v-col cols="12">
            <div class="headline">Bills</div>

            <div><b>Active Bills</b></div>
            <span v-if="ActiveBills && ActiveBills.length == 0 && !loading1"><b><i>No Active Bills</i></b></span>
            <FilterableDataTable v-else :headers="billHeadersA" :loading="loading1" hide-default-header hide-default-footer disable-pagination :items="ActiveBills" item-key="BillId" class="elevation-1">
                <template v-slot:item.BillNumberOnly="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType}}&nbsp;{{item.BillNumberOnly}}</router-link>
                </template>
            </FilterableDataTable>

            <div class="mt-4"><b>Inactive Bills</b></div>
            <span v-if="TabledBills && TabledBills.length == 0 && !loading2"><b><i>No Inactive Bills</i></b></span>
            <FilterableDataTable v-else :headers="billHeadersT" :loading="loading2" hide-default-header hide-default-footer disable-pagination :items="TabledBills" item-key="BillId" class="elevation-1">
                <template v-slot:item.BillNumberOnly="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType}}&nbsp;{{item.BillNumberOnly}}</router-link>
                </template>
            </FilterableDataTable>


            <div class="mt-4" v-if="SponsoredBills != null"><b>Sponsored Bills</b></div>
            <div v-if="SponsoredBills && SponsoredBills.length == 0 && !loading3"><b><i>No Sponsored Bills</i></b></div>
            <FilterableDataTable v-else :headers="billHeadersS" :loading="loading3" hide-default-header hide-default-footer disable-pagination :items="SponsoredBills" item-key="BillId" class="elevation-1">
                <template v-slot:item.BillNumberOnly="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType}}&nbsp;{{item.BillNumberOnly}}</router-link>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    export default {
        name: 'Bills',
        props: ['CommitteeId'],
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData()
            {
                if (this.currentRoute.includes('/Interim'))
                {

                } else
                {
                    this.loading1 = true;
                    this.loading2 = true;
                    this.loading3 = true;

                    fetch('/api/SessionCommittees/ActiveBills/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading1 = false;
                        if (res.ok)
                        {
                            res.json().then(r =>
                            {
                                this.ActiveBills = EncodeDatetimes(r);
                            });
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });

                    fetch('/api/SessionCommittees/InactiveBills/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading2 = false;
                        if (res.ok)
                        {
                            res.json().then(r =>
                            {
                                this.TabledBills = EncodeDatetimes(r);
                            });
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });

                    fetch('/api/SessionCommittees/Bills/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading3 = false;
                        if (res.ok)
                        {
                            res.json().then(r =>
                            {
                                this.SponsoredBills = EncodeDatetimes(r);
                            });
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });

                }
            },
        },
        data: function ()
        {
            return {
                loading1: false,
                loading2: false,
                loading3: false,
                billHeadersA: [
                    { text: 'Bill', value: 'BillNumberOnly', width: '150px' },
                    { text: 'Title', value: 'Title', width: '*' }],
                billHeadersT: [
                    { text: 'Bill', value: 'BillNumberOnly', width: '150px' },
                    { text: 'Title', value: 'Title', width: '*' }],
                billHeadersS: [
                    { text: 'Bill', value: 'BillNumberOnly', width: '150px' },
                    { text: 'Title', value: 'Title', width: '*' }],
                SponsoredBills: [],
                ActiveBills: [],
                TabledBills: []
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            CommitteeId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
        }
    };
</script>
<style></style>