<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline" v-if="Session && Session.YearString">{{Session.YearString}} Subject Index</div>
            <v-progress-linear indeterminate v-if="loading" color="primary"></v-progress-linear>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 sm12 md4 v-for="i in Keywords" :key="i.KeywordId">
                        <v-card outlined
                                tile>
                            <router-link :to="'/Session/Subject/' + i.KeywordId">{{i.Keyword}}</router-link>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'SubjectIndex',
        props: ['SessionId'],
        components: {
            Sidebar
        },
        methods: {
            LoadData() {
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });

                this.loading = true;
                fetch('/api/Keywords?SessionId=' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Keywords = EncodeDatetimes(response);
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
        },
        data: function () {
            return {
                Session: {},
                Keywords: [
                ],
                loading: true,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'Subject Index';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + " Subject Index";
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>