<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <span v-if="currentRoute.includes('Session_Laws')">
                <Sidebar :SessionId="SessionId"></Sidebar>
            </span>
            <span v-else>
                <Sidebar></Sidebar>
            </span>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                <span v-if="currentRoute.includes('Constitution')">Constitution</span>
                <span v-else-if="currentRoute.includes('Rules/Administrative')">Administrative Rules</span>
                <span v-else-if="currentRoute.includes('Session_Laws')"><div v-if="Session">{{Session.YearString}}</div> Table of Contents</span>
                <span v-else-if="currentRoute.includes('Statutes')">Codified Laws</span>
                <p v-if="currentRoute == '/Statutes'" style="color: red; font-size: larger;">
                    Effective {{StatuesEffectiveDate}}.
                </p>
                <v-breadcrumbs divider=">" :items="breadcrumbs"></v-breadcrumbs>
                <v-row v-if="error">
                    <v-col cols="12">
                        <v-alert type="error">
                            {{error}} - An error occurred; please try again later.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <div v-if="currentRoute.includes('Session_Laws')"><GoogleSearchStatute :SessionId="SessionId"></GoogleSearchStatute></div>
                            <div v-else><GoogleSearchStatute></GoogleSearchStatute></div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-divider></v-divider>
            <div class="px-0" v-if="currentRoute.includes('Rules/Administrative')">
                <v-container style="text-align:center" class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-card class="pa-2"
                                    outlined
                                    tile
                                    @click="rulefilter = 'All'">
                                All
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col sm="4" cols="12">
                            <v-card class="pa-2"
                                    outlined
                                    tile
                                    @click="rulefilter = 'Active'">
                                Active
                            </v-card>
                        </v-col>
                        <v-col sm="4" cols="12">
                            <v-card class="pa-2"
                                    outlined
                                    tile
                                    @click="rulefilter = 'Transferred'">
                                Transferred, Repealed, Superseded
                            </v-card>
                        </v-col>
                        <v-col sm="4" cols="12">
                            <v-card class="pa-2"
                                    outlined
                                    tile
                                    @click="rulefilter = 'Reserved'">
                                Reserved
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <FilterableDataTable :headers="filteredHeaders"
                                 :items="filteredItems"
                                 :item-key="key"
                                 disable-pagination
                                 :sort-by="sortBy"
                                 :sort-desc="[false]"
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 hide-default-footer
                                 class="elevation-1">
                <template v-slot:item.Article="{ item }">
                    <router-link :to="'/Rules/Administrative/' + item.RuleNumber" style="text-decoration: none;">{{item.Article}}</router-link>
                </template>
                <template v-slot:item.Title="{ item }">
                    <router-link :to="'/Statutes/' + item.Statute" style="text-decoration: none;">{{item.Title}}{{item.TitleLetter}}</router-link>
                </template>
                <template v-slot:item.BillNumber="{ item }">
                    <span v-if="item.BillType == null && item.BillNumber">
                        {{item.BillNumber.replace('-','&#8209;').replace(' ','&nbsp;')}}
                    </span>
                    <span v-else>
                        <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType}}&nbsp;{{item.BillNumberOnly}}</router-link>
                    </span>
                </template>
                <template v-slot:item.Chapter="{ item }">
                    <span v-if="currentRoute.includes('Session_Laws')">
                        <router-link :to="'/Statutes/Session_Laws/Chapter/' + item.SessionLawId" style="text-decoration: none;">
                            {{item.Chapter}}
                        </router-link>
                    </span>
                    <span v-else>
                        <span v-if="item.Chapter == 0"></span>
                        <span v-else>
                            <router-link :to="'/Constitution/' + item.Statute.replace('0N-', '')" style="text-decoration: none;">
                                {{item.Chapter}}
                            </router-link>
                        </span>
                    </span>
                </template>
                <template v-slot:item.CatchLine="{ item }">
                    <div v-if="item.Statute == '0N-Preamble'">
                        <router-link :to="'/Constitution/' + item.Statute.replace('0N-', '')" style="text-decoration: none;">{{item.CatchLine}}</router-link>
                    </div>
                    <div v-else>
                        <div class="hidden-md-and-up">
                            {{item.CatchLine}}
                        </div>
                        <div class="hidden-sm-and-down">
                            {{shortenCell(item.CatchLine)}}
                        </div>
                    </div>
                </template>
                <template v-slot:no-data>
                    <v-alert v-if="loading" :value="true" type="info">
                        Loading Please wait
                    </v-alert>
                    <v-alert v-else :value="true" type="info">
                        No Results
                    </v-alert>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>

</template>
<script>
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Statutes',
        props: ['SessionId'],
        components: {
            GoogleSearchStatute,
            Sidebar,
            FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/Statutes/LastStatuesEffectiveDate', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        this.error = res.status;
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    let realdateTime = EncodeDatetimes(response);
                    if (realdateTime) {
                        this.StatuesEffectiveDate = realdateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                    }
                }).catch(error => {
                    throw new Error(error);
                });
                if (this.SessionId) {
                    fetch('/api/Sessions/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else if (res.status === 404) {
                            this.$router.push('/Session/Archived');
                            return null;
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                if (this.currentRoute.includes('Constitution')) {
                    this.hideSessionLawSearch = false;

                    this.createBreadcrumbs('Constitution', '/Constitution');

                    fetch('/api/Statutes/Chapter', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else {
                            this.error = res.status;
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else if (this.currentRoute.includes('Administrative')) {
                    this.hideSessionLawSearch = false;

                    this.createBreadcrumbs('Administrative Rules', '/Rules/Administrative');

                    fetch('/api/Rules', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else {
                            this.error = res.status;
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else if (this.currentRoute.includes('Session_Laws')) {
                    this.createBreadcrumbs('Session Laws', '/Statutes/Session_Laws');

                    this.hideSessionLawSearch = true;
                    fetch('/api/SessionLaws/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else {
                            this.error = res.status;
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                        if (this.$vuetify.breakpoint.mobile) {
                            this.items.forEach(x => {
                                if (x.Catchline != null) {
                                    if (x.Catchline.length > 110) {
                                        x.Catchline = x.Catchline.substring(0, 110);
                                    }
                                }
                                return x;
                            });
                        }
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else if (this.currentRoute.includes('Statutes')) {
                    this.error = null;
                    this.hideSessionLawSearch = false;

                    this.createBreadcrumbs('Codified Laws', '/Statutes');

                    fetch('/api/Statutes/Title', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json();
                        }
                        else {
                            this.error = res.status;
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }


            },
            isNumeric(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
            quickFindSearch() {
                if (this.currentRoute.includes('Constitution')) {
                    fetch('/api/Statutes/ArticleToId/' + this.quickFind, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json().then(r => {
                                this.$router.push('/Statutes/Constitution/' + r);
                            });
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else if (this.currentRoute.includes('Administrative')) {
                    this.$router.push('/Rules/Administrative/' + r);
                    //fetch('/api/Rules/RuleToId/' + this.quickFind, {
                    //    method: "GET",
                    //    credentials: "include",
                    //    headers: {
                    //        "Content-Type": "application/json"
                    //    },
                    //}).then(res => {
                    //    this.loading = false;
                    //    if (res.ok) {
                    //        return res.json();
                    //    }
                    //    else {
                    //        return null;
                    //    }
                    //    throw new Error(res.statusText);
                    //}).then(response => {
                    //    let r = EncodeDatetimes(response);
                    //    if (r != null) {
                    //        this.$router.push('/Rules/Administrative/' + r);
                    //    }

                    //}).catch(error => {
                    //    throw new Error(error);
                    //});
                }
                else if (this.currentRoute.includes('Codified_Laws') || this.currentRoute.includes('Statutes')) {
                    fetch('/api/Statutes/StatuteToId/' + this.quickFind, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.ok) {
                            return res.json().then(r => {
                                this.$router.push('/Statutes/' + r);
                            });
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).catch(error => {
                        throw new Error(error);
                    });

                }
                //else if (this.currentRoute.includes('Session_Laws')) { window.location = '/Statutes/Session_Laws/Chapter/' + this.quickFind;}

            },
            createBreadcrumbs(route, url) {
                this.breadcrumbs = [];
                var bci = {
                    to: '',
                    link: true,
                    text: '',
                };

                bci.to = "/";
                bci.text = "Home";
                this.breadcrumbs.push(bci);

                var bci2 = {
                    to: '',
                    link: true,
                    text: '',
                };
                bci2.to = url;
                bci2.text = route;
                bci2.disabled = true,

                    this.breadcrumbs.push(bci2);
            },
            shortenCell(str) {
                if (str != null) {
                    if (str.length > 160) {
                        return str.substring(0, 160);
                    } else {
                        return str;
                    }
                }
            }

        },
        data: function () {
            return {
                StatuesEffectiveDate: 'July 1, 2024',
                loading: false,
                error: null,
                sortBy: [],
                quickFind: null,
                placeholder: 'Article, Chapter, or Section',
                breadcrumbs: [],
                items: [],
                Session: {},
                key: 'Article',
                headers: [
                    {
                        text: 'Title',
                        value: 'Title',
                        Type: 'Codified_Law',
                        width: '15ch'
                    },
                    {
                        text: 'Article',
                        value: 'Chapter',
                        Type: 'Constitution',
                        width: '15ch'
                    },
                    {
                        text: 'Catchline',
                        value: 'CatchLine',
                        Type: 'Statute',
                        width: '*'
                    },
                    {
                        text: 'Rule',
                        value: 'Article',
                        Type: 'Rule',
                        width: '15ch'
                    },
                    {
                        text: 'Agency',
                        value: 'Agency',
                        Type: 'Rule',
                    },
                    {
                        text: 'Title',
                        value: 'Catchline',
                        Type: 'Rule',
                        width: '*'
                    },
                    {
                        text: 'Chapter',
                        value: 'Chapter',
                        Type: 'Session_Laws',
                        width: '15ch'
                    },
                    {
                        text: 'Bill',
                        value: 'BillNumber',
                        Type: 'Session_Laws',
                        width: '15ch'
                    },
                    {
                        text: 'Catchline',
                        value: 'Catchline',
                        Type: 'Session_Laws',
                        width: '*'
                    },
                ],
                hideSessionLawSearch: false,
                rulefilter: "Active",
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            currentRoute() {
                this.items = [];
                this.LoadData();
            },
            SessionId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            filteredHeaders: function () {
                if (this.currentRoute.includes('Constitution')) {
                    this.sortBy = ['Article'];
                    this.key = 'StatuteId';
                    var types = ['Statute', 'Constitution'];
                    return this.headers.filter(x => types.includes(x.Type));
                }
                else if (this.currentRoute.includes('Administrative')) {
                    this.sortBy = ['Rule'];
                    this.key = 'Rule';
                    var res = this.headers.filter(x => x.Type == 'Rule');
                    if (this.rulefilter != "Active") {

                        res = res.filter(x => x.value != "Agency");
                    }

                    return res;
                }
                else if (this.currentRoute.includes('Session_Laws')) {
                    this.sortBy = ['Chapter'];
                    this.key = 'SessionLawId';
                    return this.headers.filter(x => x.Type == 'Session_Laws');
                }
                else if (this.currentRoute.includes('Statutes')) {
                    this.sortBy = ['Title'];
                    this.key = 'StatuteId';
                    var types = ['Codified_Law', 'Statute'];
                    return this.headers.filter(x => types.includes(x.Type));
                }
                else {
                    let lowerRoute = window.location.href.toLowerCase();
                    if (lowerRoute.includes('administrative')) {
                        window.location.replace(lowerRoute.replace('rules', 'Rules').replace('administrative', 'Administrative'));
                    }
                    else if (lowerRoute.includes('constitution')) {
                        window.location.replace(lowerRoute.replace('statutes', 'Statutes').replace('constitution', 'Constitution'));
                    }
                    else if (lowerRoute.includes('session_laws')) {
                        window.location.replace(lowerRoute.replace('statutes', 'Statutes').replace('session_laws', 'Session_Laws'));
                    }
                    else if (lowerRoute.includes('codified_laws') || lowerRoute.includes('statutes')) {
                        window.location.replace(lowerRoute.replace('statutes', 'Statutes').replace('codified_laws', 'Codified_Laws'));
                    }
                    return [];
                }
            },
            filteredItems: function () {
                if (this.items && this.items.length > 0) {
                    if (this.currentRoute.includes('Constitution')) {
                        return this.items.filter(x => x.Title == '0' && x.TitleLetter == 'N').sort((a, b) => (a.Chapter > b.Chapter) ? 1 : -1);
                    }
                    else if (this.currentRoute.includes('Administrative')) {
                        if (this.rulefilter != 'All') {
                            return this.items.filter(x => x.Status.Status1 == this.rulefilter).sort((a, b) => (a.Article > b.Article) ? 1 : -1);
                        }
                        else {
                            return this.items.sort((a, b) => (a.Article > b.Article) ? 1 : -1);
                        }
                    }
                    else if (this.currentRoute.includes('Session_Laws')) {
                        return this.items;
                    }
                    else if (this.currentRoute.includes('Statutes')) {
                        return this.items.filter(x => x.Title != '0');
                    }
                } else {
                    return [];
                }
            },
            Title: function () {
                let title = '';
                if (this.currentRoute.includes('Constitution')) {
                    title = "Constitution";
                }
                else if (this.currentRoute.includes('Administrative')) {
                    title = "Administrative Rules";
                }
                else if (this.currentRoute.includes('Session_Laws')) {
                    if (this.Session && this.Session.Year) {
                        title = this.Session.Year + " Session Laws";
                    }
                    else {
                        title = "Session Laws";
                    }
                }
                else if (this.currentRoute.includes('Statutes')) {
                    title = "Codified Laws";
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0].replace('rules', 'Rules').replace('administrative', 'Administrative').replace('statutes', 'Statutes').replace('constitution', 'Constitution').replace('codified_laws', 'Codified_Laws').replace('session_laws', 'Session_Laws') }
                ]
            }
        }
    };
</script>
<style></style>