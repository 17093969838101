<template>
    <v-container>
        <div class="headline">
            Statistical Comparisons
        </div>
        <v-list>
            <v-list-item v-for="i in items">
                <v-list-item-content>
                    <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Statistical Comparison/' +i.DocumentId + '.pdf'">
                        {{i.Year}} Statistical Comparison
                    </a>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-container>
</template>
<script>
    export default {
        name: 'StatisticalComparison',
        components: {

        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=80', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.items.sort((a, b) => (a.Year < b.Year) ? 1 : -1)
                }).catch(error => {
                    throw new Error(error);
                });
            },

        },
        data: function () {
            return {
                items: [

                ],
                loading: true,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Statisical Comparison';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>