<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2"></v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Legislative Session Housing Questionnaire
            </div>
            <div>If you would like to offer lodging for Legislators, Pages, or Interns in Pierre or Ft. Pierre during the SD Legislative Session, please complete the following information. If you have any questions, you can contact the Legislative Research Council (LRC) at <a href="tel:(605) 773-3251">(605) 773-3251</a> or send an email to <a rel="noopener" href="mailto:LRC@sdlegislature.gov">LRC@sdlegislature.gov</a>.</div>
            <v-form lazy-validation ref="form" v-model="valid">


                <v-text-field :counter="150" v-model="Request.Email" label="Email" :rules="emailRules" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.FirstName" label="First Name" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.LastName" label="Last Name" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.Business" label="Business(if applicable)"></v-text-field>

                <v-text-field :counter="50" v-model="Request.Address" label="Address" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.Address2" label="Address 2" ></v-text-field>
                <v-text-field :counter="50" v-model="Request.City" label="City" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.PrimePhone" label="Primary Phone" :rules="[v => !!v || 'Required']" required></v-text-field>
                <v-text-field :counter="50" v-model="Request.SecondPhone" label="Secondary Phone" ></v-text-field>

                <div>
                    Housing Type:
                </div>
                <v-radio-group v-model="Request.HousingType" :rules="[v => !!v || 'Required']" required>
                    <v-radio label="Apartment" value="Apartment"></v-radio>
                    <v-radio label="House" value="House"></v-radio>
                    <v-radio label="Sleeping Room" value="Room"></v-radio>
                    <v-radio label="Other" value="Other"></v-radio>

                </v-radio-group>


                <v-textarea v-model="Request.Description" :rules="[v => !!v || 'Required']" required
                            label="Description: this would include number of rooms/beds/bathrooms, garage, and any other info you feel would be useful to the person searching for a place to stay. "></v-textarea>
                <v-textarea v-model="Request.Restrictions"
                            label="Restrictions: this would include such things as no smoking, no pets, if you have a preference as to the person staying (legislator, page, or intern), or any other restrictions you choose to set. "></v-textarea>
                <v-textarea v-model="Request.Comments"
                            label="Comments: this would include any additional information you feel would be helpful to potential renters (off-street parking, close to Capitol, etc.). "></v-textarea>

                <br />
                <div>The SD Legislature meets during the months of January, February and part of March. Start and end dates vary from year to year. Rental prices are between you and the person renting, we do not ask for or provide that information. </div>
                <br />
                <v-btn :disabled="submitting" @click="submit()">Submit</v-btn>
                <br />
                <v-alert v-if="submitted" type="success">
                    Submitted
                </v-alert>
            </v-form>

        </v-col>
    </v-row>

</template>
<script>
    export default {
        name: 'Housing',
        components: {
        },
        methods: {
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            submit() {
                this.submitting = true
                if (this.$refs.form.validate()) {
                    myfetch('/api/Housing', {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(this.Request)
                    }).then(res => {
                        if (res.ok) {
                            
                            this.submitted = true;
                            this.Request = this.blankRequest;
                            this.$refs.form.resetValidation()
                        }
                    });
                }
            }
        },
        data: function () {
            return {
                submitted: false,
                submitting:false,
                search: '',
                valid: true,
                loading: false,
                dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
                emailRules: [
                    v => !!v || "E-mail is required",
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ],
                menu1: false,
                blankRequest: {
                    HousingId: 0,
                    FirstName: null,
                    LastName: null,
                    Email: null,
                    Business: null,
                    Address: null,
                    Address2: null,
                    City: null,
                    PrimePhone: null,
                    SecondPhone: null,
                    HousingType: null,
                    Description: null,
                    Restrictions: null,
                    Comments: null,
                },
                Request: {
                    HousingId: 0,
                    FirstName: null,
                    LastName: null,
                    Email: null,
                    Business: null,
                    Address: null,
                    Address2: null,
                    City: null,
                    PrimePhone: null,
                    SecondPhone: null,
                    HousingType: null,
                    Description: null,
                    Restrictions: null,
                    Comments: null,
                },
            }
        },
        mounted: function () {
        },
        watch: {
            date(val) {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        computed: {
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            Title: function () {
                let title = 'Housing Questionnaire';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>