<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="SessionId" :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline" v-if="Session && Session.Year">{{Session.YearString}} Prison/Jail Cost Estimates</div>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 :search="search"
                                 item-key="item.DocumentId"
                                 group-by="BillType"
                                 multi-sort
                                 disable-pagination
                                 hide-default-footer
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:group.header="{items, isOpen, toggle, group, groupby}">
                    <td colspan="3">
                        Bill Type: {{LongBillName(group)}}
                        <v-icon v-if="isOpen" @click="toggle">{{minus}}</v-icon>
                        <v-icon v-else @click="toggle">{{plus}}</v-icon>
                    </td>
                </template>
                <template v-slot:item.BillNumber="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumber + '&nbsp;'}}</router-link>
                </template>
                <template v-slot:item.PrisonJail="{ item }">
                    <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'">{{item.BillType + '&nbsp;' + item.BillNumber + '-' + Ending(item.Filename)}}</a>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    import { mdiPlus } from '@mdi/js';
    import { mdiMinus } from '@mdi/js';
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'PrisonJail',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            loadData()
            {
                this.loading = true;
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.Session = EncodeDatetimes(response);
                }).catch(error =>
                {
                    throw new Error(error);
                });
                fetch('/api/Documents/DocumentType?Type=16&SessionIds=' + this.SessionId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        var temp = EncodeDatetimes(response);

                        temp.forEach(x =>
                        {
                            x.PrisonJail = x.BillType + ' ' + x.BillNumber + '-' + this.Ending(x.Filename);
                        });

                        this.items = temp.sort(
                            function (a, b)
                            {
                                if (a.BillType === b.BillType)
                                {
                                    return a.BillNumber - b.BillNumber;
                                }
                                return a.BillType > b.BillType ? 1 : -1;
                            });
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });

                fetch('/api/BillNumbers/BillTypes',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.BillTypes = EncodeDatetimes(response);

                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });
            },
            Ending(filename)
            {
                var str = filename.substring(0, filename.indexOf('.'));
                var lastChar = str.charAt(str.length - 1);
                return lastChar.toUpperCase();
            },
            LongBillName(short)
            {
                if (this.BillTypes && this.BillTypes.length > 0 && this.BillTypes.find(x => x.BillType == short))
                {
                    return this.BillTypes.find(x => x.BillType == short).LongBillType || '';
                }
                else
                {
                    return short;
                }
            },
        },
        data: function ()
        {
            return {
                plus: mdiPlus,
                minus: mdiMinus,
                search: '',
                loading: false,
                Session: {},
                headers: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillNumber',
                        width: '15ch'
                    },
                    {
                        text: 'Prison/Jail Cost Estimate',
                        align: 'start',
                        value: 'PrisonJail',
                        width: '30ch'
                    },

                    {
                        text: 'Title',
                        value: 'BillTitle',
                        width: '*'
                    },
                ],
                items: [],
                BillTypes: [],
            }
        },
        mounted: function ()
        {
            this.loadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Loading Prison/Jail Cost Estimates...';
                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + " Prison/Jail Cost Estimates";
                }
                else
                {
                    title = 'Prison/Jail Cost Estimates';
                }
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>