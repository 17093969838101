<template>
    <v-container>
        <div class="headline">
            External Resources
        </div>
        <v-list>
            <v-list-item v-for="i in Items">
                <v-list-item-content>
                    <a rel="noopener" :href="i.url">
                        {{i.name}}
                    </a>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-container>
</template>
<script>
    export default {
        name: 'ExternalResources',
        components: {

        },
        methods: {

        },
        data: function () {
            return {
                Items: [
                    { name: 'National Conference of State Legislatures', url: 'https://www.ncsl.org/' },
                    { name: 'Council of State Governments', url: 'https://www.csg.org/' },
                    { name: 'Education Commission of the States', url: 'https://www.ecs.org/' },
                    { name: 'South Dakota Government Agencies', url: 'https://www.sd.gov/cs?id=content_category&category=dd2a73f21b3f30101c4fa97ae54bcb65' },
                    { name: 'South Dakota Investment Council', url: 'https://sdic.sd.gov/' },
                    { name: 'Department of Legislative Audit', url: 'https://legislativeaudit.sd.gov/' },
                    { name: 'Code of Federal Regulations', url: 'https://www.law.cornell.edu/cfr/text' },
                    { name: 'U.S. Code', url: 'https://www.law.cornell.edu/uscode/text' },
                    { name: 'Lobbyist Registration', url: 'https://sosenterprise.sd.gov/businessservices/lobbyist/default.aspx' }
                ],
            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'External Resources';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>