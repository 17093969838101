<template>
<v-container>
    <div class="headline">LRC Maps</div>
    Maps from the South Dakota Legislative Research Council
    <v-card flat>
        <v-card-title>Legislative District Maps</v-card-title>
        <v-card-text>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12 sm12 md4 v-for="i in Districts" :key="i">
                        <v-card flat
                                tile>
                            <span><router-link :to="'/Legislators/Find?District=' + i">District {{i}}</router-link> ({{CountyList(i)}})</span>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</v-container>
</template>
<script>
    export default {
        name: 'Maps',
        components: {

        },
        methods: {
            LoadData() {
                fetch('/api/Sessions/Current', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
                fetch('/api/DistrictCounties', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.DistrictCounties = EncodeDatetimes(response);
                    this.Districts = [...new Set(this.DistrictCounties.map(item => item.District.District))].sort();
                }).catch(error => {
                    throw new Error(error);
                });
            },
            CountyList(district) {
                var d = this.DistrictCounties.filter(x => x.District.District == district && x.SessionId == this.Session.SessionId);
                var res = "";
                d.forEach(x => {
                    res += x.County.County + ", ";
                });

                return res.replace(/,\s*$/, "");

            }
        },
        data: function () {
            return {
                DistrictCounties: [],
                Districts: [],
                Session: {},
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Legislative Maps';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>