<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col md="2" cols="12">
                <RedistrictingMenu></RedistrictingMenu>
            </v-col>
            <v-col md="10" cols="12">
                <v-card flat>
                    <v-card-title>
                        <h3>Redistricting</h3>
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            Redistricting in South Dakota
                        </h3>
                        <p>
                            Every year after the census is completed, the South Dakota Legislature is constitutionally required to redraw its legislative district boundaries to accommodate shifts in population and ensure equal representation to all South Dakotans. These new boundaries are then implemented across South Dakota for the next ten years.
                        </p>
                        <h3>
                            Census Apportionment Numbers
                        </h3>
                        <p>
                            On April 26, 2021, the U.S. Census Bureau announced the population totals for the 2020 Census. While South Dakota's total population has been calculated, the data used for redistricting will not be available until August. More information on the data can be found on the Bureau's website.
                        </p>
                        <p>
                            South Dakota Resident Population: 886,667
                        </p>
                        <p>
                            Average Population per State Senate District: 25,333
                        </p>
                        <p>
                            <a href="/Legislators/Find?activeLayer=2021" style="font-size: 16.3px; font-weight: bold;">2021 Adopted Map</a>
                        </p>
                        <h3>
                            2021 Special Legislative Session on Redistricting
                        </h3>
                        <p>
                            <v-container fluid pa-0>
                                <v-row dense>
                                    <v-col cols="6">
                                        House
                                        <table class="table-dates" style="width: 100%">
                                            <tr><th>Name</th></tr>
                                            <tr v-for="h in houseMaps">
                                                <td><a :href="'https://mylrc.sdlegislature.gov/api/documents/' + h.DocumentId + '.pdf'">{{h.Title}}</a></td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="6">
                                        Senate
                                        <table class="table-dates" style="width: 100%">
                                            <tr><th>Name</th></tr>
                                            <tr v-for="s in senateMaps">
                                                <td><a :href="'https://mylrc.sdlegislature.gov/api/documents/' + s.DocumentId + '.pdf'">{{s.Title}}</a></td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </p>
                        <h3>
                            Interim Committee Proposed Maps
                        </h3>
                        <p>
                            <v-container fluid pa-0>
                                <v-row dense>
                                    <v-col cols="6">
                                        House Legislative Redistricting Committee
                                        <table class="table-dates" style="width: 100%">
                                            <tr><th>Name</th></tr>
                                            <tr v-for="h in house">
                                                <td><a :href="'https://mylrc.sdlegislature.gov/api/documents/' + h.DocumentId + '.pdf'">{{h.Title}}</a></td>
                                            </tr>
                                        </table>
                                    </v-col>
                                    <v-col cols="6">
                                        Senate Legislative Redistricting Committee
                                        <table class="table-dates" style="width: 100%">
                                            <tr><th>Name</th></tr>
                                            <tr v-for="s in senate">
                                                <td><a :href="'https://mylrc.sdlegislature.gov/api/documents/' + s.DocumentId + '.pdf'">{{s.Title}}</a></td>
                                            </tr>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </p>
                        <h3>
                            Itinerary for October Tour
                        </h3>
                        <p>
                            <table class="table-dates">
                                <tr><th>City</th><th>Date</th><th>Time</th><th>Location</th><th>Address</th><th>Room</th></tr>
                                <tr><td>Rapid City</td><td>October 11th</td><td>8am (MST)</td><td>Black Hills State University</td><td>4300 Cheyenne Boulevard, Box Elder, SD 57719</td><td>Room 112</td></tr>
                                <tr><td>Mission</td><td>October 11th</td><td>4pm</td><td>Rosebud Casino<sup><b>(1)(2)</b></sup></td><td>30421 U.S. Hwy 83, Valentine, NE<sup><b>(1)(2)</b></sup></td><td>Entertainment Center<sup><b>(1)(2)</b></sup></td></tr>
                                <tr><td>Mobridge</td><td>October 12th</td><td>8am</td><td>Wrangler Inn</td><td>820 W Grand Crossing, Mobridge, SD 57601</td><td>Wranger Inn Conference Room</td></tr>
                                <tr><td>Aberdeen</td><td>October 12th</td><td>1pm</td><td>Aberdeen Recreation & Cultural Center</td><td>225 3rd Ave SE, Aberdeen, SD 57401</td><td>TBD</td></tr>
                                <tr><td>Watertown</td><td>October 12th</td><td>6pm</td><td>Lake Area Technical College</td><td>1201 Arrow Ave NE, Watertown, SD 57201</td><td>LATC Student Center </td></tr>
                                <tr><td>Sioux Falls</td><td>October 13th</td><td>2pm</td><td>USD Community College</td><td>4801 N Career Ave, Sioux Falls, SD 57107</td><td>FADM 186</td></tr>
                                <tr><td>Sioux Falls</td><td>October 13th</td><td>6pm</td><td>USD Community College</td><td>4801 N Career Ave, Sioux Falls, SD 57107</td><td>FADM 186</td></tr>
                            </table>
                            <sup><b>(1)</b></sup> Masks are <b>Required</b>. <br />
                            <sup><b>(2)</b></sup> Location has changed.
                        </p>
                        <h3>
                            South Dakota Redistricting Timetable for 2021
                        </h3>
                        <p>
                            <table class="table-dates">
                                <tr><th>Date</th><th>Milestone</th></tr>
                                <tr><td>August 12th</td><td>Legacy-Format Data Released by U.S. Census Bureau</td></tr>
                                <tr><td>August 30th</td><td>Committee Meeting to Discuss Data</td></tr>
                                <tr><td>September 9th</td><td>Committee Meeting to Discuss Initial Maps</td></tr>
                                <tr><td style="text-decoration: line-through;">September 22nd</td><td>Cancelled</td></tr>
                                <tr><td>September 30th</td><td>Committee Meeting to Finalize Draft Maps for October Tour</td></tr>
                                <tr><td>September 30th</td><td>New-Format Data Released by U.S. Census Bureau</td></tr>
                                <tr><td>October 11th-13th</td><td>Redistricting Committees Holding Meetings Across the State</td></tr>
                                <tr><td>October 18th</td><td>Committee Meeting to Discuss and Incorporate Public Feedback</td></tr>
                                <tr><td>October 25th</td><td>Committee Meeting to Finalize Draft Maps for Special Session</td></tr>
                                <tr><td>November 8th</td><td>Special Session to Adopt Legislation Regarding Redistricting</td></tr>
                                <tr><td>December 1st</td><td>Constitutional Deadline for Legislature to Complete Redistricting</td></tr>
                            </table>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    const RedistrictingMenu = () => import("@/Redistricting/RedistrictingMenu.vue");

    export default {
        name: 'Redistricting',
        components: {
            RedistrictingMenu
        },
        methods: {

        },
        data: function () {
            return {
                loading: false,
                house: [],
                houseMaps: [],
                senate: [],
                senateMaps: [],
            }
        },
        mounted: function () {
            fetch('/api/Documents/DocumentType?Type=95&SessionIds=44&SessionIds=65', { method: 'GET' })
                .then(r => {
                    if (r.ok) {
                        r.json().then(r => {
                            var h = r.filter(x => x.InterimYearCommitteeId == 433);
                            var s = r.filter(x => x.InterimYearCommitteeId == 434);

                            var hm = r.filter(x => x.SessionCommitteeId == 1046);
                            var sm = r.filter(x => x.SessionCommitteeId == 1072);

                            h.sort((a, b) => {
                                if (a.Title < b.Title)
                                    return -1;
                                else if (a.Title > b.Title)
                                    return 1;
                                else
                                    return 0;
                            });

                            hm.sort((a, b) => {
                                if (a.Title < b.Title)
                                    return -1;
                                else if (a.Title > b.Title)
                                    return 1;
                                else
                                    return 0;
                            });

                            s.sort((a, b) => {
                                if (a.Title < b.Title)
                                    return -1;
                                else if (a.Title > b.Title)
                                    return 1;
                                else
                                    return 0;
                            });

                            sm.sort((a, b) => {
                                if (a.Title < b.Title)
                                    return -1;
                                else if (a.Title > b.Title)
                                    return 1;
                                else
                                    return 0;
                            });

                            this.house = h;
                            this.senate = s;
                            this.houseMaps = hm;
                            this.senateMaps = sm;
                        });
                    }
                });
        },
        watch: {

        },
        computed: {
            Title: function () {
                let title = 'Redistricting';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>