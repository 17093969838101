var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Required Reports ")]),_c('v-card-text',[_c('v-container',[_c('v-flex',{attrs:{"xs12":""}},[_c('GoogleSearch')],1)],1),_c('v-divider'),_c('FilterableDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"DocumentId","loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","disable-pagination":"","id":"requiredTable"},scopedSlots:_vm._u([{key:"item.SortDate",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value +'api/Documents/Required Report/' + item.DocumentId + '.pdf'}},[_vm._v(" "+_vm._s(_vm._f("FormatDates")(item.DocumentDate))+" ")])]}},{key:"item.Subject",fn:function(ref){
var item = ref.item;
return [(item.RequiredReportType && item.RequiredReportType.Subject)?_c('div',{domProps:{"innerHTML":_vm._s(item.RequiredReportType.Subject)}}):_vm._e(),(item.RequiredReportType && item.RequiredReportType.ReceivedBy)?_c('div',[_c('div',{staticClass:"hidden-sm-and-down"},[_c('b',[_vm._v("Delivered To: ")]),_vm._v(_vm._s(item.RequiredReportType.ReceivedBy)+" ")])]):_vm._e(),(item.RequiredReportType && item.RequiredReportType.Frequency)?_c('div',[_c('div',{staticClass:"hidden-sm-and-down",style:(_vm.StrikeThrough(item))},[_c('b',[_vm._v("Frequency: ")]),_vm._v(_vm._s(item.RequiredReportType.Frequency)+" ")])]):_vm._e(),(item.Repealed)?_c('div',[_c('div',{staticClass:"hidden-sm-and-down"},[_c('b',[_vm._v("Repealed: ")]),_vm._v(_vm._s(_vm._f("FormatDates")(item.Repealed))+" ")])]):_vm._e()]}},{key:"item.SDCLRULES",fn:function(ref){
var item = ref.item;
return [(item.RequiredReportType && item.RequiredReportType.Rules)?_c('a',{on:{"click":function($event){return _vm.quickFindSearch(item.RequiredReportType.Rules, 'Administrative')}}},[_vm._v(" "+_vm._s(item.RequiredReportType.Rules)+" ")]):_vm._e(),(item.RequiredReportType && item.RequiredReportType.Sdcl)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.splitStatutes(item.RequiredReportType.Sdcl))}}):_vm._e()]}},{key:"item.Repealed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("FormatDates")(item.Repealed))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }