<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Employment Opportunities
            </div>
            <br />
            <div>
                <FilterableDataTable :headers="headers"
                                     :items="items"
                                     item-key="DocumentId"
                                     sort-by="DocumentId"
                                     :sort-desc="[true]"
                                     disable-pagination
                                     hide-default-footer
                                     :loading="loading"
                                     loading-text="Loading... Please wait"
                                     class="elevation-1">
                    <template v-slot:item.Description="{ item }">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/JobPosting/' + item.DocumentId + '.pdf'">
                            {{item.Title}}
                        </a>
                    </template>
                    <template v-slot:no-data>
                        No positions open at this time.
                    </template>
                </FilterableDataTable>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Careers',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {

                fetch('/api/Documents/DocumentType?Type=90',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        this.loading = false;

                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.items = EncodeDatetimes(response);
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });
            }
        },
        data: function ()
        {
            return {
                search: '',
                loading: false,
                items: [],
                headers: [
                    { text: 'Listing', value: 'Description', width: '*' }
                ],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Careers';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>