<template>
    <v-container>
        <v-card flat>
            <v-card-title>Issue Memos / White Papers</v-card-title>
            <v-card-text>
                <v-container>
                    <GoogleSearch></GoogleSearch>
                </v-container>
                <v-divider></v-divider>
                Persons accessing these issue memoranda are hereby advised that the memoranda were both pertinent and accurate as of the date they were issued. Conditions may change in the intervening time period to the extent that certain memoranda are no longer applicable to current situations.
                <FilterableDataTable :headers="headers"
                                     :items="items"
                                     item-key="DocumentId"
                                     sort-by="DocumentId"
                                     :sort-desc="[true]"
                                     disable-pagination
                                     hide-default-footer
                                     :loading="loading"
                                     loading-text="Loading... Please wait"
                                     class="elevation-1">
                    <template v-slot:item.Title="{ item }">
                        <div v-if="item.Filename != 'not available'">
                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Issue Memo/' + item.DocumentId + '.pdf'">
                                {{item.Title}}
                            </a>
                        </div>
                        <div v-else>
                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Issue Memo/' + item.DocumentId + '.pdf'">
                                {{DatePretty(item.DocumentDate)}}
                            </a>
                        </div>
                    </template>
                </FilterableDataTable>
            </v-card-text>
        </v-card>
    </v-container>

</template>
<script>
    import moment from 'moment'
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");


    export default {
        name: 'IssueMemos',
        components: {
            GoogleSearch, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                fetch('/api/Documents/DocumentType?Type=49', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.items = response;
                }).catch(error =>
                {
                    throw new Error(error);
                });
            },

            FilenameReplace(s)
            {
                return s.toString().replace('im', '').replace('.pdf', '');
            },
            DatePretty(Date)
            {
                if (Date != undefined)
                {
                    return moment(Date).format('MM/DD/YYYY');
                } else
                {
                    return null;
                }
            },
        },
        data: function ()
        {
            return {
                headers: [
                    { text: 'Number', value: 'Title', width: '20ch' },
                    { text: 'Title', value: 'Description', width: '*' }
                ],
                loading: true,
                items: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Issue Memos';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>