<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="SessionId" :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Custom Bill Report
            </div>
            This form can be used to get the full Bill Directory or to customize your own bill search.
            <br />
            <div>
                <div>
                    Bill Tracking Lists are available in <a rel="noopener" :href="$MyLRCUrl.value">MyLRC</a>.
                </div>

                <div>
                </div>
                <b>Tips:</b>
                <ul>

                    <li>
                        Start values will retrieve
                        all bills on and after that value or date. (Example: If you enter 2/1/05 all bills
                        introduced on or <b>after</b> that date will be returned.)
                    </li>
                    <li>
                        End values will retrieve
                        all bills on and before that value or date. (Example: If you enter 3/1/05 all bills
                        introduced on or <b>before</b> that date will be returned.)
                    </li>
                    <li>
                        To return a list
                        with one bill or one date enter the value in both the starting and ending values.
                    </li>
                    <li>
                        To return a list of bills with Emergency Clauses click the Emergency Clause check
                        box.
                    </li>
                </ul>
            </div>

            <v-container fluid pa-0>
                <v-row no-gutters>
                    <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pa-1">
                        <v-btn @click="filterDialog=true" color="primary">Filter</v-btn>
                    </v-col>
                    <v-col v-else cols="12" class="pa-1">
                        Filter by:
                    </v-col>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterBillType" label="Bill Type" :items="BillTypes" clearable item-text="BillType" item-value="BillTypeId" outlined hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterKeyword" label="Keyword" :items="Keywords" clearable item-text="Keyword" item-value="KeywordId" outlined hide-details></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterCommittee" label="Assigned to Committee" :items="Committees" item-text="FullName" item-value="CommitteeId" outlined hide-details clearable></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterCommitteeSponsored" label="Sponsored by Committee" :items="Committees" item-text="FullName" item-value="SessionCommitteeId" outlined hide-details clearable></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterInterimCommitteeSponsored" label="Sponsored by Interim Committee" :items="InterimCommittees" item-text="Name" item-value="InterimCommitteeId" outlined hide-details clearable></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterAgencySponsored" label="Requested by Agency" :items="Agencies" item-text="Agency" item-value="AgencyId" outlined hide-details clearable></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterPrimeSponsor" label="Prime Sponsor" :items="Legislators" item-text="Name" item-value="SessionMemberId" outlined clearable hide-details>
                                <template slot="item" slot-scope="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-avatar v-if="data.item.Picture">
                                            <v-img transition="false" :src="data.item.PictureSmall"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>{{data.item.MemberType | MemberTypeFilter}}</v-list-item-subtitle>
                                            <v-list-item-title>{{data.item.FirstName}} {{data.item.LastName}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-autocomplete v-model="filterSponsor" label="Sponsor" :items="Legislators" item-text="Name" item-value="SessionMemberId" outlined hide-details clearable>
                                <template slot="item" slot-scope="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-avatar v-if="data.item.Picture">
                                            <v-img transition="false" :src="data.item.PictureSmall"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>{{data.item.MemberType | MemberTypeFilter}}</v-list-item-subtitle>
                                            <v-list-item-title>{{data.item.FirstName}} {{data.item.LastName}}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-text-field v-model="filterText" label="Filter by Text" outlined hide-details clearable></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-menu ref="menu1"
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateFormatted"
                                                  label="Filter by Start Date"
                                                  hint="MM/DD/YYYY format"
                                                  persistent-hint
                                                  v-bind="attrs"
                                                  @blur="filterStartDate = parseDate(dateFormatted)"
                                                  outlined
                                                  clearable
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="filterStartDate" no-title @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-menu ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateFormatted1"
                                                  label="Filter by End Date"
                                                  hint="MM/DD/YYYY format"
                                                  persistent-hint
                                                  v-bind="attrs"
                                                  @blur="filterEndDate = parseDate(dateFormatted1)"
                                                  outlined
                                                  clearable
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="filterEndDate" no-title @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" class="pa-1">
                            <v-checkbox v-model="filterEmergencyClause" class="mx-2" label="Emergency Clause"></v-checkbox>

                        </v-col>
                    </template>
                </v-row>
            </v-container>
            <v-row no-gutters>
                <v-col cols="12">
                    <FilterableDataTable :headers="billHeaders"
                                         :items="sortedBills"
                                         :loading="loading"
                                         disable-pagination
                                         hide-default-footer
                                         item-key="ListBillId"
                                         :search="filterText"
                                         class="elevation-1 ma-1">
                        <template v-slot:item.BillSort="{ item }">
                            <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + ' ' + item.BillNumberOnly}}</router-link>
                        </template>
                    </FilterableDataTable>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    import moment from 'moment'
    export default {
        name: 'Custom',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData() {
                myfetch('/api/Bills/Session/' + this.SessionId, { method: 'GET' })
                    .then(r => r.json())
                    .then(r => {
                        EncodeDatetimes(r);

                        r = r.map(x => {
                            x.OtherBillNumber = x.BillNumber;
                            x.BillNumber = x.BillType + " " + x.BillNumberOnly;
                            x.BillSort = x.BillType + ("000000" + x.BillNumberOnly).slice(-6);

                            if (x.Introduced instanceof Date) {
                                x.IntroducedSearch = moment(x.Introduced).format("YYYY-MM-DD MM/DD/YYYY MM-DD-YYYY M/D/YYYY M-D-YYYY");
                            }
                            else {
                                x.IntroducedSearch = null;
                            }

                            return x;
                        });

                        r.sort((x, y) => {
                            if (x.BillSort > y.BillSort)
                                return 1;
                            else if (x.BillSort < y.BillSort)
                                return -1;
                            return 0;
                        });

                        this.bills = r;
                        this.loading = false;
                    });

                myfetch('/api/Keywords?SessionId=' + this.SessionId, { method: 'GET' }).then(r => r.json()).then(
                    r => {
                        r.sort((x, y) => {
                            if (x.Keyword < y.Keyword) return -1;
                            else if (x.Keyword > y.Keyword) return 1;
                            else return 0;
                        });

                        this.Keywords = r;
                    });

                myfetch('/api/SessionCommittees/Session/' + this.SessionId, { method: 'GET' }).then(r => r.json()).then(
                    r => {
                        r = r.filter(x => x.CommitteeType == 'S');

                        r.sort((x, y) => {
                            if (x.FullName < y.FullName) return -1;
                            else if (x.FullName > y.FullName) return 1;
                            else return 0;
                        });

                        this.Committees = r;
                    });

                myfetch("/api/InterimCommittees/Session/" + this.SessionId, { method: 'GET' }).then(r => r.json()).then(
                    r => {

                        r.sort((x, y) => {
                            if (x.Name < y.Name) return -1;
                            else if (x.Name > y.Name) return 1;
                            else return 0;
                        });

                        this.InterimCommittees = r;
                    });

                myfetch('/api/SessionMembers/Session/' + this.SessionId, { method: 'GET' }).then(r => r.json()).then(
                    r => {
                        r.sort((x, y) => {
                            if (x.UniqueName < y.UniqueName) return -1;
                            else if (x.UniqueName > y.UniqueName) return 1;
                            else return 0;
                        });

                        this.Legislators = r
                    });

                myfetch('/api/Agencies/Session/' + this.SessionId, { method: 'GET' })
                    .then(r => r.json())
                    .then(r =>
                        {
                        r.sort((x, y) => {
                            if (x.Agency < y.Agency) return -1;
                            else if (x.Agency > y.Agency) return 1;
                            else return 0;
                        });

                    this.Agencies = r;
                });
                fetch('/api/BillNumbers/BillTypes', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        return res.text().then(r=>Promise.reject(r));
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.BillTypes = EncodeDatetimes(response);

                }).catch(error => {
                    throw new Error(error);
                });

            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },
        data: function () {
            return {
                search: '',
                loading: true,
                rowsPerPageItems: [15, 30, 50],
                pagination: {
                    descending: null,
                    page: 1,
                    rowsPerPage: 15,
                    sortBy: null,
                    totalItems: null
                },
                billHeaders: [

                    {
                        text: 'Bill',
                        align: 'left',
                        sortable: true,
                        value: 'BillSort',
                        width: "15ch",
                        visible: true,
                        Filter: null,
                    },
                    {
                        text: 'Title',
                        align: 'left',
                        sortable: true,
                        value: 'Title',
                        width: "*",
                        visible: true,
                        Filter: null,
                    },

                ],
                bills: [],
                filterAgencySponsored: null,
                filterKeyword: null,
                filterBillType: null,
                filterCommittee: null,
                filterCommitteeSponsored: null,
                filterInterimCommitteeSponsored: null,
                filterSponsor: null,
                filterText: null,
                filterTextResults: null,
                filterPrimeSponsor: null,
                filterEmergencyClause: null,
                filterStartDate: null,
                filterEndDate: null,
                sortStack: [],
                Agencies: [],
                existingBills: [],
                Keywords: [],
                BillTypes: [],
                Committees: [],
                InterimCommittees: [],
                Legislators: [],
                ListName: null,
                AddCriteria: false,
                filterDialog: false,
                dateFormatted: null,
                menu1: false,
                menu2: false,
                dateFormatted1: null,
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            filterStartDate(val) {
                this.dateFormatted = this.formatDate(this.filterStartDate)
            },
            filterEndDate(val) {
                this.dateFormatted1 = this.formatDate(this.filterEndDate)
            },
        },
        computed: {
            computedDateFormatted() {
                return this.formatDate(this.filterStartDate)
            },
            sortedBills: function () {
                let filters = this.billHeaders.filter((x) => {
                    if (x.Filter != null && x.Filter.trim() != null && x.Filter.trim().length > 0) {
                        return true;
                    }
                    return false;
                });

                let resultRows = this.bills.filter(x => !this.existingBills.includes(x.BillId));

                if (this.filterBillType != null) {
                    resultRows = resultRows.filter(x => x.BillType.includes(this.filterBillType));
                }

                if (this.filterKeyword != null) {
                    resultRows = resultRows.filter(x => x.Keywords.includes(this.filterKeyword));
                }

                if (this.filterCommittee != null) {
                    resultRows = resultRows.filter(x => x.Committees.includes(this.filterCommittee));
                }

                if (this.filterSponsor != null) {
                    resultRows = resultRows.filter(x => x.Sponsors.includes(this.filterSponsor));
                }

                if (this.filterPrimeSponsor != null) {
                    resultRows = resultRows.filter(x => x.PrimeSponsors.includes(this.filterPrimeSponsor));
                }

                if (this.filterCommitteeSponsored != null) {
                    resultRows = resultRows.filter(x => x.SessionCommitteeId == this.filterCommitteeSponsored);
                }

                if (this.filterInterimCommitteeSponsored != null) {
                    resultRows = resultRows.filter(x => x.InterimCommitteeId == this.filterInterimCommitteeSponsored);
                }

                if (this.filterAgencySponsored != null) {
                    resultRows = resultRows.filter(x => x.AgencyId == this.filterAgencySponsored);
                }

                if (this.filterTextResults != null) {
                    resultRows = resultRows.filter(x => this.filterTextResults.includes(x.BillId));
                }

                if (this.filterEmergencyClause == true) {
                    resultRows = resultRows.filter(x => x.EmergencyClause == true);
                }

                if (this.filterStartDate != null) {
                    resultRows = resultRows.filter(x => x.Introduced >= new Date(this.filterStartDate));
                }

                if (this.filterEndDate != null) {
                    resultRows = resultRows.filter(x => x.Introduced <= new Date(this.filterEndDate));
                }


                resultRows = resultRows.filter((x) => {
                    for (let i in filters) {
                        let searchVals = filters[i].Filter.trim().toLowerCase().split(' ');
                        let searchString = (x[filters[i].value] || "").toString().toLowerCase();

                        if (isValidDate(searchString))
                            searchString = (x[filters[i].value] || "").toLocaleString().toLowerCase();

                        if (!searchVals.every(s => searchString.includes(s)))
                            return false;
                    }
                    return true;
                });

                let stack = this.sortStack;
                resultRows.sort(function (a, b) {
                    for (let i in stack) {
                        let key = stack[i].value;
                        let desc = stack[i].descending;
                        if (a[key] > b[key]) return desc ? -1 : 1;
                        else if (a[key] < b[key]) return desc ? 1 : -1;
                        else continue;
                    }
                    return 0;
                });
                return resultRows;
            },
            Title: function () {
                let title = 'Custom Bill Search';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>