<template>
    <v-card style="float:left; min-height: 50px;" class="d-print-none">
        <v-navigation-drawer floating permanent class="hidden-sm-and-down d-print-none">
            <v-list dense nav>
                <v-list-item v-for="item in items" :key="item.id" :to="item.url" link style="text-decoration:none !important">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <div class="hidden-md-and-up d-print-none">
            <v-row>
                <v-col cols="12">
                    <v-spacer></v-spacer>
                    <div class="hidden-md-and-up justify-end" style="top: 5px;position:absolute">
                        <v-btn @click.stop="drawer1 = !drawer1">
                            <v-icon>{{dotsvertical}}</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-navigation-drawer v-model="drawer1" right fixed clase="d-print-none" temporary>
            <v-list dense nav>
                <v-list-item v-for="item in items" :key="item.id" :to="item.url" link style="text-decoration:none !important">
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                            {{item.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>
<script>
    import { mdiDotsVertical } from '@mdi/js';
    export default {
        name: 'RedistrictingMenu',
        components: {

        },
        methods: {

        },
        data: function () {
            return {
                dotsvertical: mdiDotsVertical,
                items: [
                    { id: 1, url: '/Redistricting/Home', text: 'Redistricting' },
                    { id: 2, url: '/Redistricting/Laws', text: 'Redistricting Laws' },
                    { id: 3, url: '/Redistricting/CourtCases', text: 'Redistricting Court Cases' },
                    { id: 4, url: '/Legislators/Find', text: 'Current Maps' },
                    { id: 5, url: '/Redistricting/Faq', text: 'FAQs' },
                    { id: 6, url: '/Redistricting/Resources', text: 'Resources and Links' }],
                drawer1: false,
            }
        },
        mounted: function () {
        },
        watch: {

        },
        computed: {

        }
    };
</script>
<style></style>