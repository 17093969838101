var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"md":"2","sm":"12","cols":"12"}},[(_vm.SessionId)?_c('Sidebar',{attrs:{"SessionId":_vm.SessionId}}):_vm._e()],1),_c('v-col',{attrs:{"md":"10","sm":"12"}},[(_vm.Session && _vm.Session.Year)?_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.Session.YearString)+" Withdrawn Report")]):_vm._e(),_c('div',[_vm._v(" The following bills have been withdrawn by the prime sponsor in accordance with Joint Rule 6B-1.1. ")]),_c('FilterableDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"BillNumber","group-by":"BillType","multi-sort":"","disable-pagination":"","hide-default-footer":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var group = ref.group;
var groupby = ref.groupby;
return [_c('td',{attrs:{"colspan":"2"}},[_vm._v(" Bill Type: "+_vm._s(_vm.LongBillName(group))+" "),(isOpen)?_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(_vm.minus))]):_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(_vm.plus))])],1)]}},{key:"item.BillNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Session/Bill/' + item.BillId}},[_vm._v(_vm._s(item.BillType + ' ' + item.BillNumber))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }