<template>
    <v-container>
        <v-card flat>
            <v-card-text>
                <div>
                    <div>
                        The South Dakota Legislative Research Council takes reasonable precautions to assure the accuracy and reliability of the data contained on this web site. No warranty, guarantee, or promise, express or implied, is made.
                    </div>
                    <div>
                        The internet version of the South Dakota Codified Laws is made available as a public service. Online resources may be vulnerable to unauthorized changes or redirection. Any person who relies on any information obtained from this web site does so at that person’s own risk. In the case of inconsistencies resulting from omissions or other errors, the current printed version of the Code will prevail.
                    </div>
                    <div>
                        For matters affecting legal or other rights, or to confirm content, please refer to the current printed version of the Code or consult an attorney. The staff of the Legislative Research Council may not provide legal advice.
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: 'Disclaimer',
        components: {

        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'Disclaimer';
                return title;
            },
            Description: function () {
                return 'The South Dakota Legislative Research Council takes reasonable precautions to assure the accuracy and reliability of the data contained on this web site. No warranty, guarantee, or promise, express or implied, is made.';
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>