var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pr-4"},[(_vm.currentRoute.includes('/Interim'))?_c('v-card-title',[_vm._v(" Archived Interims ")]):_c('v-card-title',[_vm._v(" Archived Sessions ")]),_c('v-card-text',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.currentRoute.includes('/Interim'))?_c('div',[_c('GoogleSearch')],1):_c('div',[_c('GoogleSearch')],1)])],1),_c('FilterableDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.filteredSessions,"item-key":"Year","sort-by":['YearString'],"sort-desc":true,"disable-pagination":"","hide-default-footer":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.Bills",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Session/Bills/' + item.SessionId}},[_vm._v("Bills")])]}},{key:"item.Committees",fn:function(ref){
var item = ref.item;
return [(_vm.IsSession)?_c('router-link',{attrs:{"to":'/Session/Committees/' + item.SessionId}},[_vm._v("Committees")]):(!_vm.IsSession)?_c('router-link',{attrs:{"to":'/Interim/Committees/' + item.SessionId}},[_vm._v("Committees")]):_vm._e()]}},{key:"item.ConferenceCommittees",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Session/ConferenceCommittees/' +item.SessionId}},[_vm._v("Conference Committees")])]}},{key:"item.General",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Session/General/' +item.SessionId}},[_vm._v("General")])]}},{key:"item.FinalReport",fn:function(ref){
var item = ref.item;
return [(_vm.getReportID(item.SessionId) != null)?_c('div',[_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value +'api/Documents/AnnualReport/' + _vm.getReportID(item.SessionId) + '.pdf'}},[_vm._v("Final Report")])]):_vm._e()]}},{key:"item.Legislators",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Legislators/Listing/' +item.SessionId}},[_vm._v("Legislators")])]}},{key:"item.Session_Laws",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/Statutes/Session_Laws/' +item.SessionId}},[_vm._v("Session Laws")])]}}])}),(_vm.currentRoute.includes('/Session') || _vm.currentRoute.includes('/Statute') )?_c('div',[_c('a',{attrs:{"rel":"noopener","href":"http://sdsdl-montage.auto-graphics.com/#/customizeListView?listId=CustomizeEntityLists_97"}},[_vm._v("State Archives: Session Laws of South Dakota, beginning with 1890 to the present.")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }