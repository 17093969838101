<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12" cols="12">
            <div class="headline">LEGISLATIVE RESEARCH COUNCIL</div>
            <div>The South Dakota Legislative Research Council (LRC) was established by action of the 1951 Legislature.</div>
            <br />
            <div>
                By statute, the LRC consists of all members of the Legislature. It is the function of the LRC to consider legislative policies between sessions. The council is directed by a fifteen-member executive board, consisting of eight representatives and seven senators. The executive board employs the Director and the Code Counsel.
            </div>
            <br />
            <div class="headline">
                Mission
            </div>
            <br />
            <div>
                The mission of the LRC is to provide to the members of the Legislature legal analysis, fiscal analysis, and advice in addition to research, drafting, and budget services in a professional, confidential, and nonpartisan manner.
            </div>
            <br />
            <div class="headline">
                Services We Provide to Legislators
            </div>
            <br />
            <div>
                The staff of the LRC provides the following services to Legislators: bill and amendment drafting; research for the development of legislation; legal and fiscal research and analysis; federal and state case law research; legal and fiscal services for legislative committees; resolution drafting; and requests for attorney general opinions. These services will be provided on request to legislators and as part of legislative meetings including partisan caucuses.
            </div>
            <br />
            <div>
                The staff of the LRC will not provide the following services: policy advocacy; writing speeches; writing partisan press releases; compiling voting records; providing legal services to constituents or the public; and issuing official legal opinions.
            </div>
        </v-col>
    </v-row>
</template>

<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Default',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,
            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'About';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>
