<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12" v-if="loading" class="hidden-md-and-down">
            <template>
                <v-sheet>
                    <v-skeleton-loader class="mx-auto"
                                       max-width="250"
                                       type="list-item-three-line,list-item-three-line"></v-skeleton-loader>
                </v-sheet>
            </template>
        </v-col>
        <v-col md="2" sm="12" cols="12" v-if="!HideSideBar && !loading">
            <Sidebar v-if="Bill && Bill.SessionId" :SessionId="Bill.SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <div class="headline" v-if="Bill && Bill.BillId">
                <div style="display:inline">
                    {{Bill.BillTypeFull}} {{Bill.BillNumber}}
                </div>
                <div style="display:inline;float:right" v-if="!HideSideBar && Bill && Bill.SessionId">
                    <v-btn :to="'/Session/Bills/' + Bill.SessionId">Back to Full List</v-btn>
                </div>
            </div>
            <v-row v-if="error">
                <v-col cols="12">
                    <v-alert type="error">
                        {{error}} - An error occurred; please try again later.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-if="!HideSideBar">
                <v-col cols="12"
                       sm="6"
                       class="py-2" v-if="Bill && Bill.BillId">
                    <v-btn depressed x-small color="orange" style="vertical-align:top;" rel="noopener" :href="'/api/Bills/RSS/' + Bill.BillId">
                        <span class="white--text">RSS</span><v-icon color="white" right>{{rss}}</v-icon>
                    </v-btn>
                    <v-btn depressed x-small color="#007bff" style="vertical-align:top;" rel="noopener" :href="'https://www.facebook.com/sharer/sharer.php?u='+FullRoute">
                        <v-icon x-small color="white" left>{{facebook}}</v-icon><span class="white--text">Share</span>
                    </v-btn>
                    <v-btn depressed x-small color="#1b95e0" style="vertical-align:top;" rel="noopener" :href="'https://twitter.com/intent/tweet?text=' + Bill.BillType + ' ' + Bill.BillNumber + '&tw_p=tweetbutton&url='+ FullRoute">
                        <v-icon x-small color="white" left>{{twitter}}</v-icon><span class="white--text">Tweet</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="Bill && Bill.BillId">
                <v-col cols="12"
                       sm="2"
                       class="py-2">
                    <b>Title:</b>
                </v-col>
                <v-col cols="12"
                       sm="10"
                       class="py-2">
                    <div>{{Bill.Title}}</div>
                </v-col>
            </v-row>
            <v-row v-if="Bill && Bill.BillId">
                <v-col cols="12"
                       sm="2"
                       class="py-2">
                    <b>Sponsors:</b>
                </v-col>
                <v-col cols="12"
                       sm="10"
                       class="py-2">
                    <div v-html="Bill.BillCommitteeSponsor"></div>

                </v-col>
            </v-row>
            <v-row v-if="Bill && Bill.BillId">
                <v-col cols="12"
                       sm="2"
                       class="py-2">
                    <b>Subjects:</b>
                </v-col>
                <v-col cols="12"
                       sm="10"
                       class="py-2">
                    <div v-if="Bill.Keywords" v-for="(r,i) in Bill.Keywords" style="display:inline"><router-link :to="'/Session/Subject/' + r.KeywordId">{{r.Keyword}}</router-link><span v-if="i < (Bill.Keywords || []).length - 1">, </span></div>
                </v-col>
            </v-row>
            <v-row v-if="FiscalNotes && FiscalNotes != null && FiscalNotes.length > 0">
                <v-col cols="12"
                       sm="2"
                       class="py-2"
                       style="display:inline">
                    <b>Fiscal Notes:</b>
                </v-col>
                <v-col cols="12"
                       style="display:inline"
                       sm="10"
                       class="py-2">
                    <div v-for="(r,i) in FiscalNotes" style="display:inline"><a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + r.DocumentId + '.pdf'">{{r.BillType + '&nbsp;' + r.BillNumber + '&nbsp;'}}<span v-if="r.SixC">{{'(Per' + '&nbsp;' + 'JR' + '&nbsp;'+ '6C‑4)'}}</span><span v-else>{{'(' +r.Version + ')'}}</span></a><span v-if="i<FiscalNotes.length-1">, </span></div>
                </v-col>
            </v-row>
            <v-row v-if="PrisonJail && PrisonJail != null && PrisonJail.length > 0">
                <v-col cols="12"
                       sm="2"
                       class="py-2"
                       style="display:inline">
                    <b>Prison/Jail Population Cost Estimates:</b>
                </v-col>
                <v-col cols="12"
                       style="display:inline"
                       sm="10"
                       class="py-2">
                    <div v-for="(r,i) in PrisonJail" style="display:inline"><a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + r.DocumentId + '.pdf'">{{r.BillType + '&nbsp;' + r.BillNumber + '&nbsp;'}}<span v-if="r.SixC">{{'(Per' + '&nbsp;' + 'JR' + '&nbsp;'+ '6C‑4)'}}</span><span v-else>{{'(' +r.Version + ')'}}</span></a><span v-if="i<PrisonJail.length-1">, </span></div>
                </v-col>
            </v-row>
            <v-row v-if="ConferenceCommittees && ConferenceCommittees.length > 0">
                <v-col cols="12"
                       sm="5"
                       md="3"
                       lg="2"
                       class="py-2">
                    <b>Conference&nbsp;Committee<span v-if="ConferenceCommittees.length > 1">s</span>:</b>
                </v-col>
                <v-col cols="12"
                       sm="7"
                       md="9"
                       lg="10"
                       class="py-2" v-if="ConferenceCommittees.some(x => x.Agenda)">
                    <v-row v-for="CC in ConferenceCommittees" no-gutters>
                        <v-col cols="12" sm="12" md="12" v-if="CC.Agenda">
                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + CC.Agenda.DocumentId + '.pdf'">{{CC.Name}} {{CC.MeetingTime | FormatDatesLongDay}} Meeting Notice</a>
                            <span v-if="CC.Minutes">
                                - <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + CC.Minutes.DocumentId + '.pdf'">Minutes</a>
                            </span>
                            <span v-if="CC.Report">
                                - <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + CC.Report.DocumentId + '.pdf'">Report</a>
                            </span>
                        </v-col>
                        <!--<v-col cols="12" sm="4" md="2" v-if="CC.Disposition && CC.Disposition.SenateAdoptionText">
                            Senate: {{CC.Disposition.SenateAdoptionText}}
                        </v-col>
                        <v-col cols="12" sm="4" md="2" v-if="CC.Disposition && CC.Disposition.HouseAdoptionText">
                            House: {{CC.Disposition.HouseAdoptionText}}
                        </v-col>-->
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="!loading && !HideSideBar">
                <v-col cols="12" v-if="TrackingLists && TrackingLists.length > 0 && !NotLoggedIn" class="py-2">
                    <div v-if="BillAlreadyFollowed">
                        You already follow this bill.
                    </div>
                    <div v-else>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-autocomplete :items="TrackingLists"
                                                v-model="SelectedList"
                                                label="Add this bill to my tracking list"
                                                item-text="ListName"
                                                item-value="BillListId"></v-autocomplete>
                            </v-col>
                            <v-btn @click="AddBillToList()">Add</v-btn>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" v-if="NotLoggedIn">
                    Want to add this bill to one of your tracking lists? <a rel="noopener" :href="$MyLRCUrl.value">Logon to MyLRC</a>
                </v-col>
            </v-row>
            <div>
                <v-card flat>
                    <v-card-text>
                        <FilterableDataTable :headers="actionHeaders"
                                             hide-default-footer
                                             disable-pagination
                                             :items="ActionLog">
                            <template v-slot:item.ActionDate="{ item }">
                                <span v-if="!item.DocumentId">{{DatePretty(item.ActionDate)}}</span>
                                <span v-else-if="item.JournalPage != null"><a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf#page=' + (item.JournalPage - item.StartPageNumber)" target="_blank">{{DatePretty(item.ActionDate)}}</a></span>
                                <span v-else><a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf#page=1'" target="_blank">{{DatePretty(item.ActionDate)}}</a></span>
                            </template>
                            <template v-slot:item.StatusText="{ item }">
                                <span v-if="item.StatusText == null">{{item.Description}}</span>
                                <span v-if="(item.StatusText =='Do Pass' || item.StatusText =='Tabled' || item.ShowCommitteeName) && item.ActionCommittee">
                                    <router-link :to="'/Session/Committee/'+item.ActionCommittee.ActionCommitteeId+'/Detail'">
                                        {{item.ActionCommittee.FullName}}
                                    </router-link>
                                </span>
                                <span v-else-if="(item.StatusText =='Do Pass' || item.StatusText =='Tabled' || item.ShowCommitteeName) && item.ConferenceCommittee">
                                    <router-link :to="'/Session/ConferenceCommittees/'+Bill.SessionId">
                                        Conference Committee {{item.ConferenceCommittee.Name}}
                                    </router-link>
                                </span>
                                <span v-if="item.StatusText == 'Signed by the Governor' || item.StatusText =='Delivered to the Governor'">
                                    {{item.StatusText}} on {{item.ActionDate2 | FormatDatesLongDay}}
                                    <span v-if="item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.Body && item.ActionCommittee.FullBody">
                                        {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                    </span>
                                </span>
                                <span v-else-if="item.ShowPassed ==true|| item.ShowFailed ==true">
                                    {{item.StatusText}}<span v-if="item.AssignedCommittee && item.ShowAssignedCommittee"><router-link :to="'/Session/Committee/'+item.AssignedCommittee.AssignedCommitteeId+'/Detail'"> {{item.AssignedCommittee.FullName}}</router-link></span><span v-if="item.Result == 'P'">, Passed</span><span v-else-if="item.Result == 'F'">, Failed</span><span v-else-if="item.Result == 'D'">, Failed for lack of a second</span><span v-else-if="item.Result == 'W'">, Withdrawn</span><span v-if="item.StatusText != 'Certified uncontested, placed on consent' && item.Vote">, </span>
                                    <router-link :to="'/Session/Vote/' + item.Vote.VoteId" v-if="item.StatusText != 'Certified uncontested, placed on consent' && item.Vote">YEAS&nbsp;{{item.Vote.Yeas}}, NAYS&nbsp;{{item.Vote.Nays}}.</router-link>
                                    <span v-if=" item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.FullBody">
                                        {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                    </span>
                                </span>
                                <span v-else>
                                    <span v-if="item.StatusText == 'Vetoed for Style and Form' || item.StatusText == 'Vetoed by the Governor' || item.StatusText == 'Line Item Veto'">
                                        <router-link :to="'/Session/BillReports/VetoedByGovernor/' + ((Bill || {}).SessionId || 44)">
                                            {{item.StatusText}}
                                        </router-link>
                                    </span>
                                    <span v-else>{{item.StatusText}}</span>
                                    <span v-if="item.AssignedCommittee && item.ShowAssignedCommittee">
                                        <router-link :to="'/Session/Committee/'+item.AssignedCommittee.AssignedCommitteeId+'/Detail'"> {{item.AssignedCommittee.FullName}}</router-link>
                                    </span>
                                    <span v-if="item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.FullBody">
                                        {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                    </span>
                                </span>
                                <span>
                                    <span v-if="item.Amendment">
                                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Amendment/' + item.Amendment.DocumentId + '.pdf'"> Amendment {{item.Amendment.Filename}}</a>
                                    </span>
                                </span>
                            </template>
                            <template v-slot:item.AudioSegment="{ item }">
                                <span v-if="HasAudio(item)">
                                    <v-btn icon color="primary" rel="noopener" :href="GetAudioUrl(item)" aria-label="SDPB Audio">
                                        <v-icon>{{mdiVolumeHigh}}</v-icon>
                                    </v-btn>
                                </span>
                                <span v-else><b>N/A</b></span>
                            </template>
                            <template v-slot:item.Location="{ item }">
                                <span v-if="HasAudio(item)">
                                    {{GetTime(item)}}
                                </span>
                            </template>
                        </FilterableDataTable>
                    </v-card-text>
                </v-card>
                <br />
                <v-card flat v-if="!HideSideBar">
                    <v-card-title class="justify-center">
                        Bill Text Versions
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="computedversionHeaders"
                                      hide-default-footer
                                      disable-pagination
                                      :items="Versions">
                            <template v-slot:item.DocumentDate="{ item }">
                                <span v-if="item.DocumentDate">
                                    {{DatePretty(item.DocumentDate)}}
                                </span>
                                <span v-else>
                                    Document Date Missing
                                </span>
                            </template>
                            <template v-slot:item.HTML="{ item }">
                                <router-link :to="'/Session/Bill/'+ BillId + '/' + item.DocumentId">{{item.BillVersion}}</router-link>
                            </template>
                            <template v-slot:item.PDF="{ item }">
                                <a rel="noopener" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.pdf'">{{item.BillVersion}}</a>
                            </template>
                            <template v-slot:item.MyLRC="{ item }">
                                <div v-if="HasNote(item.DocumentId)">
                                    <v-btn rel="noopener" :href="$MyLRCUrl.value +'Main#/Document/' + item.DocumentId">
                                        <v-icon>{{edit}}</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn rel="noopener" :href="$MyLRCUrl.value +'Main#/Document/' + item.DocumentId">
                                        <v-icon>{{add}}</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <br />
                <v-card flat v-if="!HideSideBar">
                    <v-card-title class="justify-center">
                        Amendments
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="amendmentHeaders"
                                      hide-default-footer
                                      disable-pagination
                                      :items="AmendmentsActive">
                            <template v-slot:item.SessionMemberId="{ item }">
                                <router-link v-if="item.SessionMemberId" :to="'/Legislators/Profile/'+ item.SessionMemberId">{{item.LastName}}</router-link>
                            </template>
                            <template v-slot:item.InContext="{ item }">
                                <a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.pdf'">{{item.Filename}} <sub>(PDF)</sub></a>&nbsp;
                                <sub><a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.html'">(HTML)</a></sub>
                            </template>
                            <template v-slot:item.Instructions="{ item }">
                                <a rel="noopener" target="_blank" :href="$MyLRCUrl.value + 'api/Documents/' + item.AmendmentInstructionsDocumentId + '.pdf'">{{item.Filename}}<sub>(PDF)</sub></a>&nbsp;
                                <sub><a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.AmendmentInstructionsDocumentId + '.html'">(HTML)</a></sub>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card flat v-if="!HideSideBar">
                    <v-card-title class="justify-center">
                        Previous Amendments
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="amendmentHeaders"
                                      hide-default-footer
                                      disable-pagination
                                      :items="AmendmentsPrevious">
                            <template v-slot:item.SessionMemberId="{ item }">
                                <router-link v-if="item.SessionMemberId" :to="'/Legislators/Profile/'+ item.SessionMemberId">{{item.LastName}}</router-link>
                            </template>
                            <template v-slot:item.InContext="{ item }">
                                <a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.pdf'">{{item.Filename}} <sub>(PDF)</sub></a>&nbsp;
                                <sub><a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.DocumentId + '.html'">(HTML)</a></sub>
                            </template>
                            <template v-slot:item.Instructions="{ item }">
                                <a rel="noopener" target="_blank" :href="$MyLRCUrl.value + 'api/Documents/' + item.AmendmentInstructionsDocumentId + '.pdf'">{{item.Filename}}<sub>(PDF)</sub></a>&nbsp;
                                <sub><a rel="noopener" target="_blank" :href="$MyLRCUrl.value +'api/Documents/' + item.AmendmentInstructionsDocumentId + '.html'">(HTML)</a></sub>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    import moment from 'moment';
    import { mdiRss } from '@mdi/js';
    import { mdiFacebook } from '@mdi/js';
    import { mdiTwitter } from '@mdi/js';
    import { mdiPlus } from '@mdi/js';
    import { mdiPencil, mdiVolumeHigh } from '@mdi/js';
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Bill',
        props: ['BillId', 'HideSideBar'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                this.error = null;

                fetch('/api/Bills/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                        {
                            return res.json();
                        }
                        else if (res.status === 404)
                        {
                            if (this.Session && this.Session.SessionId)
                            {
                                this.$router.push('/Session/Bills/' + this.Session.SessionId);
                            }
                            else if (this.$Session && this.$Session.SessionId)
                            {
                                this.$router.push('/Session/Bills/' + this.$Session.SessionId);
                            }
                            else
                            {
                                this.error = res.status;
                            }
                        }
                        else
                        {
                            this.error = res.status;
                        }

                        return Promise.reject("error");
                    })
                    .then(r =>
                    {
                        this.Bill = EncodeDatetimes(r);
                    })
                    .then(_ =>
                    {
                        return fetch('/api/Sessions/' + this.Bill.SessionId,
                            {
                                method: "GET",
                                credentials: "include",
                                headers: { "Content-Type": "application/json" },
                            });
                    })
                    .then(r =>
                    {
                        if (r.ok)
                            return r.json();
                        else
                            return Promise.reject(r.status);
                    })
                    .then(response =>
                    {
                        this.Session = EncodeDatetimes(response);
                    })
                    .catch(error =>
                    {

                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });

                fetch('/api/Bills/ActionLog/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(r => this.ActionLog = EncodeDate(r));

                fetch('/api/Bills/Versions/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(x =>
                    {
                        this.Versions = EncodeDate(x);

                        if (this.LoggedIn)
                            this.LoadMylrc();
                        else
                            this.NotLoggedIn = true;
                    });

                fetch('/api/Bills/Amendments/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json().then(x => { this.Amendments = EncodeDate(x); });
                        else
                            this.Amendments = [];
                    });

                fetch('/api/Bills/FiscalNotes/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json().then(x => { this.FiscalNotes = EncodeDate(x); });
                        else
                            this.FiscalNotes = [];
                    });

                fetch('/api/Bills/PrisonJail/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" }
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json().then(x => { this.PrisonJail = EncodeDate(x); });
                        else
                            this.PrisonJail = [];
                    });

                fetch('/api/ConferenceCommittees/Bill/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" }
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json().then(x => { this.ConferenceCommittees = EncodeDate(x); });
                        else
                            this.ConferenceCommittees = [];
                    });


                fetch('/api/Bills/Audio/' + this.BillId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    }).then(res =>
                    {
                        if (res.ok)
                            return res.json().then(r => this.Audio = EncodeDate(r));
                        else
                            this.Audio = [];
                    });
            },
            LoadMylrc()
            {
                fetch('/api/MyLRC/BillLists/' + this.Bill.SessionId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        this.loading = false;
                        if (res.ok)
                        {
                            return res.json();
                        }
                        else if (res.status === 403)
                        {
                            this.NotLoggedIn = true;
                            return null;
                        }
                        else
                        {
                            return null;
                        }
                    })
                    .then(response =>
                    {
                        if (response != null)
                        {
                            this.TrackingLists = EncodeDatetimes(response);
                        }
                        this.TrackingLists.forEach(x =>
                        {
                            if (x.ListBills && x.ListBills.filter(y => y.BillId == this.Bill.BillId).length > 0)
                            {
                                this.BillAlreadyFollowed = true;
                            }
                        });

                    })
                    .catch(error =>
                    {
                    });

                var query = '';
                this.Notes = [];

                if (this.Versions && this.Versions.length > 0)
                {
                    this.Versions.forEach(x =>
                    {
                        query += '&DocumentIds=' + x.DocumentId;
                    });

                    query = query.substring(1, query.length);

                    fetch('/api/MyLRC/Notes?' + query,
                        {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        })
                        .then(res =>
                        {
                            this.loading = false;

                            if (res.ok)
                                return res.json();
                            else
                                return null;
                        })
                        .then(response =>
                        {
                            if (response != null)
                            {
                                this.Notes = EncodeDatetimes(response);
                            }

                        })
                        .catch(error =>
                        {
                        });
                }
            },
            IsFuture(Date)
            {
                if (Date != undefined)
                    return moment(Date).isAfter();
                else
                    return null;
            },
            DatePretty(Date)
            {
                if (Date != undefined)
                    return moment(Date).format('MM/DD/YYYY');
                else
                    return null;
            },
            DateMonthPretty(Date)
            {
                if (Date != undefined)
                    return moment(Date).format('MMMM DD, YYYY');
                else
                    return null;
            },
            AddBillToList()
            {
                fetch('/api/MyLRC/BillLists/' + this.SelectedList + "?BillId=" + this.Bill.BillId,
                    {
                        method: "POST",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return this.LoadMylrc();
                        else
                            return null;
                    })
                    .catch(error =>
                    {
                    });
            },
            HasNote(docid)
            {
                if (this.Notes.some(x => x.DocumentId == docid))
                {
                    return true;
                } else
                {
                    return false;
                }
            },
            HasAudio(item)
            {
                if (this.Audio && this.Audio.find(x => moment(x.MeetingDate).format('MMMM DD, YYYY') == moment(item.ActionDate).format('MMMM DD, YYYY')))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            GetAudioUrl(item)
            {
                if (this.Audio != null)
                {
                    return this.Audio.find(x => moment(x.MeetingDate).format('MMMM DD, YYYY') == moment(item.ActionDate).format('MMMM DD, YYYY')).FullUrl;
                }
                return null;
            },
            GetTime(item)
            {
                if (this.Audio != null)
                {
                    let time = this.Audio.find(x => moment(x.MeetingDate).format('MMMM DD, YYYY') == moment(item.ActionDate).format('MMMM DD, YYYY')).StartTime;
                    if (time && time == '00:00:00.0')
                    {
                        return null;
                    }
                    return time;
                }
            },
        },
        data: function ()
        {
            return {
                loading: false,
                error: null,
                rss: mdiRss,
                mdiVolumeHigh,
                facebook: mdiFacebook,
                twitter: mdiTwitter,
                add: mdiPlus,
                edit: mdiPencil,
                BillAlreadyFollowed: false,
                NotLoggedIn: true,
                SelectedList: null,
                FullRoute: encodeURIComponent(window.location.href),
                Notes: [],
                TrackingLists: [
                    {
                        BillListId: null,
                        ListName: null,
                        SessionId: null,
                        ListBills: [
                            {
                                BillId: null,
                            }
                        ]
                    }
                ],
                ActionLog: [],
                Versions: [],
                Amendments: [],
                ConferenceCommittees: [],
                FiscalNotes: [],
                PrisonJail: [],
                Audio: [],
                Bill: {
                },
                Session: {},
                versionHeaders: [
                    { text: 'Date', value: 'DocumentDate' },
                    { text: 'HTML Format', value: 'HTML', },
                    { text: 'PDF Format', value: 'PDF', },
                    { text: 'Notes', value: 'MyLRC', }
                ],
                amendmentHeaders: [
                    { text: 'Requested By', value: 'SessionMemberId', width:'30ch'},
                    { text: 'Status', value: 'Result', width:'25ch'},
                    { text: 'In Context', value: 'InContext',  width:'30ch'},
                    { text: 'Instructions', value: 'Instructions', width: '30ch' },
                    { text: 'Version of Bill', value: 'BillVersion', width:'*'},
                ],
                actionHeaders: [
                    { text: 'Date', value: 'ActionDate', filterable: false, width: '15ch', sortable: false },
                    { text: 'Action', value: 'StatusText', filterable: false, width: '*', sortable: false },
                    { text: 'Audio', value: 'AudioSegment', filterable: false, width: '20ch', align: 'center', sortable: false },
                    { text: 'Location', value: 'Location', filterable: false, width: '20ch', sortable: false },
                ],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            BillId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            AmendmentsActive()
            {
                return this.Amendments.filter(x => x.Active === true);
            },
            AmendmentsPrevious()
            {
                return this.Amendments.filter(x => x.Active === false);
            },
            Representatives: function ()
            {
                if (this.Bill && this.Bill.BillSponsor != undefined)
                {
                    return this.Bill.BillSponsor.filter(x => x.MemberType == 'H');
                } else
                {
                    return [];
                }
            },
            Senators: function ()
            {
                if (this.Bill && this.Bill.BillSponsor != undefined)
                {
                    return this.Bill.BillSponsor.filter(x => x.MemberType == 'S');
                } else
                {
                    return [];
                }
            },
            LoggedIn: function ()
            {
                return this.$LoggedIn;
            },
            computedversionHeaders: function ()
            {
                if (this.NotLoggedIn)
                {
                    return this.versionHeaders.filter(x => x.value != 'MyLRC');
                } else
                {
                    return this.versionHeaders;
                }
            },
            Title: function ()
            {
                let title = 'Loading Bill...';
                if (this.Session && this.Session.Year && this.Bill && this.Bill.BillTypeFull && this.Bill.BillNumber)
                {
                    title = this.Session.Year + " " + this.Bill.BillTypeFull + " " + this.Bill.BillNumber;
                }
                else if (this.Bill && this.Bill.BillTypeFull && this.Bill.BillNumber)
                {
                    title = this.Bill.BillTypeFull + " " + this.Bill.BillNumber;
                }
                return title;
            },
            Description: function ()
            {
                if (this.Bill && this.Bill.Title)
                {
                    return this.Bill.Title;
                }
                return this.Title;
            },
            BillType: function ()
            {
                if (this.Bill && this.Bill.BillTypeFull)
                {
                    return this.Bill.BillTypeFull;
                }
                return 'Bill';
            },
            datePublished: function ()
            {
                if (this.Versions && this.Versions.length > 0)
                {
                    return moment(this.Versions[0].DocumentDate).format();
                }
                else if (this.ActionLog && this.ActionLog.length > 0)
                {
                    return moment(this.ActionLog[0].ActionDate).format();
                }
                return null;
            },
            dateModified: function ()
            {
                if (this.Versions && this.Versions.length > 0)
                {
                    return moment(this.Versions[this.Versions.length - 1].DocumentDate).format();
                }
                else if (this.ActionLog && this.ActionLog.length > 0)
                {
                    return moment(this.ActionLog[this.ActionLog.length - 1].ActionDate).format();
                }
                return null;
            },

        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': this.Title,
                            'description': this.Description,
                            'legislationType': this.BillType,
                            'inLanguage': 'English',
                            'datePublished': this.datePublished,
                            'dateModified': this.dateModified,
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] },
                    { vmid: 'alternate', rel: 'alternate', type: 'application/rss+xml', href: window.location.origin + '/api/Bills/RSS/' + this.BillId }
                ]
            };
        }
    };
</script>
<style></style>