<template>
    <v-card>
        <div class="headline">
            Archived Hearing Documents
        </div>
        <v-card-text>
            <v-container>
                <v-layout row wrap>

                    <v-flex xs12 md6>
                        <v-text-field label="Text Search"
                                      placeholder="Google Custom Search">
                            <template v-slot:append>
                                <v-icon>{{searchIcon}}</v-icon>
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                        <v-text-field label="Quick Find"
                                      :placeholder="placeholder">
                            <template v-slot:append>
                                <v-icon>{{searchIcon}}</v-icon>
                            </template>
                        </v-text-field>
                    </v-flex>

                </v-layout>

                <v-layout row wrap>
                    <v-flex style="display:inline">
                        <v-list>
                            <v-list-item v-for="y in Years" rel="noopener"
                                         :href="y.url">
                                <v-list-item-content>
                                    {{y.name}}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mdiMagnify } from '@mdi/js';
    export default {
        name: 'Archived',
        components: {

        },
        methods: {

        },
        data: function () {
            return {
                searchIcon: mdiMagnify,
                Years: [
                    {
                        name: '2020',
                        url: '/Budget/MonthlyResource/#year',
                    },
                    {
                        name: '2019',
                        url: '/Budget/MonthlyResource/#year',
                    },
                    {
                        name: '2018',
                        url: '/Budget/MonthlyResource/#year',
                    },
                    {
                        name: '2017',
                        url: '/Budget/MonthlyResource/#year',
                    }
                ],
            }
        },
        mounted: function () {

        },
        computed: {

        }
    };
</script>
<style>
</style>