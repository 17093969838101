<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col md="2" cols="12">
                <RedistrictingMenu></RedistrictingMenu>
            </v-col>
            <v-col md="10" cols="12">
                <v-card flat>
                    <v-card-title>
                        <h3>Redistricting Laws</h3>
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            US Constitution - Article I, Sections 2 and 4
                        </h3>
                        <p>
                            <h4>
                                Article I, Section 2
                            </h4>
                        </p>
                        <p>
                            The House of Representatives shall be composed of Members chosen every second Year by the People of the several States, and the Electors in each State shall have the Qualifications requisite for Electors of the most numerous Branch of the State Legislature.
                        </p>
                        <p>
                            No Person shall be a Representative who shall not have attained to the Age of twenty five Years, and been seven Years a Citizen of the United States, and who shall not, when elected, be an Inhabitant of that State in which he shall be chosen.
                        </p>
                        <p>
                            Representatives and direct Taxes shall be apportioned among the several States which may be included within this Union, according to their respective Numbers, which shall be determined by adding to the whole Number of free Persons, including those bound to Service for a Term of Years, and excluding Indians not taxed, three fifths of all other Persons. The actual Enumeration shall be made within three Years after the first Meeting of the Congress of the United States, and within every subsequent Term of ten Years, in such Manner as they shall by Law direct. The Number of Representatives shall not exceed one for every thirty Thousand, but each State shall have at Least one Representative; and until such enumeration shall be made, the State of New Hampshire shall be entitled to chuse three, Massachusetts eight, Rhode Island and Providence Plantations one, Connecticut five, New York six, New Jersey four, Pennsylvania eight, Delaware one, Maryland six, Virginia ten, North Carolina five, South Carolina five, and Georgia three.*
                        </p>
                        <p style="font-style: italic; font-size: smaller;">
                            *The part of this clause relating to the mode of apportionment of representatives among the several States has been affected by section 2 of amendment XIV, and as to taxes on incomes without apportionment by amendment XVI.
                        </p>
                        <p>
                            When vacancies happen in the Representation from any State, the Executive Authority thereof shall issue Writs of Election to fill such Vacancies.
                        </p>
                        <p>
                            The House of Representatives shall chuse their Speaker and other Officers; and shall have the sole Power of Impeachment.
                        </p>

                        <p><h4>Article I, Section 4</h4></p>
                        <p>
                            The Times, Places and Manner of holding Elections for Senators and Representatives, shall be prescribed in each State by the Legislature thereof; but the Congress may at any time by Law make or alter such Regulations, except as to the Places of chusing Senators.
                        </p>
                        <p>
                            <h3>
                                US Constitution - The 14th Amendment
                            </h3>
                        </p>
                        <p>
                            <h4>
                                Section 1
                            </h4>
                        </p>
                        <p>
                            All persons born or naturalized in the United States, and subject to the jurisdiction thereof, are citizens of the United States and of the State wherein they reside. No State shall make or enforce any law which shall abridge the privileges or immunities of citizens of the United States; nor shall any State deprive any person of life, liberty, or property, without due process of law; nor deny to any person within its jurisdiction the equal protection of the laws.
                        </p>
                        <p>
                            <h4>
                                Section 2
                            </h4>
                        </p>
                        <p>
                            Representatives shall be apportioned among the several States according to their respective numbers, counting the whole number of persons in each State, excluding Indians not taxed. But when the right to vote at any election for the choice of electors for President and Vice-President of the United States, Representatives in Congress, the Executive and Judicial officers of a State, or the members of the Legislature thereof, is denied to any of the male inhabitants of such State, being twenty-one years of age,* and citizens of the United States, or in any way abridged, except for participation in rebellion, or other crime, the basis of representation therein shall be reduced in the proportion which the number of such male citizens shall bear to the whole number of male citizens twenty-one years of age in such State.
                        </p>
                        <p style="font-style: italic; font-size: smaller;">
                            *Changed by section 1 of the 26th amendment.
                        </p>
                        <p>
                            <h4>Section 3</h4>
                        </p>
                        <p>No person shall be a Senator or Representative in Congress, or elector of President and Vice-President, or hold any office, civil or military, under the United States, or under any State, who, having previously taken an oath, as a member of Congress, or as an officer of the United States, or as a member of any State legislature, or as an executive or judicial officer of any State, to support the Constitution of the United States, shall have engaged in insurrection or rebellion against the same, or given aid or comfort to the enemies thereof. But Congress may by a vote of two-thirds of each House, remove such disability.</p>
                        <p>
                            <h4>Section 4</h4>
                        </p>
                        <p>The validity of the public debt of the United States, authorized by law, including debts incurred for payment of pensions and bounties for services in suppressing insurrection or rebellion, shall not be questioned. But neither the United States nor any State shall assume or pay any debt or obligation incurred in aid of insurrection or rebellion against the United States, or any claim for the loss or emancipation of any slave; but all such debts, obligations and claims shall be held illegal and void.</p>
                        <p>
                            <h4>
                                Section 5
                            </h4>
                        </p>
                        <p>The Congress shall have the power to enforce, by appropriate legislation, the provisions of this article</p>
                        <p>
                            <h3>Federal Voting Rights Act</h3>
                        </p>
                        <p>(a) No voting qualification or prerequisite to voting or standard, practice, or procedure shall be imposed or applied by any State or political subdivision in a manner which results in a denial or abridgement of the right of any citizen of the United States to vote on account of race or color, or in contravention of the guarantees set forth in section 1973b (f)(2) of this title, as provided in subsection (b) of this section.</p>
                        <p>
                            (b) A violation of subsection (a) of this section is established if, based on the totality of circumstances, it is shown that the political processes leading to nomination or election in the State or political subdivision are not equally open to participation by members of a class of citizens protected by subsection (a) of this section in that its members have less opportunity than other members of the electorate to participate in the political process and to elect representatives of their choice. The extent to which members of a protected class have been elected to office in the State or political subdivision is one circumstance which may be considered: Provided, That nothing in this section establishes a right to have members of a protected class elected in numbers equal to their proportion in the population.
                        </p>
                        <p>
                            (42 U.S.C. §§ 1973–1973aa-6)
                        </p>
                        <p>
                            <h3>State Constitution - Article III, Sections 2 and 5</h3>
                        </p>
                        <p>
                            <h4>Article III, Section 2</h4>
                        </p>
                        <p>
                            <i>Number of legislators--Regular sessions</i>. After the Legislature elected for the years 1937 and 1938 the number of members of the house of representatives shall not be less than fifty nor more than seventy-five and the number of members of the senate shall not be less than twenty-five nor more than thirty-five.
                        </p>
                        <p>
                            The sessions of the Legislature shall be biennial except as otherwise provided in this Constitution.
                        </p>
                        <p>
                            <h4>Article III, Section 5</h4>
                        </p>
                        <p>
                            <i>Legislative reapportionment</i>. The Legislature shall apportion its membership by dividing the state into as many single-member, legislative districts as there are state senators. House districts shall be established wholly within senatorial districts and shall be either single-member or dual-member districts as the Legislature shall determine. Legislative districts shall consist of compact, contiguous territory and shall have population as nearly equal as is practicable, based on the last preceding federal census. An apportionment shall be made by the Legislature in 1983 and in 1991, and every ten years after 1991. Such apportionment shall be accomplished by December first of the year in which the apportionment is required. If any Legislature whose duty it is to make an apportionment shall fail to make the same as herein provided, it shall be the duty of the Supreme Court within ninety days to make such apportionment.
                        </p>
                        <p>
                            <h3>
                                State Statute
                            </h3>
                        </p>
                        <p>
                            <h4>
                                2-2-41. Legislative policy in redistricting.
                            </h4>
                        </p>
                        <p>
                            The Legislature, in making the 2021 redistricting, determines, as a matter of policy, that the following principles are of primary significance:
                        </p>
                        <p>
                            (1) Adherence to standards of population deviance as established by judicial precedent and to standards of population deviance as prescribed by S.D. Const., Art. III, § 5;
                        </p>
                        <p>
                            (2) Protection of communities of interest by means of compact and contiguous districts;
                        </p>
                        <p>
                            (3) Respect for geographical and political boundaries; and
                        </p>
                        <p>
                            (4) Protection of minority voting rights consistent with the United States Constitution, the South Dakota Constitution, and federal statutes, as interpreted by the United States Supreme Court and other courts with jurisdiction.
                        </p>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    const RedistrictingMenu = () => import("@/Redistricting/RedistrictingMenu.vue");

    export default {
        name: 'Laws',

        components: {
            RedistrictingMenu
        },
        methods: {

        },
        data: function () {
            return {

            }
        },
        mounted: function () {

        },
        watch: {

        },
        computed: {
            Title: function () {
                let title = 'Redistricting Laws';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>