<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12" v-if="SessionId">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Archived Registers
            </div>
            <v-btn depressed x-small color="orange" style="vertical-align:top;" rel="noopener" :href="'/api/Documents/DocumentTypesRSS?Type=50'">
                <span class="white--text">RSS</span><v-icon color="white" right>{{rss}}</v-icon>
            </v-btn>
            <v-container>
                <v-layout row wrap>
                    <GoogleSearch></GoogleSearch>
                </v-layout>
            </v-container>
            <v-divider></v-divider>

            <v-layout row wrap>
                <v-card flat>
                    <v-card-title>
                        <v-autocomplete v-model="Year"
                                        style="max-width:100px"
                                        :items="Years"
                                        item-value="name"
                                        item-text="name"></v-autocomplete> Register
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear indeterminate
                                           v-if="loading"
                                           color="primary"></v-progress-linear>
                        <div>
                            The South Dakota Register is a weekly publication that contains: notices of hearings on proposed amendments to administrative rules; final administrative rules filed in the secretary of state's office; executive orders; judicial, legislative and executive appointments; declaratory rulings; and other information which is not otherwise generally available to the public. You may receive copies electronically by e-subscribing using MyLRC.
                        </div>
                        <span v-if="Documents && Documents.length == 0">
                            <v-alert v-if="!loading" dense
                                     text
                                     type="info">No registers available for this year.</v-alert>
                        </span>
                        <v-container>
                            <v-layout row wrap>
                                <v-flex xs12 sm2 md3 v-for="d in Documents" :key="d.DocumentId">
                                    <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Register/' + d.DocumentId + '.pdf'">{{DatePretty(d.DocumentDate)}}</a>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-layout>
        </v-col>
    </v-row>

</template>
<script>
    import moment from 'moment'
    import { mdiRss } from '@mdi/js';
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    export default {
        name: 'Registers',
        props: ['SessionId'],
        components: { Sidebar, GoogleSearch },
        methods: {
            LoadData() {
                if (this.SessionId) {
                    fetch('/api/Sessions/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else {
                    fetch('/api/Sessions/Current', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                fetch('/api/Documents/DocumentType?Type=50', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = [];
                    this.items = EncodeDatetimes(response);
                    if (this.Session) {
                        this.Year = this.Session.Year;
                    }
                    else if (this.Session && this.Session.SessionId) {
                        this.Year = new Date().Year;
                        this.$router.push('/Rules/Registers/' + this.Session.SessionId);
                    }
                    else {
                        this.Year = "2022";
                        this.$router.push('/Rules/Registers/64');
                    }
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            DatePretty(date) {
                return moment(date, "DD/MM/YYYY").format("M/DD/YYYY");
            },
        },
        data: function () {
            return {
                rss: mdiRss,
                loading: true,
                items: [],
                allDocuments: [],
                Year: null,
                Session: {},
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: {
                handler() {
                    this.Year = null;
                    this.LoadData();
                },
            },
        },
        computed: {
            Documents: function () {
                if (this.Year) {
                    return this.items.filter(x => moment(x.DocumentDate, "DD/MM/YYYY").format("YYYY") == this.Year);
                }
                else {
                    return [];
                }
            },
            Years: function () {
                let docDates = this.items.map(a => moment(a.DocumentDate, "DD/MM/YYYY").format("YYYY"));
                if (docDates && docDates.length > 0 && this.Session && this.Session.Year) {
                    var unique = [...new Set(docDates)].sort().reverse();
                    if (!unique.find(x => x == this.Session.Year)) {
                        unique.push(this.Session.Year);
                    }
                    return unique;
                } else {
                    return [];
                }
            },
            Title: function () {
                let title = 'Archived Registers';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>