<template>
    <v-container>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card height="400" color="transparent" flat>
                    <div class="display-3 mt-5">Page not found.</div>
                    <div class="grey--text lighten-5">
                        The page you are trying to get to could not be located.
                    </div>
                    <div class="paragraph-text mt-2">
                        <br />
                        <br />Try searching for the page you are looking. Or, click the contact us link for support.
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    export default {
        name: 'NotFound',
        props: {
            Example: { type: Boolean, default: true },
        },
        methods: {

        },
        data: function () {
            return {
            }
        },
        watch: {
        },
        mounted() {
            let url = null;
            if (window && window.location) {
                url = window.location.href;
            }
            let search = null;
            if (window && window.location) {
                search = window.location.search;
            }
            // `info` is a Vue-specific error info, e.g. which lifecycle hook // the error was found in. Only available in 2.2.0+
            fetch("/api/MissingWebsiteUrl", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    MissingWebsiteURLId: 0,
                    Path: url,
                    QueryString: search,
                    Path: url,
                    Created: new Date(),
                    LastUpdated: new Date(),
                    Occurrences: 1,
                }),
            });
        },
        computed: {
            Title: function () {
                let title = 'Not Found';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.protocol + '//' + window.location.host + '/NotFound' }
                ]
            }
        }
    };
</script>
<style></style>