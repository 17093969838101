<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat>
                <v-card-title>
                    Rules Drafting Manual
                </v-card-title>
                <v-card-text>
                    <v-btn rel="noopener" :href="RulesManualURL">Rules Drafting Manual <span><sup>(PDF)</sup></span></v-btn>
                    Last Updated <span v-if="RulesManual != null">{{DatePretty(RulesManual.LastUpdated)}}</span>
                    <br />
                    <br />
                    <!--Rules Forms in Microsoft Word Format-->
                    <v-list v-for="i in Docs">
                        <v-list-item v-if="i.url != null" rel="noopener" :href="i.url">{{i.Name}} <span v-if="i.url.includes('.pdf')"><sup>(PDF)</sup></span><span v-if="i.url.includes('.docx')"><sup>(DOCX)</sup></span></v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>
<script>
    import moment from 'moment'
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'RulesManual',
        components: {
            Sidebar
        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=54', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);

                    this.RulesManual = this.items.filter(x => x.Filename == "rulesmanual.pdf")[0];
                    this.RulesManualURL = this.$MyLRCUrl.value + 'api/Documents/' + this.RulesManual.DocumentId + '.pdf';
                    this.Docs.filter(x => x.Name == 'Permanent Rules')[0].url = this.$MyLRCUrl.value + 'api/Documents/' + this.items.filter(x => x.Filename == "rulesforms.docx")[0].DocumentId + '.docx';
                    this.Docs.filter(x => x.Name == 'Emergency Rules')[0].url = this.$MyLRCUrl.value + 'api/Documents/' + this.items.filter(x => x.Filename == "emergencyforms.docx")[0].DocumentId + '.docx';
                    this.Docs.filter(x => x.Name == 'DSS Rules')[0].url = this.$MyLRCUrl.value + 'api/Documents/' + this.items.filter(x => x.Filename == "ruleformsdss.docx")[0].DocumentId + '.docx';
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            DatePretty(date) {
                return moment(date, "DD/MM/YYYY").format("M/DD/YYYY");
            },
        },
        data: function () {
            return {
                items: [],
                RulesManual: null,
                RulesManualURL: null,
                Docs: [
                    {
                        Name: 'Permanent Rules',
                        url: null,
                    },
                    {
                        Name: 'Emergency Rules',
                        url: null,
                    },
                    {
                        Name: 'DSS Rules',
                        url: null,
                    },
                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Administrative Rules Manual';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>