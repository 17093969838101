<template>
<v-row class="ma-0 pa-0">
    <v-col md="2" sm="12" cols="12">
        <Sidebar></Sidebar>
    </v-col>
    <v-col md="10" sm="12">
        <div class="headline">LEGISLATIVE RESEARCH COUNCIL</div>
        <br />
        <div>
            <div><b>Address: </b>Legislative Research Council</div>
            <div>Capitol Building, 3rd Floor - 500 East Capitol Avenue</div>
            <div>
                Pierre, SD 57501-5070
            </div>
        </div>
        <br />
        <div>
            <b>E-mail:</b> <a rel="noopener" href="mailto:lrc@sdlegislature.gov">LRC@sdlegislature.gov</a>
        </div>
        <br />
        <div>
            <b>Phone:</b> <a rel="noopener" href="tel:6057733251">(605) 773-3251</a>
        </div>
        <br />
        <div>
            <b>Staff Fax:</b> <a rel="noopener" href="tel:6057734576">(605) 773-4576</a>
        </div>
        <br />
        <div>
            <b>Legislator Fax (Session Only):</b> <a rel="noopener" href="tel:6057736806">(605) 773-6806</a>
        </div>
    </v-col>
</v-row>

</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Information',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,
            }
        },
        mounted: function () {
        },
        computed: {
            Title: function () {
                let title = 'Contact Us';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>