<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12" cols="12">
            <div class="headline">
                Staff Listing By Division
            </div>

            <v-row>
                <v-col pb-5 md="3" offset-md="4" offset-sm="0" cols="12">
                    <v-card flat>
                        <v-card-title style="display:inherit" class="text-center">
                            <h3>Director</h3>
                        </v-card-title>
                        <v-card-text>
                            <span v-if="items.length > 0">
                                <PersonCard :expand="false" :UserProp="Director" :bigImage="true"></PersonCard>
                            </span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col pb-5 md="3" offset-md="2" offset-sm="0" cols="12">
                    <v-card flat class="pt-4 mt-4">
                        <v-card-title style="display:inherit" class="text-center">
                            <b>Code Counsel</b>
                        </v-card-title>
                        <v-card-text>
                            <span v-if="items.length > 0">
                                <PersonCard :expand="false" :UserProp="CodeCounsel"></PersonCard>
                            </span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col pb-5 md="3" cols="12">
                    <v-card flat>
                        <v-card-title style="display:inherit" class="text-center  pa-0">
                            <b>Research & Legal</b>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="i in Research">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <PersonCard :expand="false" :UserProp="i"></PersonCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col pb-5 md="3" cols="12">
                    <v-card flat>
                        <v-card-title style="display:inherit" class="text-center  pa-0">
                            <b>Fiscal Analysis</b>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="i in Fiscal">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <PersonCard :expand="false" :UserProp="i"></PersonCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col pb-5 md="3" cols="12">
                    <v-card flat>
                        <v-card-title style="display:inherit" class="text-center  pa-0">
                            <b>Operations</b>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="i in Operations">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <PersonCard :expand="false" :UserProp="i"></PersonCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col pb-5 md="3">
                    <v-card flat>
                        <v-card-title style="display:inherit" class="text-center  pa-0">
                            <b>IT</b>
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <template v-for="i in IT">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <PersonCard :expand="false" :UserProp="i"></PersonCard>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    import { DirectorHead, It, Operations, Fiscal, Research, CodeCounselHead } from '@/utils'

    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const PersonCard = () => import("@/Components/PersonCard.vue");

    export default {
        name: 'Division',
        components: {
            PersonCard, Sidebar
        },
        methods: {
            LoadData()
            {
                fetch('/api/Users', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.ok)
                    {
                        return res.json();
                    }
                    else
                    {
                        throw new Error(res.statusText);
                    }
                }).then(response =>
                {
                    this.items = EncodeDatetimes(response);
                }).catch(error =>
                {
                    throw new Error(error);
                });
            },
            filterDepartment(item)
            {
                this.filterValue = item;

            },
            fieldSorter(fields)
            {
                return function (a, b)
                {
                    return fields
                        .map(function (o)
                        {
                            var dir = 1;
                            if (o[0] === '-')
                            {
                                dir = -1;
                                o = o.substring(1);
                            }
                            if (a[o] > b[o]) return dir;
                            if (a[o] < b[o]) return -(dir);
                            return 0;
                        })
                        .reduce(function firstNonZeroValue(p, n)
                        {
                            return p ? p : n;
                        }, 0);
                };
            },
            sizeOfColumn(i)
            {
                if (i.StaffGroupId == 1 && this.filterValue != 'All')
                {
                    return 'md6 offset-md-3';
                } else
                {
                    return 'md6';
                }
            },
        },
        data: function ()
        {
            return {
                items: [],
            };
        },
        mounted: function ()
        {
            this.LoadData();

        },
        computed: {
            Director: function ()
            {
                if (this.items.length > 0)
                {
                    return DirectorHead(this.items);
                }
                else
                {
                    return {};
                }
            },
            CodeCounsel: function ()
            {
                if (this.items.length > 0)
                {
                    return CodeCounselHead(this.items);
                }
                else
                {
                    return {};
                }
            },
            Staff: function ()
            {
                if (this.items.length > 0)
                {
                    let directorId = (this.Director() || {}).UserId;

                    return this.items.filter(x => x.UserId != directorId);
                }
                else
                {
                    return {};
                }
            },
            Fiscal: function ()
            {
                if (this.items.length > 0)
                {
                    return Fiscal(this.items, true);
                } else
                {
                    return {};
                }
            },
            Research: function ()
            {
                if (this.items.length > 0)
                {
                    return Research(this.items, true);
                }
                else
                {
                    return {};
                }
            },
            Operations: function ()
            {
                if (this.items.length > 0)
                {
                    return Operations(this.items, true);
                }
                else
                {
                    return {};
                }
            },
            IT: function ()
            {
                if (this.items.length > 0)
                {
                    return It(this.items, true);
                } else
                {
                    return {};
                }
            },
            Title: function ()
            {
                let title = 'Staff by Division';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>