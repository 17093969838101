<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat class="pa-xs-0 ma-xs-0">
                <v-card-title v-if="Member">
                    <span v-if="Member.MemberType=='H'">Representative&nbsp;</span>
                    <span v-if="Member.MemberType=='S'">Senator&nbsp;</span>
                    {{Member.FirstLastName }} -&nbsp;<span>{{Member.Year }}</span>
                </v-card-title>
                <v-card-text class="pa-xs-0 ma-xs-0">

                    <v-progress-linear indeterminate
                                       v-if="loading"></v-progress-linear>
                    <v-tabs show-arrows class="pb-md-2">
                        <v-tab v-for="i in tabs"
                               :key="i.value"
                               @click="switchTab(i)"
                               :to="i.Path">

                            {{ i.value }}
                        </v-tab>
                    </v-tabs>
                    <router-view :SessionMemberId="SessionMemberId"></router-view>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    import moment from 'moment'
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'Profile',
        props: ['SessionMemberId'],
        components: {
            Sidebar
        },
        methods: {
            switchTab(item) {
                this.filterValue = item;
            },
            LoadData() {
                fetch('/api/SessionMembers/Detail/' + this.SessionMemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.loading = false;
                    this.Member = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DateTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('M/DD/YYYY h:mm A');
                } else {
                    return null;
                }
            }
        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',

                Member: {

                    Year: null,
                    Picture: ""
                },
            }
        },
        mounted: function () {
            if (this.$route.query.Tab != null) {
                this.activeTab = this.$route.query.Tab;
                this.filterValue = this.$route.query.Tab;
            }
            this.LoadData();
        },
        watch: {
            SessionMemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            tabs: function () {
                return [
                    {
                        Id: 0, value: 'Detail', type: 'All', Path: "/Legislators/Profile/" + this.SessionMemberId + "/Detail"
                    },
                    {
                        Id: 1, value: 'Committees', type: 'All', Path: "/Legislators/Profile/" + this.SessionMemberId + "/Committees"
                    },
                    {
                        Id: 2, value: 'Bills', type: 'All', Path: "/Legislators/Profile/" + this.SessionMemberId + "/Bills"
                    },
                    {
                        Id: 3, value: 'Hearings', type: 'All', Path: "/Legislators/Profile/" + this.SessionMemberId + "/Hearings"
                    },
                ];
            },
            Title: function () {
                let title = 'Loading...';
                if(this.Member && this.Member.FirstLastName){
                    if(this.Member.MemberType == 'H'){
                        title = 'Representative ';
                    }
                    else if(this.Member.MemberType == 'S'){
                        title = 'Senator ';
                    }
                    else{
                        title = '';
                    }
                    title += this.Member.FirstLastName;
                }
                return title;
            },
            Description: function () {
                return this.Title;
            },
            FirstName: function () {
                let name = null;
                if(this.Member && this.Member.FirstLastName){
                    name = this.Member.FirstLastName.split(' ').shift()
                }
                return name;
            },
            LastName: function () {
                let name = null;
                if(this.Member && this.Member.FirstLastName){
                    name = this.Member.FirstLastName.split(' ').pop()
                }
                return name;
            },
        },
        metaInfo()
        {
            //https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
            let removeEmpty = function (obj)
            {
                const newObj = {};

                Object.entries(obj).forEach(([k, v]) =>
                {
                    if (Array.isArray(v))
                    {
                        newObj[k] = new Array();

                        v.forEach(x =>
                        {
                            if (x === Object(x))
                            {
                                newObj[k].push(removeEmpty(x));
                            }
                            else if (x != null)
                            {
                                newObj[k].push(x);
                            }
                        });
                    }
                    else if (v === Object(v))
                    {
                        newObj[k] = removeEmpty(v);
                    } else if (v != null)
                    {
                        newObj[k] = obj[k];
                    }
                });
                return newObj;
            }

            let person = {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Person',
                            'name': (this.Member || {}).FirstLastName,
                            'email': (this.Member || {}).EmailState,
                            'telephone': (this.Member || {}).CapitolPhone,
                            'image': (this.Member || {}).PictureSmall,
                            "address":
                            {
                                "@type": "PostalAddress",
                                "addressLocality": (this.Member || {}).HomeCity,
                                "addressRegion": (this.Member || {}).HomeState,
                                "postalCode": (this.Member || {}).HomeZip,
                                "streetAddress": (this.Member || {}).HomeAddress1
                            }
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'profile' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:image', property: 'og:image', content: (this.Member || {}).PictureSmall },
                    { vmid: 'og:image:width', property: 'og:image:width', content: '100' },
                    { vmid: 'og:image:height', property: 'og:image:height', content: '130' },
                    { vmid: 'profile:first_name', property: 'profile:first_name', content: this.FirstName },
                    { vmid: 'profile:last_name', property: 'profile:last_name', content: this.LastName },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description },
                    { vmid: 'twitter:image', name: 'twitter:image', content: (this.Member || {}).PictureSmall },
                    { vmid: 'twitter:image:alt', name: 'twitter:image:alt', content: 'A Profile Picture of ' + (this.Member || {}).FirstLastName }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };

            person = removeEmpty(person);

            return person;
        }
    };
</script>
<style></style>