<template>
    <v-row class="ma-0 pa-0 .d-print-block">
        <v-col md="2" sm="12" cols="12" class="d-print-none">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div v-if="Session && Session.Year">
                {{Session.YearString}} Bills
            </div>
            <v-container class="width:80%; d-print-none">
                <v-layout row wrap style="text-align:center">
                    <v-row no-gutters>
                        <v-col>
                            <v-card class="py-2"
                                    outlined
                                    tile
                                    xs12
                                    @click="filter('All')">
                                <div style="text-align:center">
                                    All
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
                <v-layout row wrap style="text-align:center">
                    <v-row no-gutters>
                        <v-col>
                            <v-card class="pa-2"
                                    outlined
                                    v-for="i in HouseBillTypes" :key="i.BillType"
                                    tile
                                    xs12
                                    @click="filter(i.BillType)">
                                <v-flex>
                                    {{i.LongBillType}}
                                </v-flex>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="pa-2"
                                    outlined
                                    v-for="i in SenateBillTypes" :key="i.BillType"
                                    tile
                                    xs12
                                    @click="filter(i.BillType)">
                                <v-flex>
                                    {{i.LongBillType}}
                                </v-flex>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-layout>
            </v-container>
            <v-row v-if="error">
                <v-col cols="12">
                    <v-alert type="error">
                        {{error}} - An error occurred; please try again later.
                    </v-alert>
                </v-col>
            </v-row>
            <v-text-field v-model="search"
                          label="Filter"
                          single-line
                          hide-details py-4>
                <template v-slot:append>
                    <v-icon>{{searchIcon}}</v-icon>
                </template>
            </v-text-field>
            <div id="toTable" ref="target"></div>

            <FilterableDataTable :headers="headers"
                                 :items="filterBills"
                                 :search="search"
                                 item-key="BillId"
                                 group-by="BillType"
                                 :dense="false"
                                 multi-sort
                                 ShowDownloadCSV
                                 disable-pagination
                                 hide-default-footer
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 class="elevation-1 d-print-table">
                <template v-slot:group.header="{items, isOpen, toggle, group, groupby}">
                    <td colspan="2" class="d-print-table-cell">
                        Bill Type: {{LongBillName(group)}}
                        <v-icon v-if="isOpen" @click="toggle">{{minus}}</v-icon>
                        <v-icon v-else @click="toggle">{{plus}}</v-icon>
                    </td>
                </template>
                <template v-slot:item.Combined="{ item }">
                    <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumberOnly}}</router-link>
                </template>

                <template v-slot:item.Title="{ item }">
                    <div class="hidden-sm-and-up d-print-none">
                        <span class="d-inline-block text-truncate"
                              style="max-width: 270px;">
                            {{item.Title}}
                        </span>
                    </div>
                    <div class="hidden-xs-only">
                        {{item.Title}}
                    </div>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    import { mdiPlus } from '@mdi/js';
    import { mdiMinus } from '@mdi/js';
    import { mdiMagnify } from '@mdi/js';

    export default {
        name: 'Bills',
        props: ['SessionId'],
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData() {
                this.loading = true;
                this.error = null;
                fetch('/api/Sessions/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Session = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });

                fetch('/api/Bills/Session/Light/' + this.SessionId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.ok) {
                        return res.json();
                    }
                    else {
                        this.error = res.status;
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    var mapped = EncodeDatetimes(response);
                    mapped.map(x => {
                        x.Combined = x.BillType + ' ' + x.BillNumberOnly;
                        return x;
                    })
                    this.Bills = mapped;
                    this.filterBills = mapped;


                    var uniqueBillTypes = [...new Set(mapped.map(item => item.BillType))];
                    this.SenateBillTypes = this.SenateBillTypes.filter(x => uniqueBillTypes.includes(x.BillType) == true);
                    this.HouseBillTypes = this.HouseBillTypes.filter(x => uniqueBillTypes.includes(x.BillType) == true);

                }).catch(error => {
                    throw new Error(error);
                });

                fetch('/api/BillNumbers/BillTypes', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.BillTypes = EncodeDatetimes(response);
                    this.SenateBillTypes = this.BillTypes.filter(x => x.BillType.includes('S') == true);
                    this.HouseBillTypes = this.BillTypes.filter(x => x.BillType.includes('H') == true);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            LongBillName(short) {
                if (this.BillTypes && this.BillTypes.length > 0 && this.BillTypes.find(x => x.BillType == short)) {
                    return this.BillTypes.find(x => x.BillType == short).LongBillType || '';
                }
                else {
                    return short;
                }
            },
            filter(billType) {
                if (billType == 'All') {
                    this.filterBills = this.Bills;
                } else {
                    this.filterBills = this.Bills.filter(x => x.BillType == billType);
                }
                this.toTable();
            },
            toTable: function () {
                var elmnt = document.getElementById("toTable");
                elmnt.scrollIntoView();
            },
        },
        data: function () {
            return {
                error: null,
                plus: mdiPlus,
                minus: mdiMinus,
                searchIcon: mdiMagnify,
                uniqueBillTypes: [],
                loading: true,
                search: '',
                loading: false,
                BillTypes: [],
                SenateBillTypes: [],
                HouseBillTypes: [],
                headers: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'Combined',
                        width: '20ch'
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        width: '*'
                    },
                ],
                Bills: [
                ],
                filterBills: [],
                Session: {},
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionId: function () {
                this.LoadData();
            }
        },
        computed: {
            Title: function () {
                let title = 'Loading Bills...';
                if (this.Session && this.Session.Year) {
                    title = this.Session.Year + " Bills";
                }
                else {
                    title = 'Bills';
                }
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>