var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[(_vm.Session && _vm.Session.YearString)?_c('v-card-title',[_vm._v(_vm._s(_vm.Session.YearString)+" Conference Committees")]):_vm._e(),_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.PageText)}})])],1),_c('hr'),_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-0"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('FilterableDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.TableHeaders,"items":_vm.AllConferenceCommittees,"hide-default-footer":"","group-by":"BillNumber","disable-pagination":"","disable-sort":"","items-per-page":5},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var group = ref.group;
var groupby = ref.groupby;
var remove = ref.remove;
return [_c('td',{staticClass:"d-print-table-cell pl-2",attrs:{"colspan":"7"}},[_c('b',[_c('router-link',{attrs:{"to":'/Session/Bill/' + items[0].Bill.BillId}},[_vm._v(_vm._s(items[0].Bill.BillType + ' ' + items[0].Bill.BillNumberOnly))]),_vm._v(" - "+_vm._s(items[0].Bill.Title))],1)])]}},{key:"item.MeetingTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DatePretty(item.MeetingTime))+" ")]}},{key:"item.Senate",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.filterMembers(item, 'S')),function(j,i){return _c('div',{staticStyle:{"display":"inline"}},[_c('router-link',{attrs:{"to":'/Legislators/Profile/' +j.SessionMemberId}},[_vm._v(_vm._s(j.LastName))]),(j.Membership == 'Chair')?_c('span',[_vm._v(" (Chair)")]):_vm._e(),(i < (_vm.filterMembers(item, 'S').length-1))?_c('span',[_vm._v(", ")]):_vm._e()],1)})}},{key:"item.House",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.filterMembers(item, 'H')),function(j,i){return _c('div',{staticStyle:{"display":"inline"}},[_c('router-link',{attrs:{"to":'/Legislators/Profile/' +j.SessionMemberId}},[_vm._v(_vm._s(j.LastName))]),(j.Membership == 'Chair')?_c('span',[_vm._v(" (Chair)")]):_vm._e(),(i < _vm.filterMembers(item, 'H').length-1)?_c('span',[_vm._v(", ")]):_vm._e()],1)})}},{key:"item.Report",fn:function(ref){
var item = ref.item;
return [(item.Report !=null)?_c('span',[_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value + 'api/Documents/' + item.Report.DocumentId + '.pdf'}},[_vm._v("Report")])]):_vm._e()]}},{key:"item.Minutes",fn:function(ref){
var item = ref.item;
return [(item.Minutes !=null)?_c('span',[_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value + 'api/Documents/' + item.Minutes.DocumentId + '.pdf'}},[_vm._v("Minutes")])]):_vm._e()]}},{key:"item.CustomDisposition",fn:function(ref){
var item = ref.item;
return [(item.Disposition.SenateAdoptionText != null)?_c('div',[(item.Disposition.SenateAdoptionText.StatusText =='Conference Committee Report adopted')?_c('span',[_vm._v("Senate adopted report")]):_vm._e(),(item.Disposition.SenateAdoptionText.StatusText =='Conference Committee Report adopted, no committee appointed')?_c('span',[_vm._v("Senate report adopted - No Committee Appointed")]):_vm._e(),(item.Disposition.SenateAdoptionText.StatusText =='Conference Committee report not adopted, appoint Conference Committee')?_c('span',[_vm._v("Senate report not adopted")]):_vm._e(),(item.Disposition.SenateAdoptionText.StatusText =='Conference Committee report not adopted, no committee appointed')?_c('span',[_vm._v("Senate report not adopted")]):_vm._e()]):_vm._e(),(item.Disposition.HouseAdoptionText != null)?_c('div',[(item.Disposition.HouseAdoptionText.StatusText =='Conference Committee Report adopted')?_c('span',[_vm._v("House adopted report")]):_vm._e(),(item.Disposition.HouseAdoptionText.StatusText =='Conference Committee Report adopted, no committee appointed')?_c('span',[_vm._v("House report adopted - No Committee Appointed")]):_vm._e(),(item.Disposition.HouseAdoptionText.StatusText =='Conference Committee report not adopted, appoint Conference Committee')?_c('span',[_vm._v("House report not adopted")]):_vm._e(),(item.Disposition.HouseAdoptionText.StatusText =='Conference Committee report not adopted, no committee appointed')?_c('span',[_vm._v("House report not adopted")]):_vm._e()]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }