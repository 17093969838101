<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">Statehood Legislators <v-chip>{{items.length}}</v-chip></div>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 item-key="MemberId + Year"
                                 :sort-by="['Year']"
                                 :sort-desc="[true]"
                                 :loading="loading"
                                 :items-per-page="999"
                                 disable-pagination
                                 hide-default-footer
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.Name="{ item }">
                    <router-link :to="'/Historical/Legislator/Profile/' + item.MemberId">{{item.Name}}</router-link>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Statehood',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                fetch('/api/Historical/Statehood', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    if (res.ok)
                        return res.json();

                    else
                        throw new Error(res.statusText);
                }).then(response =>
                {
                    if (response)
                        this.items = response;

                }).catch(error =>
                {
                    throw new Error(error);
                }).finally(_ =>
                {
                    this.loading = false;
                });
            },
        },
        data: function ()
        {
            return {
                search: '',
                loading: true,
                items: [],
                headers: [
                    { text: 'Name', value: 'Name' },
                    { text: 'Birthdate', value: 'Birthdate' },
                    { text: 'Deathdate', value: 'Deathdate' },
                    { text: 'Years', value: 'Years' },
                    { text: 'Remarks', value: 'Remarks' },
                ],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {

        }
    };
</script>
<style></style>