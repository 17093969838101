<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="Session && Session.SessionId" :SessionId="Session.SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <div class="headline" v-if="Vote && Vote.actionLog && Vote.Bill">
                <span v-for="(b,i) in Vote.Bill" :key="i"><span v-if="i>0">, <router-link :to="'/Session/Bill/'+b.BillId">{{b.BillType}} {{b.BillNumber}}</router-link></span><span v-else><router-link :to="'/Session/Bill/'+b.BillId">{{b.BillType}} {{b.BillNumber}}</router-link></span></span><span v-if="Vote.Bill && Vote.Bill.length > 0">, {{Vote.actionLog.FullName}}, {{Vote.actionLog.StatusText}} - {{Session.Year}}</span><span v-else>{{Vote.actionLog.FullName}}, {{Vote.actionLog.StatusText}} - {{Session.Year}}</span>
            </div>
            <v-row v-if="Vote">
                <v-col cols="12" class="py-2">
                    <b>Yeas</b><v-chip>{{Vote.Yeas}}</v-chip>
                    <b>Nays</b><v-chip>{{Vote.Nays}}</v-chip>
                    <b>Excused</b><v-chip>{{Vote.Excused}}</v-chip>
                    <b>Absent</b><v-chip>{{Vote.Absent}}</v-chip>
                </v-col>
            </v-row>
            <v-row v-if="Vote && Vote.RollCalls">
                <v-flex md4 sm6 xs12 v-for="i in Vote.RollCalls" :key="i.RollCallId">
                    <v-layout wrap>
                        <v-flex xs7 offset-xs1><router-link :to="'/Legislators/Profile/' +i.SessionMemberId+'/Detail'">{{i.FormattedUniqueName}}</router-link></v-flex>
                        <v-flex xs4>{{i.Vote1}}</v-flex>
                    </v-layout>
                </v-flex>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
    import moment from 'moment'
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'Vote',
        props: ['VoteId'],
        components: {
            Sidebar
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/Votes/' + this.VoteId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        this.Vote = EncodeDatetimes(response);

                        if (this.Vote.SessionId)
                        {
                            return fetch('/api/Sessions/' + this.Vote.SessionId,
                                {
                                    method: "GET",
                                    credentials: "include",
                                    headers: { "Content-Type": "application/json" },
                                })
                                .then(res =>
                                {
                                    if (res.ok)
                                        return res.json();
                                    else
                                        return res.text().then(r => Promise.reject(r));
                                })
                                .then(response =>
                                {
                                    this.Session = EncodeDatetimes(response);
                                });
                        }
                    })
                    .catch(error =>
                    {
                        throw new Error(error);

                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
            DatePretty(Date)
            {
                if (Date != undefined)
                {
                    return moment(Date).format('MM/DD/YYYY');
                } else
                {
                    return null;
                }
            },
            DateMonthPretty(Date)
            {
                if (Date != undefined)
                {
                    return moment(Date).format('MMMM DD, YYYY');
                } else
                {
                    return null;
                }
            },
        },
        data: function ()
        {
            return {
                search: '',
                loading: true,
                Session: {},
                Vote: {
                    SessionId: null,
                    Yea: 0,
                    Nay: 0,
                    Excused: 0,
                    Absent: 0,
                },
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            VoteId: function ()
            {
                this.LoadData();
            }
        },
        computed: {
            Title: function ()
            {
                let title = 'Vote ' + this.VoteId;
                return title;
            },
            Description: function ()
            {
                if (this.Vote && this.Vote.actionLog && this.Vote.actionLog.StatusText)
                {
                    return this.Title + ': ' + this.Vote.actionLog.FullName + ' ' + this.Vote.actionLog.StatusText;
                }
                return this.Title;
            },
            datePublished: function ()
            {
                if (this.Vote && this.Vote.actionLog && this.Vote.actionLog.ActionDate)
                {
                    return moment(this.Vote.actionLog.ActionDate).format();
                }
                return null;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    { vmid: 'datePublished', property: 'datePublished', content: this.datePublished },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>