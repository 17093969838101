<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar v-if="Session && Session.SessionId" :SessionId="Session.SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">Find My Legislators</div>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <div id="viewDiv" :width="'100%'" style="align-content:center;height:75vh;"></div>
            <div class="esri-widget" id="optionsDiv" style="width:240px">
                <v-container class="py-0">
                    <v-layout>
                        <v-row v-if="Districts && Districts.length > 0 && !loading && (defaultLayer.length == 1) " v-show="snap">
                            <v-select dense label="District" :items="Districts" class="esri-widget" id="valSelect" v-model="valSelect" @change="doQuery" clearable>
                            </v-select>
                        </v-row>
                    </v-layout>
                </v-container>
            </div>
        </v-col>
    </v-row>

</template>
<script>
    import Sidebar from "@/Menus/Sidebar.vue";
    import { nextTick } from 'vue';

    //import Map from "@arcgis/core/Map";
    //import MapView from "@arcgis/core/views/MapView";
    //import Search from "@arcgis/core/widgets/Search";
    //import Locate from "@arcgis/core/widgets/Locate";
    //import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
    //import Expand from "@arcgis/core/widgets/Expand";
    //import Query from "@arcgis/core/rest/support/Query";
    //import Polygon from "@arcgis/core/geometry/Polygon";
    //import Home from "@arcgis/core/widgets/Home";
    //import LayerList from "@arcgis/core/widgets/LayerList";
    //import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
    //import "@arcgis/core/assets/esri/themes/light/main.css";

    export default {
        name: 'Find',
        components: {
            Sidebar
        },
        methods: {
            LoadData()
            {
                var component = this;

                window.dojoConfig = {
                    async: true,
                    has: { "csp-restrictions": true }
                };

                fetch('/api/Sessions/Current',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        this.loading = false;
                        if (res.ok)
                        {
                            return res.json();
                        }
                        throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        if (response)
                        {
                            this.Session = EncodeDatetimes(response);

                            return fetch('/api/SessionMembers/Session/' + this.Session.SessionId,
                                {
                                    method: "GET",
                                    credentials: "include",
                                    headers: { "Content-Type": "application/json" },
                                })
                                .then(res =>
                                {
                                    if (res.ok)
                                    {
                                        return res.json();
                                    }
                                    throw new Error(res.statusText);
                                })
                                .then(response =>
                                {
                                    if (response)
                                    {
                                        this.SessionMembers = EncodeDatetimes(response);
                                        this.Districts = Array.from(new Set(this.SessionMembers.map(x => x.District))).sort((a, b) => (a > b) ? 1 : -1);
                                    }
                                })
                                .catch(error =>
                                {
                                    throw new Error(error);
                                });
                        }
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    });

                var years = ['2021', '2011'];
                if (component.$route.query.activeLayer)
                {
                    if (Array.isArray(component.$route.query.activeLayer))
                    {
                        component.defaultLayer = component.$route.query.activeLayer;
                    }
                    else
                    {
                        if (years.includes(component.$route.query.activeLayer))
                        {
                            component.defaultLayer = [component.$route.query.activeLayer];
                        }
                        else
                        {
                            component.defaultLayer = ['2021'];
                        }
                    }
                }
                else
                {
                    component.defaultLayer = ['2021'];
                }

                nextTick(() =>
                {
                    component.LoadMap(component);
                });

            },
            LoadMap(component)
            {
                if (!window.require)
                {
                    setTimeout(()=>component.LoadMap(component), 500);
                }
                else
                {
                    window.require(["esri/config", "esri/Map", "esri/views/MapView", 'esri/widgets/Search', 'esri/widgets/Locate'
                    , 'esri/widgets/BasemapGallery', 'esri/widgets/Expand', 'esri/rest/support/Query', 'esri/geometry/Polygon', 'esri/widgets/Home'
                    , 'esri/widgets/LayerList', 'esri/layers/GeoJSONLayer'],
                    function (esriConfig, Map, MapView, Search, Locate,
                        BasemapGallery, Expand, Query, Polygon, Home,
                        LayerList, GeoJSONLayer)
                    {

                        var map = new Map({
                            basemap: "streets-navigation-vector"
                        });

                        component.redistrictingFeatureLayer = new GeoJSONLayer({
                            url: "/redistrictingFeatureLayer.geojson",
                            title: "Current Districts",
                            objectIdField: "ID",
                            opacity: 0.5,
                            labelingInfo: component.labelClass("DISTRICT"),
                            renderer: component.renderer("DISTRICT"),
                            visible: component.defaultLayer.includes('2021'),

                        });
                        map.add(component.redistrictingFeatureLayer);

                        component.districtLayer = new GeoJSONLayer({
                            url: "/2010redistrictingFeatureLayer.geojson",
                            title: "Prior effective Districts",
                            objectIdField: "id",
                            opacity: 0.5,
                            labelingInfo: component.labelClass("District_Name"),
                            renderer: component.renderer("District_Name"),
                            visible: component.defaultLayer.includes('2011'),
                        });
                        map.add(component.districtLayer);

                        component.view = new MapView({
                            container: "viewDiv",
                            map: map,
                            center: [-100.47, 44.12042],
                            zoom: 7
                        });

                        component.view.whenLayerView(component.districtLayer).then(function (lyrView)
                        {
                            if (component.$route && component.$route.query && component.$route.query.District)
                            {
                                let d = component.$route.query.District.replace(/^0+/, '');
                                component.queryDistrict(d);
                            }
                        });

                        component.view.when(function ()
                        {
                            component.view.ui.add("optionsDiv", "top-right");
                            var layers = document.getElementsByClassName("esri-layer-list__item-label");
                            for (var i = 0; i < layers.length; i++)
                            {
                                layers[i].addEventListener("click", component.LayerListClick);
                            }
                            component.snap = true;
                        });

                        component.districtLayer.popupTemplate = component.popupdistrictLayer;
                        component.redistrictingFeatureLayer.popupTemplate = component.popupredistrictLayer;

                        var locateBtn = new Locate({
                            view: component.view
                        });
                        var searchWidget = new Search({
                            view: component.view,
                            popupOpenOnSelect: false
                        });

                        searchWidget.on('select-result', function (evt)
                        {
                            console.info(evt);

                            if (component.defaultLayer.includes('2011') || component.defaultLayer.includes('Curr'))
                            {
                                component.districtLayer.queryFeatures({
                                    //query object
                                    geometry: evt.result.feature.geometry,
                                    spatialRelationship: "intersects",
                                    returnGeometry: false,
                                    outFields: ["*"],
                                }).then(function (featureSet)
                                {
                                    // open popup of query result
                                    component.view.openPopup({
                                        location: evt.result.feature.geometry,
                                        features: featureSet.features
                                    });
                                });
                            }

                            if (component.defaultLayer.includes('2021'))
                            {
                                component.redistrictLayer.queryFeatures({
                                    //query object
                                    geometry: evt.result.feature.geometry,
                                    spatialRelationship: "intersects",
                                    returnGeometry: false,
                                    outFields: ["*"],
                                }).then(function (featureSet)
                                {
                                    // open popup of query result
                                    component.view.openPopup({
                                        location: evt.result.feature.geometry,
                                        features: featureSet.features
                                    });
                                });
                            }

                        });
                        let homeWidget = new Home({
                            view: component.view
                        });
                        let layerList = new LayerList({
                            view: component.view
                        });
                        var basemapGallery = new BasemapGallery({
                            view: component.view,
                            container: document.createElement("div")
                        });
                        var bgExpand = new Expand({
                            view: component.view,
                            content: basemapGallery
                        });
                        basemapGallery.watch("activeBasemap", function ()
                        {
                            var mobileSize =
                                component.view.heightBreakpoint === "xsmall" ||
                                component.view.widthBreakpoint === "xsmall";

                            if (mobileSize)
                            {
                                bgExpand.collapse();
                            }
                        });

                        component.view.ui.add(layerList, {
                            position: "top-right"
                        });
                        component.view.ui.add(homeWidget, "top-left");
                        component.view.ui.add(locateBtn, {
                            position: "top-left"
                        });
                        component.view.ui.add(searchWidget, {
                            position: "top-right"
                        });
                        component.view.ui.add(bgExpand, "top-left");
                        component.loading = false;
                    });
                }
            },
            renderer(name)
            {
                return {
                    type: "unique-value",
                    field: name,
                    defaultSymbol: { type: "simple-fill" },
                    uniqueValueInfos: [{
                        value: "1",
                        symbol: {
                            type: "simple-fill",
                            color: "#00CED1"
                        }
                    }, {
                        value: "2",
                        symbol: {
                            type: "simple-fill",
                            color: "#E0FFFF"
                        }
                    }, {
                        value: "3",
                        symbol: {
                            type: "simple-fill",
                            color: "#800000"
                        }
                    }, {
                        value: "4",
                        symbol: {
                            type: "simple-fill",
                            color: "#F0FFF0"
                        }
                    }, {
                        value: "5",
                        symbol: {
                            type: "simple-fill",
                            color: "#9966CC"
                        }
                    }, {
                        value: "6",
                        symbol: {
                            type: "simple-fill",
                            color: "#808080"
                        }
                    }, {
                        value: "7",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFFFE0"
                        }
                    }, {
                        value: "8",
                        symbol: {
                            type: "simple-fill",
                            color: "#9932CC"
                        }
                    }, {
                        value: "9",
                        symbol: {
                            type: "simple-fill",
                            color: "#9ACD32"
                        }
                    }, {
                        value: "10",
                        symbol: {
                            type: "simple-fill",
                            color: "#B8860B"
                        }
                    }, {
                        value: "11",
                        symbol: {
                            type: "simple-fill",
                            color: "#DAA520"
                        }
                    }, {
                        value: "12",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFFAF0"
                        }
                    }, {
                        value: "13",
                        symbol: {
                            type: "simple-fill",
                            color: "#20B2AA"
                        }
                    }, {
                        value: "14",
                        symbol: {
                            type: "simple-fill",
                            color: "#808000"
                        }
                    }, {
                        value: "15",
                        symbol: {
                            type: "simple-fill",
                            color: "#228B22"
                        }
                    }, {
                        value: "16",
                        symbol: {
                            type: "simple-fill",
                            color: "#191970"
                        }
                    }, {
                        value: "17",
                        symbol: {
                            type: "simple-fill",
                            color: "#ADFF2F"
                        }
                    }, {
                        value: "18",
                        symbol: {
                            type: "simple-fill",
                            color: "#CD853F"
                        }
                    }, {
                        value: "19",
                        symbol: {
                            type: "simple-fill",
                            color: "#008080"
                        }
                    }, {
                        value: "20",
                        symbol: {
                            type: "simple-fill",
                            color: "#8B0000"
                        }
                    },
                    {
                        value: "21",
                        symbol: {
                            type: "simple-fill",
                            color: "#32CD32"
                        }
                    }, {
                        value: "22",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFE4B5"
                        }
                    }, {
                        value: "23",
                        symbol: {
                            type: "simple-fill",
                            color: "#CD5C5C"
                        }
                    }, {
                        value: "24",
                        symbol: {
                            type: "simple-fill",
                            color: "#7B68EE"
                        }
                    }, {
                        value: "25",
                        symbol: {
                            type: "simple-fill",
                            color: "#EEE8AA"
                        }
                    }, {
                        value: "26",
                        symbol: {
                            type: "simple-fill",
                            color: "#4682B4"
                        }
                    }, {
                        value: "27",
                        symbol: {
                            type: "simple-fill",
                            color: "#00FA9A"
                        }
                    }, {
                        value: "28",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFD700"
                        }
                    }, {
                        value: "29",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFFAFA"
                        }
                    }, {
                        value: "30",
                        symbol: {
                            type: "simple-fill",
                            color: "#FFA500"
                        }
                    }, {
                        value: "31",
                        symbol: {
                            type: "simple-fill",
                            color: "#F08080"
                        }
                    }, {
                        value: "32",
                        symbol: {
                            type: "simple-fill",
                            color: "#0000FF"
                        }
                    }, {
                        value: "33",
                        symbol: {
                            type: "simple-fill",
                            color: "#00BFFF"
                        }
                    }, {
                        value: "34",
                        symbol: {
                            type: "simple-fill",
                            color: "#696969"
                        }
                    }, {
                        value: "35",
                        symbol: {
                            type: "simple-fill",
                            color: "#FAFAD2"
                        }
                    }, {
                        value: "26A",
                        symbol: {
                            type: "simple-fill",
                            color: "#3CB371"
                        }
                    }, {
                        value: "26B",
                        symbol: {
                            type: "simple-fill",
                            color: "#000000"
                        }
                    }, {
                        value: "28A",
                        symbol: {
                            type: "simple-fill",
                            color: "#AFEEEE"
                        }
                    },
                    {
                        value: "28B",
                        symbol: {
                            type: "simple-fill",
                            color: "#6B8E23"
                        }
                    },

                    //
                    {
                        value: "36",
                        symbol: {
                            type: "simple-fill",
                            color: "#3CB371"
                        }
                    }, {
                        value: "38",
                        symbol: {
                            type: "simple-fill",
                            color: "#000000"
                        }
                    }, {
                        value: "37",
                        symbol: {
                            type: "simple-fill",
                            color: "#AFEEEE"
                        }
                    },
                    {
                        value: "39",
                        symbol: {
                            type: "simple-fill",
                            color: "#6B8E23"
                        }
                    }

                    ],
                };
            },
            labelClass(name)
            {
                return [{
                    symbol: {
                        type: "text",
                        color: "black",
                    },
                    labelPlacement: "center-center",
                    labelExpressionInfo: {
                        expression: "$feature." + name
                    }
                }];
            },
            doQuery()
            {
                let value = this.valSelect;
                if (value)
                {
                    this.queryDistrict(value.toString().replace(/^0+/, ''));
                }
            },
            queryDistrict(district)
            {
                var component = this;

                if (district != undefined)
                {
                    window.require(['esri/rest/support/Query', 'esri/geometry/Polygon'], function (Query, Polygon)
                    {
                        if (component.defaultLayer.includes('2011') || component.defaultLayer.includes('Curr'))
                        {
                            const query = new Query();

                            query.where = "District_Name = '" + district + "'";
                            query.returnGeometry = true;
                            query.outFields = ["*"];

                            component.districtLayer.queryFeatures(query).then(function (results)
                            {

                                var extent = results.features[0].geometry.extent;

                                var rings = [
                                    [  // first ring
                                        [extent.xmin, extent.ymin],
                                        [extent.xmax, extent.ymax],
                                    ],
                                ];

                                var polygon = new Polygon({
                                    hasZ: false,
                                    hasM: false,
                                    rings: rings,
                                    spatialReference: { wkid: 4326 }
                                });
                                component.view.goTo(polygon);

                                var point = {
                                    type: "point",
                                    x: extent.center.longitude,
                                    y: extent.center.latitude
                                };

                                component.view.openPopup({
                                    location: point,
                                    features: results.features
                                });

                            });
                        }

                        if (component.defaultLayer.includes('2021'))
                        {
                            const query = new Query();

                            query.where = "District = '" + district + "'";
                            query.returnGeometry = true;
                            query.outFields = ["*"];

                            component.redistrictingFeatureLayer.queryFeatures(query).then(function (results)
                            {

                                var extent = results.features[0].geometry.extent;

                                var rings = [
                                    [  // first ring
                                        [extent.xmin, extent.ymin],
                                        [extent.xmax, extent.ymax],
                                    ],
                                ];

                                var polygon = new Polygon({
                                    hasZ: false,
                                    hasM: false,
                                    rings: rings,
                                    spatialReference: { wkid: 4326 }
                                });
                                component.view.goTo(polygon);


                                var point = {
                                    type: "point",
                                    x: extent.center.longitude,
                                    y: extent.center.latitude
                                };


                                component.view.openPopup({
                                    location: point,
                                    features: results.features
                                });

                            });
                        }
                    });
                }
            },
            LayerListClick()
            {
                var newVisible = [];
                var visibleItems = this.view.layerViews._items.filter(x => x.visible);

                visibleItems.forEach(x =>
                {
                    newVisible.push(x.layer.title.substring(0, 4));
                });

                this.defaultLayer = newVisible;
            },
            contentChange(feature)
            {
                return "<b>Counties:</b> " + this.createCounties(feature.graphic.attributes.District_Name || feature.graphic.attributes.DISTRICT) + "<br><b>Legislators:</b><br> " + this.createLegislators(feature.graphic.attributes.District_Name || feature.graphic.attributes.DISTRICT);
            },
            createLegislators(number)
            {
                var res = "<table style='border: 1px solid black;'><tr style='border: 1px solid black;'><td style='border: 1px solid black;'>Name</td><td style='border: 1px solid black;'>Chamber</td><td style='border: 1px solid black;'>Party</td><td style='border: 1px solid black;'>Status</td><td style='border: 1px solid black;'>Occupation</td></tr>";
                if (this.SessionMembers && this.SessionMembers.length > 0)
                {
                    this.SessionMembers.filter(x => x.District.replace(/^0+/, '') == number).forEach(x =>
                    {
                        res = res + "<tr style='border: 1px solid black;'>";
                        res = res + "<td style='border: 1px solid black;'><a rel='noopener' href='https://sdlegislature.gov/Legislators/Profile/" + x.SessionMemberId + "'>" + x.LastName + ", " + x.FirstName + "</a></td>";
                        res = res + "<td style='border: 1px solid black;'>" + this.MemberTypes(x.MemberType) + "</td>";
                        res = res + "<td style='border: 1px solid black;'>" + this.Politics(x.Politics) + "</td>";
                        res = res + "<td style='border: 1px solid black;'>" + this.Terms(x.Term) + "</td>";
                        res = res + "<td style='border: 1px solid black;'>" + (x.Occupation != null ? x.Occupation : "") + "</td>";
                        res = res + "</tr>";
                    });
                }
                res = res + "</table>";
                return res;
            },
            createCounties(number)
            {
                var res = "";
                this.DistrictCounties.filter(x => x.District.District.replace(/^0+/, '') == number && x.SessionId == (this.Session.SessionId)).forEach(x =>
                {
                    res = res + x.County.County + ", ";
                });
                res = res.substring(0, res.length - 2);

                return res;
            },
            MemberTypes(type)
            {
                switch (type)
                {
                    case 'S':
                        return 'Senate';
                        break;
                    case 'H':
                        return 'House';
                        break;
                    default:
                        return '';
                        break;

                }
            },
            Politics(p)
            {
                switch (p)
                {
                    case 'R':
                        return 'Republican';
                        break;
                    case 'D':
                        return 'Democrat';
                        break;
                    case 'I':
                        return 'Independent';
                        break;
                    default:
                        return '';
                        break;
                }
            },
            Terms(t)
            {
                switch (t)
                {
                    case 'I':
                        return 'Incumbent';
                        break;
                    case 'G':
                        return 'Govs. Appointment';
                        break;
                    case 'N':
                        return 'New Member';
                        break;
                    case 'M':
                        return 'Other House';
                        break;
                    default:
                        return '';
                        break;
                }
            }
        },
        data: function ()
        {
            return {
                loading: true,
                snap: false,
                DistrictCounties: [],
                Districts: [],
                SessionMembers: [],
                valSelect: {},
                defaultLayer: ['2011'],
                districtLayer: null,
                redistrictLayer: null,
                popupdistrictLayer: {
                    title: "<b>District:</b> {District_Name}",
                    //content: this.contentChange
                },
                popupredistrictLayer: {
                    title: "<b>District:</b> {District}",
                    content: this.contentChange
                },
                view: null,
                Session: {},
            };
        },
        mounted: function ()
        {
            this.LoadData();
            var component = this;
            //var cssId = 'myCss';  // you could encode the css path itself to generate id..
            //if (!document.getElementById(cssId)) {
            //    var head = document.getElementsByTagName('head')[0];
            //    var link = document.createElement('link');
            //    link.id = cssId;
            //    link.rel = 'stylesheet';
            //    link.type = 'text/css';
            //    link.href = 'https://js.arcgis.com/4.23/esri/css/main.css';
            //    link.media = 'all';
            //    head.appendChild(link);
            //}

            fetch('/api/DistrictCounties', {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(res =>
            {
                if (res.ok)
                {
                    return res.json();
                }
                throw new Error(res.statusText);
            }).then(response =>
            {
                if (response)
                {
                    this.DistrictCounties = EncodeDatetimes(response);
                    var temp = component.DistrictCounties.filter(x => x.SessionId == this.Session.SessionId);
                    this.Districts = [...new Set(temp.map(item => item.District.District))].sort();
                }
            }).catch(error =>
            {
                throw new Error(error);
            });
        },
        watch: {

        },
        computed: {
            Title: function ()
            {
                let title = 'Find My Legislators';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] },
                    { rel: 'stylesheet', href: "https://js.arcgis.com/4.30/esri/themes/light/main.css" },
                ],
                script: [
                    { src: "https://js.arcgis.com/4.30/" },
                    { src: "https://js.arcgis.com/map-components/4.30/arcgis-map-components.esm.js", type: 'module' },
                ]
            };
        }
    };
</script>
<style></style>