<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Staff By Budget Agency Assignment
            </div>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 item-key="Agency"
                                 :sort-by="['Agency']"
                                 :sort-desc="[false]"
                                 :items-per-page="999"
                                 :loading="loading"
                                 disable-pagination
                                 hide-default-footer
                                 loading-text="Loading... Please wait"
                                 class="elevation-1">
                <template v-slot:item.Staff="{ item }">
                    <span v-for="(staff, index) in item.AssignedTo">
                        <span v-if="index > 0">, </span>
                        <router-link :to="'/Staff/Profile/' + staff.UserId">{{staff.FirstName + ' ' + staff.LastName}}</router-link>
                    </span>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'BudgetAgency',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/Users/BudgetAgencies',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        let i = EncodeDatetimes(response)

                        i.forEach(x =>
                        {
                            let s = '';

                            x.AssignedTo.forEach(y => s += y.FirstName + ' ' + y.LastName + ', ');
                            x.Staff = s;
                        });

                        this.items = i;
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
        },
        data: function ()
        {
            return {
                loading: true,
                headers: [
                    { text: 'Budget Agency', value: 'Agency', width: '*', filterable: true, Filter: null },
                    { text: 'Fiscal Staff', value: 'Staff', width: '50ch', filterable: true, Filter: null },
                ],
                items: [],
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Staff By Budget Agencies';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>