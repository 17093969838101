<template>
    <v-container>
        <v-card flat>
            <v-card-title>
                Performance Management Reports
            </v-card-title>
            <v-card-text>
                <v-container>
                    <GoogleSearch></GoogleSearch>
                </v-container>
                <v-divider></v-divider>
                <div>Welcome to the Performance Management Reports web page. Here you will find reports from state agencies that were compiled as part of the review process to provide the Legislature with a consistent system to evaluate the efficiency and effectiveness of state agencies and to provide additional government transparency and accountability to the Legislature. The performance management review process began in 2016 as part of the Legislative Planning Committee's work and was transferred to the Government Operations and Audit Committee in 2017.</div>
                <div>
                    <b>SDCL Authority: </b><span v-for="(s,i) in Scope"><a :href="'/Statutes?Statute=' + s">{{s}}</a><span v-if="i< s.length -3">, </span><span v-else-if="i< s.length -2">, and </span></span>.
                </div>
                <v-divider></v-divider>
                <FilterableDataTable :headers="headers"
                              :items="items"
                              item-key="DocumentId"
                              sort-by="DocumentId"
                              :sort-desc="[true]"
                              disable-pagination
                              hide-default-footer
                              :loading="loading"
                              loading-text="Loading... Please wait"
                              class="elevation-1">
                    <template v-slot:item.DocumentDate="{ item }">
                        {{GetDateYear(item.DocumentDate)}}
                    </template>
                    <template v-slot:item.Description="{ item }">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Performance Management Report/' + item.DocumentId + '.pdf'">
                            <span v-if="item.Agency !=null">{{item.Agency.Agency}}</span>
                            <span v-else-if="item.Department !=null">{{item.Department}}</span>
                            <span v-else>{{item.Description}}</span>
                        </a>
                    </template>
                </FilterableDataTable>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'PerformanceReports',
        components: {
            GoogleSearch, FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=52', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            GetDateYear(item) {
                return item.getFullYear();
            },
        },
        data: function () {
            return {
                loading: true,
                headers: [
                    { text: 'Report', value: 'Description', width: '*' },
                    { text: 'Year', value: 'DocumentDate', width: '15ch' }],
                items: [

                ],
                Scope: ['2-6-35', '2-6-36', ' 2-6-37', '2-6-38', '2-6-39'],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            Title: function () {
                let title = 'Performance Reports';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>