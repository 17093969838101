<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">Tenure Reports</div>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/TenYear">10-14 Years</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/FifteenYear">15-19 Years</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/TwentyYear">20-24 Years</v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn to="/Historical/Reports/TwentyFiveYear">25+ Years</v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    export default {
        name: 'TenureReports',
        components: {
            Sidebar
        },
        methods: {

        },
        data: function () {
            return {
                search: '',
                loading: false,

            }
        },
        mounted: function () {

        },
        computed: {

        }
    };
</script>
<style></style>