<template>
    <div>
        <v-progress-linear indeterminate
                           v-if="loading"></v-progress-linear>
        <div class="headline">Hearings</div>
        <FilterableDataTable :headers="HearingHeaders"
                      :items="Hearings"
                      item-key="BillId"
                      multi-sort
                      disable-pagination
                      hide-default-footer
                      :sort-by="['ActionDate']"
                      :sort-desc="[false]"
                      loading-text="Loading... Please wait"
                      class="elevation-1">
            <template v-slot:item.BillURL="{ item }">
                <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType + '&nbsp;' + item.BillNumberOnly}}</router-link>
            </template>
            <template v-slot:item.ActionDate="{ item }">
                <a rel="noopener" :href="'https://mylrc.sdlegislature.gov/api/Documents/' + item.DocumentId +'.pdf'">{{DateTimePretty(item.ActionDate)}}</a>
            </template>
            <template v-slot:item.FullName="{ item }">
                <router-link :to="'/Session/Committee/' + item.ActionCommitteeId">{{item.FullName}}</router-link>
            </template>
            <template v-slot:no-data>
                No Hearings Scheduled
            </template>
        </FilterableDataTable>
    </div>
</template>
<script>
    import moment from 'moment'
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Hearings',
        props: ['SessionMemberId'],
        components: {
            FilterableDataTable
        },
        methods: {
            switchTab(item) {
                this.filterValue = item;
            },
            LoadData() {
                fetch('/api/SessionMembers/Hearing/' + this.SessionMemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.loading = false;
                    this.Hearings = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DateTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('M/DD/YYYY h:mm A');
                } else {
                    return null;
                }
            }
        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',
                tabs: ['Detail', 'Committees', 'Bills', 'Hearings'],
                Hearings: [],
                HearingHeaders: [
                    {
                        text: 'Date',
                        value: 'ActionDate',
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                    },
                    {
                        text: 'Bill',
                        value: 'BillURL',
                    },

                    {
                        text: 'Committee',
                        value: 'FullName',
                    },

                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionMemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {

        }
    };
</script>
<style></style>