<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <span v-if="currentRoute.includes('Session_Laws') && Session && Session.SessionId">
                <Sidebar :SessionId="Session.SessionId"></Sidebar>
            </span>
            <span v-else>
                <Sidebar></Sidebar>
            </span>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                <span v-if="currentRoute.includes('Constitution')">Constitution</span>
                <span v-else-if="currentRoute.includes('Rules/Administrative')">Administrative Rules</span>
                <span v-else-if="currentRoute.includes('SDCLIndex')"><span v-if="Session">{{Session.YearString}}</span> SDCL Index</span>
                <span v-else-if="currentRoute.includes('TableOfContents')"><span v-if="Session">{{Session.YearString}}</span> Table Of Contents</span>
                <span v-else-if="currentRoute.includes('TopicalIndex')"><span v-if="Session">{{Session.YearString}}</span> Topical Index</span>
                <span v-else-if="currentRoute.includes('CrossReference')"><span v-if="Session">{{Session.YearString}}</span> Cross Reference</span>
                <span v-else-if="currentRoute.includes('Foreward')"><span v-if="Session">{{Session.YearString}}</span> Forward</span>
                <span v-else-if="currentRoute.includes('Session_Laws/Chapter')"><span v-if="Session">{{Session.YearString}}</span> Session Laws</span>
                <span v-else-if="currentRoute.includes('Statutes')">Codified Laws</span>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <v-row class="text-center">
                    <v-col cols="12" sm="6" md="5">
                        <v-breadcrumbs divider=">" :items="breadcrumbs" class="py-0" style="font-size:18px;"></v-breadcrumbs>
                    </v-col>
                    <v-col cols="12" sm="6" md="7" v-if="!loading && item">
                        <v-btn class="ma-2" color="primary" v-if="item.Previous && currentRoute.includes('Constitution')" :to="'/Constitution/' + item.Previous">
                            <v-icon>{{arrow_back}}</v-icon> Previous
                        </v-btn>
                        <v-btn class="ma-2" color="primary" v-else-if="item.Previous && currentRoute.includes('Rules/Administrative')" :to="'/Rules/Administrative/' + item.Previous">
                            <v-icon>{{arrow_back}}</v-icon> Previous
                        </v-btn>
                        <v-btn class="ma-2" color="primary" v-else-if="item.Previous && currentRoute.includes('Statutes')" :to="'/Statutes/' + item.Previous">
                            <v-icon>{{arrow_back}}</v-icon> Previous
                        </v-btn>

                        <v-btn color="primary" v-if="item.Next && currentRoute.includes('Constitution')" :to="'/Constitution/' + item.Next">
                            Next <v-icon>{{arrow_forward}}</v-icon>
                        </v-btn>
                        <v-btn color="primary" v-else-if="item.Next && currentRoute.includes('Rules/Administrative')" :to="'/Rules/Administrative/' + item.Next">
                            Next <v-icon>{{arrow_forward}}</v-icon>
                        </v-btn>
                        <v-btn color="primary" v-else-if="item.Next && currentRoute.includes('Statutes')" :to="'/Statutes/' + item.Next">
                            Next <v-icon>{{arrow_forward}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="error">
                    <v-col cols="12">
                        <v-alert type="error">
                            {{error}} - An error occurred; please try again later.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <div v-if="currentRoute.includes('Session_Laws')"><GoogleSearch v-if="!hideSessionLawSearch" :SessionId="Session.SessionId"></GoogleSearch></div>
                            <div v-else><GoogleSearchStatute></GoogleSearchStatute></div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-divider style="height: 10px; max-height: 10px" v-if="!hideSessionLawSearch"></v-divider>
            <v-layout row wrap v-if="Value != 'null' && item">
                <v-btn v-if="currentRoute.includes('Rules/Administrative') && item && item.RuleId" text rel="noopener" :href="'/api/Rules/' + item.RuleId + '.docx'">
                    Download {{item.RuleNumber}} in Microsoft Word Format
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn v-if="currentRoute.includes('Constitution') && item && !loading" style="float:right;" text rel="noopener" :href="'/api/Statutes/' + item.Statute + '.html?all=true'">Printer Friendly</v-btn>
                <v-btn v-else-if="currentRoute.includes('Rules/Administrative') && item && !loading" style="float:right;" text rel="noopener" :href="'/api/Rules/Rule/' + item.RuleNumber + '.html?all=true'">Printer Friendly</v-btn>
                <v-btn v-else-if="currentRoute.includes('Session_Laws') && item && item.DocumentId && !loading" style="float:right;" text rel="noopener" :href="$MyLRCUrl.value +'api/Documents/SessionLaws/' + item.DocumentId + '.pdf'">Printer Friendly</v-btn>
                <v-btn v-else-if="currentRoute.includes('Statutes') && item && !loading" style="float:right;" text rel="noopener" :href="'/api/Statutes/' + item.Statute + '.html?all=true'">Printer Friendly</v-btn>

            </v-layout>
            <v-container>
                <p v-if="showPendingAction" style="color: red; font-size: larger;" class="headline">
                    Pending Court Action
                </p>
                <p v-if="showUnconstitutional" style="color: red; font-size: larger;" class="headline">
                    Article XXX was held unconstitutional by <a href="https://ujs.sd.gov/uploads/sc/opinions/2954698e671a.pdf" style="color: inherit; text-decoration:underline;">Thom v. Barnett, 2021 S.D. 65 (2021).</a>
                </p>
                <div v-if="Value && Value != 'null' && item && item.Html" v-html="item.Html"></div>
                <div v-else>
                    <v-alert v-if="!loading" dense
                             text
                             type="info">Not yet available.</v-alert>
                </div>
            </v-container>
            <v-container v-if="currentRoute.includes('Rules/Administrative') && item && item.Archived">
                Online Archived History:

                <v-row v-for="r in item.Archived" :key="r.ArchivedRuleId" no-gutters>
                    <v-col md="6" cols="12">
                        <v-btn block rel="noopener" :href="'/api/Rules/Archived/' + r.ArchivedRuleId + '.pdf'" outlined>{{r.Effective | FormatDates}}</v-btn>
                    </v-col>
                </v-row>
                <v-alert v-if="!loading && item && item.Archived && item.Archived.length < 1" type="info" dense>
                    There are currently no online archives for this rule.
                </v-alert>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    import { mdiArrowLeft } from '@mdi/js';
    import { mdiArrowRight } from '@mdi/js';
    export default {
        name: 'StatuteView',
        props: ['Value'],
        components: {
            GoogleSearch,
            GoogleSearchStatute,
            Sidebar
        },
        methods: {
            LoadData() {
                this.loading = true;
                if (this.item) {
                    this.item.Html = "";
                }
                else {
                    this.item = {
                        Html: "",
                        StatuteId: null
                    };
                }
                fetch('/api/Sessions', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.Sessions = EncodeDatetimes(response);
                }).catch(error => {
                    throw new Error(error);
                });
                if (this.Value != "null") {
                    this.error = null;
                    if (this.currentRoute.includes('Session_Laws')) {
                        this.type = "Session Law";

                        this.placeholder = "Chapter";
                        this.loading = true;
                        var docType = null;
                        if (this.currentRoute.includes('SDCLIndex')) {
                            this.hideSessionLawSearch = true;
                            docType = 65;
                        }
                        else if (this.currentRoute.includes('TableOfContents')) {
                            this.hideSessionLawSearch = false;
                            docType = 59;
                        }
                        else if (this.currentRoute.includes('TopicalIndex')) {
                            this.hideSessionLawSearch = true;
                            docType = 62;
                        }
                        else if (this.currentRoute.includes('CrossReference')) {
                            this.hideSessionLawSearch = true;
                            docType = 61;
                        }
                        else if (this.currentRoute.includes('Foreword')) {
                            this.hideSessionLawSearch = true;
                            docType = 60;
                        }
                        else {
                            fetch('/api/SessionLaws/SessionLaw/' + this.Value, {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                            }).then(res => {
                                this.loading = false;
                                if (res.ok) {
                                    return res.json();
                                }
                                else if (res.status === 404) {
                                    this.$router.push('/Session/Archived');
                                    return null;
                                }
                                this.error = res.status;
                                throw new Error(res.statusText);
                            }).then(response => {
                                this.item = response;
                                let session = this.Sessions.find(x => x.SessionId == this.item.SessionId);
                                if (session) {
                                    this.Session = session;
                                }
                                else {
                                    this.Session = { Year: '' };
                                }
                                this.createBreadcrumbs("Session Laws", "/Statutes/Session_Laws/" + this.Session.SessionId, this.item.Chapter);
                            }).catch(error => {
                                throw new Error(error);
                            });
                        }

                        if (docType != null) {
                            let SessionIdQuery = '';
                            if (this.Value) {
                                SessionIdQuery = '&SessionId=' + this.Value;
                            }
                            fetch('/api/Documents/DocumentTypeWithHtml?Type=' + docType + SessionIdQuery, {
                                method: "GET",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                            }).then(res => {
                                this.loading = false;
                                if (res.status === 200 || res.status === 201) {
                                    return res.json();
                                }
                                throw new Error(res.statusText);
                            }).then(response => {
                                var res = response;
                                this.item = res;
                                
                                let session = this.Sessions.find(x => x.SessionId == this.Value);
                                if (session) {
                                    this.Session = session;
                                }
                                else {
                                    this.Session = { Year: '' };
                                }

                            }).catch(error => {
                                throw new Error(error);
                            });
                        }
                    }
                    else if (this.currentRoute.includes('Administrative'))
                    {
                        this.type = "Administrative Rule";
                        this.placeholder = "Article, Chapter, or Section";

                        fetch('/api/Rules/' + encodeURIComponent(this.Value), {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        }).then(res => {
                            this.loading = false;
                            if (res.redirected && res.url) {
                                this.$router.push(res.url.replace(location.origin, ''));
                                return null;
                            }
                            else if (res.ok) {
                                return res.json();
                            }
                            else if (res.status === 404) {
                                this.$router.push('/Rules/Administrative');
                                return null;
                            }
                            else {
                                this.error = res.status;
                                return null;
                            }
                            throw new Error(res.statusText);
                        }).then(response => {
                            this.item = response;
                            if (this.item) {
                                this.createBreadcrumbs("Administrative Rules", "/Rules/Administrative", this.item.RuleNumber, this.item.Parents);
                            }
                        }).catch(error => {
                            throw new Error(error);
                        });
                    }
                    else if (this.currentRoute.includes('Codified_Laws') || this.currentRoute.includes('Constitution') || this.currentRoute.includes('Statutes')) {
                        if (this.Value.includes("0N-")) {
                            this.$router.push(location.pathname.replace('0N-', ''));
                            return null;
                        }
                        this.type = "Statute";
                        let APIEndPoint = "Statute/";
                        if (this.currentRoute.includes('Constitution')) {
                            APIEndPoint = "Constitution/";
                        }
                        fetch('/api/Statutes/' + APIEndPoint + this.Value, {
                            method: "GET",
                            credentials: "include",
                            headers: {
                                "Content-Type": "application/json"
                            },
                        }).then(res => {
                            this.loading = false;
                            if (res.redirected && res.url) {
                                this.$router.push(res.url.replace(location.origin, ''));
                                return null;
                            }
                            else if (res.ok) {
                                return res.json();
                            }
                            else if (res.status === 404 && this.currentRoute.includes('Constitution')) {
                                this.$router.push('/Constitution');
                                return null;
                            }
                            else if (res.status === 404) {
                                this.$router.push('/Statutes');
                                return null;
                            }
                            else {
                                this.error = res.status;
                                return null;
                            }
                            throw new Error(res.statusText);
                        }).then(response => {
                            this.item = response;
                            if (this.item) {
                                if (this.currentRoute.includes('Constitution')) {
                                    this.createBreadcrumbs("Constitution", "/Constitution", this.item.Statute, this.item.parents);
                                    this.placeholder = "Section or Article";
                                } else {
                                    this.createBreadcrumbs("Codified Laws", "/Statutes", this.item.Statute, this.item.parents);
                                    this.placeholder = "Statute or Chapter";
                                }

                                if (this.item.Statute.indexOf('2-1-1.5') > -1 || this.item.Statute.indexOf('2-1-1.6') > -1) {
                                    this.showPendingAction = true;
                                    this.showUnconstitutional = false;
                                }
                                else if (this.item.Statute.indexOf('0N-30') > -1) {
                                    this.showPendingAction = false;
                                    this.showUnconstitutional = true;
                                }
                                else {
                                    this.showPendingAction = false;
                                    this.showUnconstitutional = false;
                                }
                            }
                        }).catch(error => {
                            throw new Error(error);
                        });
                    } 
                }
            },
            createBreadcrumbs(route, url, child, parents) {
                this.readableNumber = child;
                this.breadcrumbs = [];
                var bci = {
                    to: '',
                    link: true,
                    text: '',
                    exact: true,
                };

                bci.to = "/";
                bci.text = "Home";
                this.breadcrumbs.push(bci);

                var bci2 = {
                    to: '',
                    link: true,
                    text: '',
                    exact: true,
                };
                bci2.to = url;
                bci2.text = route;
                this.breadcrumbs.push(bci2);
                var split = [];
                if (route == "Session Laws") {

                    split = child.toString().split(":");
                    split.forEach((x, i) => {
                        var bciL = {
                            to: '',
                            link: true,
                            text: '',
                            exact: true,
                        };

                        if (x != '0N') {
                            switch (i) {
                                case 0:
                                    bciL.to = url + '/' + x;
                                    break;
                                case 1:
                                    bciL.to = url + '/' + split[i - 1] + '-' + x;
                                    break;
                                case 2:
                                    bciL.to = url + '/' + split[i - 2] + '-' + split[i - 1] + '-' + x;
                                    break;
                                default:
                                    bciL.to = url
                                    break;
                            }
                            bciL.text = x;
                            if (i == split.length - 1) {
                                bciL.disabled = true;

                            }
                            this.breadcrumbs.push(bciL);
                        }
                    });

                }
                else {
                    let currentVal = null;
                    parents.forEach((x, i) => {
                        var bciL = {
                            to: '',
                            link: true,
                            text: '',
                            exact: true,
                        };
                        var val = '';
                        if (route == "Administrative Rules") {
                            if (currentVal) {
                                currentVal += ':' + x.RuleNumber;
                            }
                            else {
                                currentVal = x.RuleNumber;
                            }
                            val = currentVal
                            bciL.text = x.RuleNumber;
                            if (i == 0) {
                                bciL.disabled = true;
                            }
                        } else {
                            if (currentVal) {
                                currentVal += '-' + x.Statute;
                            }
                            else {
                                currentVal = x.Statute;
                            }
                            val = currentVal
                            bciL.text = x.Statute;
                        }

                        if (x.Statute != '0N') {
                            switch (i) {
                                case 0:
                                    bciL.to = url + '/' + val;
                                    break;
                                case 1:
                                    bciL.to = url + '/' + val;
                                    break;
                                case 2:
                                    bciL.to = url + '/' + val;
                                    break;
                                default:
                                    bciL.to = url
                                    break;
                            }

                            if (i == parents.length - 1) {
                                bciL.disabled = true;

                            }
                            this.breadcrumbs.push(bciL);
                        }
                    });
                }
            },

        },
        data: function () {
            return {
                error: null,
                arrow_back: mdiArrowLeft,
                arrow_forward: mdiArrowRight,
                type: "",
                readableNumber: "",
                loading: true,
                quickFind: null,
                sortBy: [],
                placeholder: 'Article, Chapter, or Section',
                showPendingAction: false,
                showUnconstitutional: false,
                item: {
                    StatuteId: null,
                    Html: null
                },
                Session: { Year: '' },
                hideSessionLawSearch: false,
                parent: {
                    name: '',
                    url: '',
                },
                bcItem: {
                    href: '',
                    link: true,
                    text: '',
                },
                breadcrumbs: [],
                Sessions: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            currentRoute() {
                this.LoadData();
            },
            Value: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            JsonldBreadCrumbs: function () {
                let CurrentBaseURL = window.location.origin;
                let ListItems = [];
                this.breadcrumbs.forEach((x, i) => {
                    let li = {
                        "@type": "ListItem",
                        "position": i,
                        "name": x.text,
                        "item": CurrentBaseURL + x.to
                    };
                    ListItems.push(li);
                });
                return ListItems;
            },
            Title: function () {
                let title = '';
                if (this.currentRoute.includes('Constitution')) {
                    title = "Constitutional ";
                    if (this.item && this.item.Statute && (typeof this.item.Statute === 'string' || this.item.Statute instanceof String)) 
                    {
                        title = title + this.item.Statute.replace('0N-', 'Article ');
                    }
                }
                else if (this.currentRoute.includes('Administrative')) {
                    title = "Administrative Rule ";
                    if (this.item && this.item.RuleNumber) {
                        title = title + this.item.RuleNumber;
                    }
                }
                else if (this.currentRoute.includes('Session_Laws')) {
                    title = "Session Law Chapter ";
                    if (this.Session && this.Session.Year) {
                        title = this.Session.Year + ' ' + title;
                    }
                    else {
                        title = "Session Laws" + ' ' + title;
                    }
                    if (this.item && this.item.Chapter) {
                        title = title + ' ' + this.item.Chapter;
                    }
                }
                else if (this.currentRoute.includes('Codified_Laws') || this.currentRoute.includes('Statutes')) {
                    title = "Codified Law ";
                    if (this.item && this.item.Statute) {
                        title = title + this.item.Statute;
                    }
                }
                return title;
            },
            Description: function () {
                if(this.item && this.item.CatchLine){
                    return this.item.Catchline;
                }
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': this.type + ' ' + this.readableNumber,
                            'description': this.Description,
                            'legislationType': this.type,
                            'inLanguage': 'English'
                        }
                    }, {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'BreadcrumbList',
                            "itemListElement": this.JsonldBreadCrumbs
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>