<template>
    <v-card flat class="pr-4">
        <v-card-title>Current Schedule</v-card-title>
        <v-card-text>
            <v-container class="d-print-none pa-0 ma-0" fluid>
                <v-row no-gutters>
                    <v-col>

                        Click <v-icon color="primary" alt="SDPB Audio" aria-label="SDPB Audio">{{mdiVolumeHigh}}</v-icon> for the live audio broadcast.
                        <br />
                        Broadcasts are provided by <a rel="noopener" href="https://www.sd.net/">SDPB</a>.
                        <br />
                        Click <v-icon medium color="blue lighten-3">{{tv}}</v-icon> for the Committee Live Scoreboard.
                        <br />
                        Click the meeting date to see the Agenda or Calendar.
                        <br />
                    </v-col>
                    <v-col style="text-align: right;">
                        <v-btn href="/Session/Schedule?printerFriendly=true" target="_blank" color="primary">Print Friendly</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-card flat>
                <v-card-text>
                    <FilterableDataTable :headers="headers"
                                  :items="filteredInterimCommitteeMeetings"
                                  :search="search"
                                  item-key="MeetingName"
                                  multi-sort
                                  hide-default-footer
                                  disable-pagination
                                  :loading="loading"
                                  loading-text="Loading... Please wait"
                                  no-data-text="No Meetings Scheduled Today"
                                  class="elevation-1 d-print-none">
                        <template v-slot:item.SDPB="{ item }">
                            <v-btn icon color="primary" small rel="noopener" :href="item.RoomAudio" v-if="item.RoomAudio && item.RoomAudio != '#' && item.RoomAudio != 'No Audio'" class="px-1"  alt="SDPB Audio">
                                <v-icon>{{mdiVolumeHigh}}</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.AddToCalendar="{ item }">
                            <v-icon>{{event}}</v-icon>
                        </template>
                        <template v-slot:item.Meeting="{ item }">
                            <router-link v-if="item && item.SessionCommitteeId" :to="'/Session/Committee/'+ item.SessionCommitteeId +'/Detail'">
                                {{item.CommitteeFullName }}
                            </router-link>
                            <router-link v-else-if="item && item.InterimYearCommitteeId" :to="'/Interim/Committee/'+ item.InterimYearCommitteeId +'/Detail'">{{ item.InterimYearCommitteeName}}</router-link>
                            <span v-else>
                                {{item.Meeting}}
                            </span>
                        </template>
                        <template v-slot:item.Weekday="{ item }">
                            <a v-if="item.InternetReady" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'">
                                {{DayOfWeek(item.DocumentDate)}}
                            </a>
                            <span v-else>
                                {{DayOfWeek(item.DocumentDate)}}
                            </span>
                        </template>
                        <template v-slot:item.Time="{ item }">
                            <a v-if="item.InternetReady && !item.TBD" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'">
                                {{item.DocumentDate | FormatTimes}}
                            </a>
                            <span v-else-if="item.TBD">
                                {{formatDate2(item.DocumentDate)}}
                            </span>
                            <span v-else>
                                {{item.DocumentDate | FormatTimes}}
                            </span>
                        </template>
                        <template v-slot:item.MeetingDate="{ item }">
                            <a v-if="item.InternetReady" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'">
                                {{item.DocumentDate | FormatDates}}
                            </a>
                            <span v-else>
                                {{item.DocumentDate | FormatDates}}
                            </span>
                        </template>
                    </FilterableDataTable>
                    <table class="d-none d-print-block" style="width: 100%; border-collapse: collapse; border-spacing: 2px 2px; font-size: smaller;">
                        <thead>
                            <tr>
                                <td style="font-weight: bold;border: 1px solid grey; width: 10ch; padding: 2px;">Date</td>
                                <td style="font-weight: bold; border: 1px solid grey; width: 15ch; padding: 2px;">Weekday</td>
                                <td style="font-weight: bold; border: 1px solid grey; width: 15ch; padding: 2px;">Time</td>
                                <td style="font-weight: bold; border: 1px solid grey; padding: 2px;">Meeting</td>
                                <td style="font-weight: bold; border: 1px solid grey; width: 15ch; padding: 2px;">Location</td>
                            </tr>
                        </thead>
                        <tr v-for="item in CommitteeMeetings" style="border: 1px solid grey;">
                            <td style="border: 1px solid grey; padding: 2px;">{{item.DocumentDate | FormatDates}}</td>
                            <td style="border: 1px solid grey; padding: 2px;">{{DayOfWeek(item.DocumentDate)}}</td>
                            <td style="border: 1px solid grey; padding: 2px;">{{item.DocumentDate | FormatTimes}}</td>
                            <td style="border: 1px solid grey; padding: 2px;">
                                <span v-if="item.CommitteeFullName">{{item.CommitteeFullName}}</span><span v-else-if="item.InterimYearCommitteeName">{{ item.InterimYearCommitteeName}}</span><span v-else>{{item.Meeting}}</span>
                            </td>
                            <td style="border: 1px solid grey; padding: 2px;">{{item.Room}}</td>
                        </tr>
                    </table>
                </v-card-text>
                <v-card-actions>
                    <b>
                        **All meetings to be held in Pierre unless otherwise indicated.
                    </b>
                </v-card-actions>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
    import moment from 'moment'
    import { mdiCalendar } from '@mdi/js';
    import { mdiTelevision } from '@mdi/js';
    import { mdiVolumeHigh } from '@mdi/js';

    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Schedule',
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Documents/Schedule?all=true', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    var tempSchedule = EncodeDatetimes(response);
                    var endSchedule = [];

                    tempSchedule.sessionMeetings.forEach(x => {
                        if (x.DocumentDate) {
                            x.MeetingDate = x.DocumentDate;
                            x.Time = x.DocumentDate;
                            x.Weekday = this.DayOfWeek(x.DocumentDate);
                        }
                        if (x.CommitteeFullName) {
                            x.Meeting = x.CommitteeFullName;
                        }
                        else if (x.InterimYearCommitteeName) {
                            x.Meeting = x.InterimYearCommitteeName;
                        }
                        else {
                            x.Meeting = x.Title;
                        }
                        endSchedule.push(x);
                    });
                    if (endSchedule) {
                        this.CommitteeMeetings = endSchedule.sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                    }
                    else {
                        this.CommitteeMeetings = [];
                    }
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            DayOfWeek(Date) {
                return moment(Date).format('dddd');
            },
            formatDate2(Date)
            {
                return moment(Date).add(8, 'hours').format('M/DD/YYYY');
            },
        },
        data: function () {
            return {
                event: mdiCalendar,
                tv: mdiTelevision,
                mdiVolumeHigh,
                headers: [
                    {
                        text: '',
                        value: 'AddToCalendar',
                        width: '1%',
                        filterable: false
                    },
                    {
                        text: 'Date',
                        value: 'MeetingDate',
                        width: '10%'
                    },
                    {
                        text: 'Weekday',
                        value: 'Weekday',
                        width: '15%'
                    },
                    {
                        text: 'Time',
                        value: 'Time',
                        width: '15%'
                    },
                    {
                        text: 'Meeting',
                        value: 'Meeting',
                        width: '*'
                    },
                    {
                        text: 'Location',
                        value: 'Room',
                        width: '10%'
                    },
                    {
                        text: '',
                        value: 'SDPB',
                        width: '10%',
                        filterable: false
                    },
                ],
                loading: true,
                CommitteeMeetings: [
                ],
                search: '',
                groupbyVisible: false,
                GroupByValue: '',
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            currentRoute: function () {
                this.LoadData();
            },
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            filteredInterimCommitteeMeetings: function () {
                return this.CommitteeMeetings;//.filter(x => x.InterimYearCommitteeId);
            },
            filteredCommitteeMeetings: function () {
                return this.CommitteeMeetings;//.filter(x => x.SessionCommitteeId);
            },
            Title: function () {
                let title = 'Schedule';
                return title;
            },
            Description: function () {
                return this.Title;
            }
        },
        metaInfo() {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0].toLowerCase().replace('?printerfriendly=true', '').replace('/interim/schedule', '/session/schedule') }
                ]
            }
        }
    };
</script>
<style></style>